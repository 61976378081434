import React, {Component} from 'react';
import {
    BrowserRouter as Router,
    Route,
    Switch,
} from 'react-router-dom';

import { applyMiddleware, createStore } from 'redux';
import { Provider } from 'react-redux';

import thunk from 'redux-thunk';

import Reducers from 'darwin/reducers';

import WelcomeScene from 'darwin/scenes/WelcomeScene';
import MyTulipsScene from 'darwin/scenes/MyTulipsScene';
import ShopScene from 'darwin/scenes/ShopScene';
import BattleScene from 'darwin/scenes/BattleScene';
import SuccessScene from 'darwin/scenes/SuccessScene';
import My404Component from 'darwin/scenes/My404Component';
import MarketplaceScene from 'darwin/scenes/MarketplaceScene';
import MarketplaceManageListingsScene from 'darwin/scenes/MarketplaceManageListingsScene';
import TermsOfUseScene from 'darwin/scenes/TermsOfUseScene';
import PrivacyPolicyScene from 'darwin/scenes/PrivacyPolicyScene';
import AttributionsScene from 'darwin/scenes/AttributionsScene';
import DuelMainMenuScene from 'darwin/scenes/DuelMainMenuScene';
import DuelLoadoutScene from 'darwin/scenes/DuelLoadoutScene';
import DuelScene from 'darwin/scenes/DuelScene';
import BridgeScene from 'darwin/scenes/BridgeScene';
import GardenScene from 'darwin/scenes/GardenScene';

import 'styles/inter.css';
import 'styles/cabin.css';
import 'styles/pure-min.css';
import 'styles/App.css';
import 'styles/fa.css';
import Garden from './components/Garden';

const store = createStore(Reducers, applyMiddleware(thunk));

class App extends Component {
    render() {
        return (
            <Provider store={store}>
                <Router>
                    <Switch>
                        <Route exact path="/" component={WelcomeScene}/>
                        <Route exact path="/my_tulips" component={MyTulipsScene}/>
                        <Route exact path="/shop" component={ShopScene}/>
                        <Route exact path="/shop/purchased" component={SuccessScene} />
                        <Route exact path="/battle" component={BattleScene} />
                        <Route exact path="/bridge" component={BridgeScene}/>
                        <Route exact path="/garden" component={GardenScene}/>
                        {/*<Route exact path="/marketplace" component={MarketplaceScene} />*/}
                        {/*<Route exact path="/marketplace/manage" component={MarketplaceManageListingsScene} />*/}
                        <Route exact path="/legal/terms" component={TermsOfUseScene} />
                        <Route exact path="/legal/privacy" component={PrivacyPolicyScene} />
                        <Route exact path="/legal/attributions" component={AttributionsScene} />
                        <Route exact path="/battles/duel" component={DuelMainMenuScene} />
                        <Route exact path="/battles/duel/game" component={DuelScene} />
                        <Route exact path="/battles/duel/loadout" component={DuelLoadoutScene} />
                        <Route path='*' exact={true} component={My404Component} />
                    </Switch>
                </Router>
            </Provider>
        );
    }
}

export default App;
