import React, { Component } from 'react';

import Gen2021Banner from './Gen2021Banner';
import GardenBanner from './GardenBanner';
import LandingSplash from './LandingSplash';
import LandingRibbon from './LandingRibbon';
import LandingCallout from './LandingCallout';

var Styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
};

export default class LandingPage extends Component {
    render() {
        return (
            <div style={Styles.container}>
                <GardenBanner />
                <LandingSplash />
                <LandingRibbon />
                <LandingCallout />
            </div>
        );
    }
}
