import React, { Component } from 'react';

import getAsset from 'darwin/lib/assets/getAsset';
import mergeStyles from 'darwin/lib/styling/mergeStyles';
import Constants from 'darwin/Constants';

import Apis from 'darwin/lib/api';
const Api = Apis.v1Api;

var Styles = {
    wrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    container: {
        flex: 1,
        fontSize: 16,
        borderRadius: 5,
        marginTop: 10,
        padding: '.75rem 1.25rem',
        color: '#004085',
        backgroundColor: '#cce5ff',
        border: '2px solid #b8daff',
        maxWidth: 980,
        lineHeight: 1.2,
    },
    link: {
        textDecoration: 'underline',
    },
};

export default class BridgePrompt extends Component {
    constructor(props) {
        super(props);

        this.state = {
            total: null,
            eligible: null,
        }
    }

    componentWillMount() {
        const that = this;
        Api.getNumOwnedTulips()
            .then(total => {
                that.setState({ total: total.toNumber() });
                return Api.countEligibleTulips(Constants.maxOwnedTulipsBatchSize);
            })
            .then(eligible => that.setState({ eligible }))
            .catch(err => console.log(err));
    }

    render() {
        let message;
        if (this.state.total === null) {
            message = <>Loading your EtherTulips legacy tokens</>;
        } else if (this.state.total === 0) {
            message = <>You have no EtherTulips on the legacy contract.</>;
        } else if (this.state.eligible === null) {
            message = (<>
                You have {this.state.total} EtherTulips on the legacy contract.
                Determining bridging eligibility...
            </>);
        } else if (this.state.eligible === 0) {
            message = (<>
                You have {this.state.total} EtherTulips on the legacy contract, but none of them are
                eligible for bridging. You can view, manage, and battle these tulips
                at <a style={Styles.link} target="_blank" href="https://legacy.ethertulips.com/my_tulips">legacy.ethertulips.com</a>.
                More information about tulip bridging can be found
                in <a style={Styles.link} target="_blank" href="https://medium.com/@ethertulips/the-2021-update-bf0b653a623a">this post</a>.
            </>)
        } else {
            message = (<>
                You have {this.state.total} EtherTulips on the legacy contract, and {this.state.eligible} of
                them are eligible for bridging. You can view, manage, and battle your legacy tulips
                at <a style={Styles.link} target="_blank" href="https://legacy.ethertulips.com/my_tulips">legacy.ethertulips.com</a>,
                and transfer your eligible tulips to the upgraded contract using
                the <a style={Styles.link} href="https://ethertulips.com/bridge">EtherTulips Bridge</a>.
                More information about tulip bridging can be found
                in <a style={Styles.link} target="_blank" href="https://medium.com/@ethertulips/the-2021-update-bf0b653a623a">this post</a>.
            </>)
        }

        return (
            <div style={Styles.wrapper}>
                <div style={Styles.container}>
                    {message}
                </div>
            </div>
        );
    }
}
