import * as React from 'react';
import Modal from 'react-modal';

import Apis from 'darwin/lib/api';
import mergeStyles from 'darwin/lib/styling/mergeStyles';
import getAsset from 'darwin/lib/assets/getAsset';

import { tileMap, plotCoords, plotMap, gardenWidth, gardenHeight } from './gardenMap';
import PlantedTulip from './PlantedTulip';
import TulipPicker from './TulipPicker';

const { gardenApi, v2Api } = Apis;

const Styles = {
    modalContent: {
        borderRadius: '4px',
        bottom: 'auto',
        minHeight: '10rem',
        left: '50%',
        paddingLeft: '2rem',
        paddingRight: '2rem',
        position: 'fixed',
        right: 'auto',
        top: '50%',
        transform: 'translate(-50%,-50%)',
        minWidth: '10rem',
        width: '80%',
        maxWidth: 768,
        maxHeight: '90vh',
    },
    controlArea: {
        height: 24,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    closeButton: {
        cursor: 'pointer',
    },
    content: {
        display: 'flex',
        flexDirection: 'row',
    },
    plotImageContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    detailsContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        marginLeft: 36,
    },
    tile: {
        width: 200,
        height: 200,
    },
    plotState: {
        margin: 0,
        padding: 0,
    },
    claimButton: {
        marginTop: 8,
        border: 'none',
        padding: 8,
        borderRadius: 8,
    },
    claimButtonText: {
        fontWeight: 600,
        fontSize: 14,
    },
    disclaimerMessage: {
        fontSize: 14,
        borderRadius: 5,
        marginTop: 8,
        padding: '.75rem 1.25rem',
        color: '#004085',
        backgroundColor: '#cce5ff',
        border: '2px solid #b8daff',
        flex: 1,
    },
};

function PlotImage(props) {
    const [row, col] = plotCoords[props.plotId];
    const tileType = tileMap[row][col];
    const imageUrl = getAsset(`tiles/${tileType}.png`);
    const backgroundImage = `url("${imageUrl}")`;
    const tileStyle = mergeStyles(Styles.tile, { backgroundImage });

    let plot = null;
    if (props.plotClaimed === null || (props.plotClaimed && props.plotInfo === null)) {
        plot = <span className="fa fa-spinner" style={{color: '#555'}}></span>;
    } else if (props.plotClaimed && props.plotInfo.nftAddress != 0) {
        plot = <PlantedTulip tulipId={props.plotInfo.nftId} />;
    }

    return (
        <div style={tileStyle}>
            { plot }
        </div>
    );
}

function PlotManager(props) {
    const [action, setAction] = React.useState(null);
    const [nftOwner, setNftOwner] = React.useState(null);

    const account = gardenApi.maybeGetAccount();
    const info = props.plotInfo;
    const { tulipData, setTxInFlight, setTxHash, setTxError } = props;

    React.useEffect(() => {
        if (info.nftAddress != 0) {
            v2Api.ownerOf(info.nftId).then(owner => setNftOwner(owner));
        }
    }, [info]);

    const cancelHandler = () => {
        setAction(null);
        props.setShowingPicker(false);
        props.cancelSelection();
        setTxError(null);
    };

    let plantButton, uprootButton, dataButton, cancelButton, confirmationPage;
    switch (action) {
        case null:
            if (account == info.owner) {
                const plantHandler = () => {
                    setAction('plant');
                    props.setShowingPicker(true);
                    setTxError(null);
                    setTxHash(null);
                };
                plantButton = <button onClick={plantHandler}>Plant</button>;
            }
            if (info.nftAddress != 0 && (account == info.owner || account == nftOwner)) {
                const uprootHandler = () => {
                    setAction('uproot');
                    setTxInFlight(true);
                    setTxError(null);
                    setTxHash(null);

                    gardenApi.uproot(props.plotId)
                        .then(tx => {
                            props.forceUpdate();
                            setTxHash(tx.hash);
                        }).catch(err => {
                            setTxError(err.message);
                        }).finally(() => {
                            setTxInFlight(false);
                            setAction(null);
                        });
                };
                uprootButton = <button onClick={uprootHandler}>Uproot</button>;
            }
            // if (account == info.owner) {
            //     dataButton = <button onClick={() => setAction('data')}>Set Data</button>;
            // }
            break;

        case 'plant':
            cancelButton = <button onClick={cancelHandler}>Cancel</button>;

            if (props.selectedTulip != null) {
                const confirmPlant = () => {
                    setTxInFlight(true);
                    setTxError(null);

                    v2Api.contractAddress()
                        .then(v2Address => {
                            return gardenApi.plant(props.plotId, props.selectedTulip, v2Address);
                        }).then(tx => {
                            setAction(null);
                            props.setShowingPicker(false);
                            props.cancelSelection();
                            props.forceUpdate();
                            setTxHash(tx.hash);
                        }).catch(err => {
                            setTxError(err.message);
                        }).finally(() => {
                            setTxInFlight(false);
                        });
                };
                confirmationPage = (
                    <div>
                        <PlantedTulip tulipId={props.selectedTulip} />
                        <div>
                            Plant tulip #{props.selectedTulip}?
                        </div>
                        <button onClick={confirmPlant}>Plant Tulip</button>
                    </div>
                );
            }
            break;

        case 'uproot':
            confirmationPage = (
                <div>
                    Uprooting the tulip...
                </div>
            );
            break;
        case 'data':
            cancelButton = <button onClick={cancelHandler}>Cancel</button>;
            break;
    }
    const buttons = [plantButton, uprootButton, dataButton, cancelButton];

    const speciesName = (tulipData === null
        ? <span className="fa fa-spinner" style={{color: '#555'}}></span>
        : <>{tulipData.name}</>
    );
    const contents = (info.nftAddress == 0
        ? <>Empty</>
        : <>Contains EtherTulip #{info.nftId.toNumber()}: {speciesName}</>
    );
    // const data = (info.data == ""
    //     ? <>No data</>
    //     : <>Has data "{info.data}"</>
    // );
    const infoContent = (
        <div style={{marginBottom: 8}}>
            <ul style={Styles.plotState}>
                <li>Owned by {info.owner}</li>
                <li>{contents}</li>
                {/*<li>{data}</li>*/}
            </ul>
        </div>
    );

    return (
        <div>
            { infoContent }
            <div>
                { plantButton }
                { uprootButton }
                { dataButton }
                { cancelButton }
            </div>
            { confirmationPage }
        </div>
    );
}

function PlotShop(props) {
    // const { txHash, setTxInFlight, setTxHash, setTxError } = props;

    const [showingPicker, setShowingPicker] = React.useState(false);
    const [selectedTulip, setSelectedTulip] = React.useState(null);
    const [txLoading, setTxLoading] = React.useState(false);
    const [txError, setTxError] = React.useState(null);
    const [txHash, setTxHash] = React.useState(null);

    const tulipPicked = (tulip, data) => {
        setSelectedTulip({tulip, data});
        setShowingPicker(false);
    };
    const hideTulip = (tulipId) => v2Api.contractAddress().then(v2Address => gardenApi.tulipIrredeemable(v2Address, tulipId));

    const picker = (showingPicker
        ? <TulipPicker onSelected={tulipPicked} filter={hideTulip} claimPrices={true} />
        : null
    );

    let price = '0.04';
    if (selectedTulip) {
        price = (selectedTulip.tulip.id.toNumber() <= 7250 ? '0.01' : '0.025');
    }

    const onClaimClicked = () => {
        setTxLoading(true);
        setTxError(null);

        if (price === '0.04') {
            gardenApi.claimEmpty(props.plotId)
                .then(tx => setTxHash(tx.hash))
                .catch(err => setTxError(err.message))
                .finally(() => setTxLoading(false));
        } else {
            gardenApi.claimWithTulip(props.plotId, selectedTulip.tulip.id)
                .then(tx => setTxHash(tx.hash))
                .catch(err => setTxError(err.message))
                .finally(() => setTxLoading(false));
        }
    };

    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <span>This plot is available to be claimed.</span>
            <span style={{marginTop: 8, fontSize: 12}}>Plant a tulip right away to receive a discount! (optional)</span>
            <button onClick={() => setShowingPicker(!showingPicker)} style={{border: '1px solid #ccc', padding: 8, marginTop: 4, textAlign: 'left', borderRadius: 4, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                {selectedTulip ? (
                    <div style={{display: 'flex', flexDirection: 'row', flex: 1, alignItems: 'center'}}>
                        <img src={selectedTulip.data.image} style={{width: 24, height: 24, marginRight: 4}}/>
                        <span>{selectedTulip.data.name}</span>
                    </div>
                ) : (
                    <span style={{flex: 1}}>Select tulip…</span>
                )}
                <span style={{fontFamily: 'Inter, sans-serif', fontWeight: 'bold', color: '#555'}}>{showingPicker ? '↑' : '↓'}</span>
            </button>
            {picker}
            <span style={Styles.disclaimerMessage}>Plots are sold for entertainment purposes only with no guaranteed resale value. By claiming, you agree to the <a href="/legal/terms" target="_blank" style={{textDecoration: 'underline'}}>Terms of Service</a>.</span>
            {txError && <span style={{color: 'red', textAlign: 'center', marginTop: 4}}>{txError}</span>}
            {!txLoading && !txHash && <button style={{marginTop: 8, padding: 12, border: 'none', fontWeight: 'bold', color: 'white', borderRadius: 4}} className="shopscene-mint-button" onClick={onClaimClicked}>Claim ({price}Ξ)</button>}
            {!txLoading && txHash && <button style={{marginTop: 8, padding: 12, border: 'none', fontWeight: 'bold', color: 'white', borderRadius: 4}} className="shopscene-track-button" onClick={() => window.open(`https://etherscan.io/tx/${txHash}`,'_blank')}>Track on EtherScan <span className="fa fa-external-link"></span></button>}
            {txLoading && (
                <div style={{height: 44, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                    <img style={{height: 16, marginRight: 4}} src={getAsset("animation/loading.gif")} />
                    <span>Waiting for transaction...</span>
                </div>
            )}
        </div>
    );

    // function claimEmpty() {
    //     setTxInFlight(true);
    //     setTxError(null);
    //
    //     gardenApi.claimEmpty(props.plotId)
    //         .then(tx => setTxHash(tx.hash))
    //         .catch(err => setTxError(err.message))
    //         .finally(() => setTxInFlight(false));
    // }
    // function claimWithTulip() {
    //     props.setShowingPicker(true);
    //     setTxError(null);
    // }
    // function confirmSelection() {
    //     setTxInFlight(true);
    //     setTxError(null);
    //
    //     gardenApi.claimWithTulip(props.plotId, props.selectedTulip)
    //         .then(tx => {
    //             setTxHash(tx.hash);
    //             props.cancelSelection();
    //         }).catch(err => setTxError(err.message))
    //         .finally(() => setTxInFlight(false));
    // }
    // function cancel() {
    //     props.setShowingPicker(false);
    //     props.cancelSelection();
    //     setTxError(null);
    // }
    //
    // let claimEmptyContent, claimWithTulipContent, cancelButton, confirmButton, confirmationPage;
    // if (txHash) {
    //     confirmationPage = <span className="fa fa-spinner" style={{color: '#555'}}></span>;
    // } else if (props.showingPicker) {
    //     cancelButton = (
    //         <button onClick={cancel}>Cancel</button>
    //     );
    // } else if (props.selectedTulip != null) {
    //     cancelButton = (
    //         <button onClick={cancel}>Cancel</button>
    //     );
    //     confirmButton = (
    //         <button onClick={confirmSelection}>Confirm</button>
    //     );
    //     const cost = (props.selectedTulip <= 7250 ? '0.01' : '.025');
    //     confirmationPage = (
    //         <div>
    //             <PlantedTulip tulipId={props.selectedTulip} />
    //             <div>
    //                 Claim plot and plant tulip #{props.selectedTulip} for {cost} ETH?
    //             </div>
    //         </div>
    //     );
    // } else {
    //     claimEmptyContent = (
    //         <div>
    //             This empty plot can be claimed for 0.04 ETH.
    //             <div>
    //                 <button className="rainbowBackground" style={Styles.claimButton} onClick={claimEmpty}>
    //                     <span style={Styles.claimButtonText}>Claim Empty</span>
    //                 </button>
    //             </div>
    //         </div>
    //     );
    //     claimWithTulipContent = (
    //         <div style={{marginTop: 20}}>
    //             If you have a tulip to plant immediately in this plot, you can get a discount!
    //             Claiming a plot with a Gen 2021 tulip costs 0.025 ETH, and claiming a plot with a Gen 0 or Gen 1 tulip
    //             costs 0.01 ETH. Note that price discounts can only be applied once per tulip, and that Gen 0 or Gen 1
    //             tulips must be bridged to the upgraded contract.
    //
    //             <div>
    //                 <button className="rainbowBackground" style={Styles.claimButton} onClick={claimWithTulip}>
    //                     <span style={Styles.claimButtonText}>Claim With Tulip</span>
    //                 </button>
    //             </div>
    //         </div>
    //     );
    // }
    //
    // return (
    //     <div>
    //         <div>
    //             { claimEmptyContent }
    //             { claimWithTulipContent }
    //             { confirmButton }
    //             { cancelButton }
    //         </div>
    //         { confirmationPage }
    //     </div>
    // );
}

export default function GardenModal(props) {
    const [plotClaimed, setPlotClaimed] = React.useState(null);
    const [plotInfo, setPlotInfo] = React.useState(null);
    const [tulipData, setTulipData] = React.useState(null);
    const [showingPicker, setShowingPicker] = React.useState(false);
    const [selectedTulip, setSelectedTulip] = React.useState(null);
    const [txInFlight, setTxInFlight] = React.useState(false);
    const [txHash, setTxHash] = React.useState(null);
    const [txError, setTxError] = React.useState(null);

    React.useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, []);

    const { plotId } = props;

    const updatePlot = () => {
        gardenApi.plotClaimed(plotId)
            .then(claimed => {
                setPlotClaimed(claimed);

                if (claimed) {
                    gardenApi.plotInfo(plotId).then(info => {
                        setPlotInfo(info);
                        if (info.nftAddress == v2Api.maybeGetContractAddress()) {
                            v2Api.tokenUri(info.nftId)
                                .then(uri => fetch(uri))
                                .then(output => output.json())
                                .then(data => setTulipData(data));
                        }
                    });
                }
            }).catch(err => console.log(plotId, err));
    };
    React.useEffect(() => {
        updatePlot();
        const timeout = setInterval(updatePlot, 3000);
        return () => clearInterval(timeout);
    }, [plotId]);

    const tulipPicked = (tulip) => {
        setSelectedTulip(tulip.id.toNumber());
        setShowingPicker(false);
    };
    const cancelSelection = () => {
        setSelectedTulip(null);
    };
    const hideTulip = (plotClaimed
        ? (tulipId) => v2Api.contractAddress().then(v2Address => gardenApi.nftPlanted(v2Address, tulipId))
        : (tulipId) => v2Api.contractAddress().then(v2Address => gardenApi.tulipIrredeemable(v2Address, tulipId))
    );
    const picker = (showingPicker
        ? <TulipPicker onSelected={tulipPicked} filter={hideTulip} claimPrices={true} />
        : null
    );

    let content;
    if (plotClaimed === null || (plotClaimed && plotInfo === null)) {
        content = <span className="fa fa-spinner" style={{color: '#555'}}></span>;
    } else if (plotClaimed) {
        content = (
            <PlotManager
                plotId={plotId}
                plotInfo={plotInfo}
                setShowingPicker={setShowingPicker}
                cancelSelection={cancelSelection}
                tulipData={tulipData}
                selectedTulip={selectedTulip}
                forceUpdate={updatePlot}
                setTxInFlight={setTxInFlight}
                setTxHash={setTxHash}
                setTxError={setTxError}
            />
        );
    } else {
        content = (
            <PlotShop
                plotId={plotId}
                showingPicker={showingPicker}
                setShowingPicker={setShowingPicker}
                cancelSelection={cancelSelection}
                selectedTulip={selectedTulip}
                txHash={txHash}
                setTxInFlight={setTxInFlight}
                setTxHash={setTxHash}
                setTxError={setTxError}
            />
        );
    }

    const inFlightMessage = (txInFlight &&
        <p><span className="fa fa-spinner" style={{color: '#555'}}></span>Transaction loading...</p>
    );
    const successMessage = ( txHash &&
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <span style={{fontWeight: 'bold', color: 'green', marginBottom: 4}}>Transaction submitted!</span>
            <button
                style={{border: 'none', backgroundColor: 'blue', padding: 12, borderRadius: 8, color: 'white', fontWeight: 'bold'}}
                onClick={() => window.open(`https://etherscan.io/tx/${txHash}`,'_blank')}
            >
                Track on Etherscan <span className="fa fa-external-link"></span>
            </button>
        </div>
    );

    const errorMessage = (txError &&
        <p style={{color: 'red'}}>Transaction error: {txError}</p>
    );

    return (
        <Modal style={{content: Styles.modalContent}} onRequestClose={props.onRequestClose} isOpen={true} ariaHideApp={false}>
            <div style={Styles.controlArea}><span style={Styles.closeButton} onClick={props.onRequestClose}>Close</span></div>
            <div>
                <div style={Styles.content}>
                    <div style={Styles.plotImageContainer}>
                        <PlotImage plotId={plotId} plotClaimed={plotClaimed} plotInfo={plotInfo} />
                    </div>
                    <div style={Styles.detailsContainer}>
                        <h2 style={{marginTop: 0, paddingTop: 0}}>Plot #{plotId}</h2>
                        {content}
                        { inFlightMessage }
                        { successMessage }
                        { errorMessage }
                    </div>
                    <div style={Styles.controlArea}></div>
                </div>
                { picker }
            </div>
        </Modal>
    );
}
