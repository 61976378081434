import io from 'socket.io-client';
import EnvironmentVariables from 'darwin/EnvironmentVariables';
import GameClientSM from './GameClientSM';

export default class DuelApi {
    constructor(address, tulipLoadout, tulipStarter, handler) {
        this.address = address;
        this.tulipLoadout = tulipLoadout;
        this.tulipStarter = tulipStarter;
        this.handler = handler;
        this._socket = null;
        this._sm = null;
        this._readyPromise = this.initSocket()
            .then(() => this.initGame())
            .then(() => this.initListeners())
            .then(() => this.initFinished());
    }

    initSocket() {
        return new Promise((resolve, reject) => {
            this.socket = io(EnvironmentVariables.DUEL_API_BASE + '?address=' + this.address + '&tulipLoadout=' + JSON.stringify(this.tulipLoadout) + '&tulipStarter=' + this.tulipStarter);
            this.socket.on('connect', () => {
                return resolve();
            });
        });
    }

    initGame() {
        return new Promise((resolve, reject) => {
            this.socket.on('init', (gameProps, gameState, myIndex) => {
                this._sm = new GameClientSM(gameProps, gameState, myIndex);
                return resolve();
            });
        });
    }

    initListeners() {
        this.socket.on('update', (gameState) => {
            this._sm.updateState(gameState);
            this.handler.handleUpdate(this._sm.gameState);
        });
    }

    initFinished() {
        this.handler.handleInit(this._sm.gameProps, this._sm.gameState, this._sm.myIndex);
    }

    action(actionData) {
        this.ready().then(() => {
            this.socket.emit('action', actionData);
        });
    }

    move(moveIndex) {
        this.action({
            type: 'move',
            data: moveIndex,
        });
    }

    swap(tulipIndex) {
        this.action({
            type: 'switch',
            data: tulipIndex,
        });
    }

    sendChat(message) {
        this.action({
            type: 'chat',
            data: message,
        })
    }

    ready() {
        return this._readyPromise;
    }

    socket() {
        return this.ready().then(() => this._socket());
    }

    sm() {
        return this.ready().then(() => this._sm());
    }
}
