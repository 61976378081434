import React, { Component } from 'react';

import etherTulipsPagify from 'darwin/components/etherTulipsPagify';

import getAsset from 'darwin/lib/assets/getAsset';

var Styles = {
    container: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        margin: '24px 0',
    },
    a :{
        alignItems: 'center',
        margin: '24px 0',
    }
};

class BattleScene extends Component {
    static KEY = 'battle';

    render() {
        return (
            <a style={Styles.a}>
                <div style={Styles.container}>
                    <img src={getAsset("battleTulip.png")} />
                </div>
                <div style={Styles.container}>
                    <span style={Styles.container}>Battles coming soon! Check&nbsp;<a href="https://medium.com/@ethertulips" className="hoverUnderline">our blog</a>&nbsp;for future updates.</span>
                </div>
            </a >
        );
    }
}

export default etherTulipsPagify(BattleScene);
