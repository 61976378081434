import React, { Component } from 'react';

import MediaQuery from 'react-responsive';

import mergeStyles from 'darwin/lib/styling/mergeStyles';
import getAsset from 'darwin/lib/assets/getAsset';

var Styles = {
    splashContainer: {
        // borderTop: '1px solid #ccc',
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        minHeight: 400,
        background: 'url("' + getAsset('floralblue.png') + '")',
    },
    splashContainerMobile: {
        // borderTop: '1px solid #ccc',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        background: 'url("' + getAsset('floralblue.png') + '")',
    },
    splash: {
    },
    splashImage: {
        height: 250,
    },
    splashHead: {
    },
    splashSubhead: {
        fontSize: 'larger',
    },
    splashButton: {
        backgroundColor: '#F92A82',
        color: '#fff',
        textDecoration: 'none',
        padding: '12px 12px',
        borderRadius: 4,
        fontWeight: 'bold',
    },
};

export default class LandingSplash extends Component {
    render() {
        return (
            <>
                <MediaQuery minWidth={680}>
                    <div style={Styles.splashContainer}>
                        <div style={Styles.splashImageContainer}>
                            <img style={Styles.splashImage} src={getAsset("tulipexamples.png")} alt="Example tulip" />
                        </div>
                        <div style={Styles.splashContent}>
                            <h1 style={Styles.splashHead}>EtherTulips</h1>
                            <p style={Styles.splashSubhead}>
                                Tradable virtual tulips on the Ethereum blockchain that can fight each other. What could go wrong?
                            </p>
                            <p>
                                <a href="/shop" style={Styles.splashButton}>
                                    Buy now →
                                </a>
                            </p>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={679}>
                    <div style={Styles.splashContainerMobile}>
                        <div style={mergeStyles(Styles.splashImageContainer, {marginTop: 16})}>
                            <img style={mergeStyles(Styles.splashImage, {height: 200})} src={getAsset("tulipexamples.png")} alt="Example tulip" />
                        </div>
                        <div style={mergeStyles(Styles.splashContent, {display: 'flex', flexDirection: 'column', alignItems: 'center', paddingLeft: 16, paddingRight: 16, paddingBottom: 16})}>
                            <h1 style={mergeStyles(Styles.splashHead, {marginBottom: 0})}>EtherTulips</h1>
                            <p style={mergeStyles(Styles.splashSubhead, {textAlign: 'center'})}>
                                Tradable virtual tulips on the Ethereum blockchain that can fight each other. What could go wrong?
                            </p>
                            <p>
                                <a href="/shop" style={Styles.splashButton}>
                                    Buy now →
                                </a>
                            </p>
                        </div>
                    </div>
                </MediaQuery>
            </>
        );
    }
}
