import React, { Component } from 'react';
import Modal from 'react-modal';

import {ethers} from 'ethers';

import Actions from 'darwin/actions';

import Apis from 'darwin/lib/api';
import getAsset from 'darwin/lib/assets/getAsset';
import mergeStyles from 'darwin/lib/styling/mergeStyles';

import TulipImage from 'darwin/components/TulipImage';

const Api = Apis.v2Api;

var Styles = {
    controlArea: {
        height: 24,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    closeButton: {
        cursor: 'pointer',
    },
    content: {
        display: 'flex',
        flexDirection: 'row',
    },
    tulipImageContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    detailsContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
    },
    inputLine: {
        display: 'flex',
        flexDirection: 'row',
    },
    inputBoxAndError: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
    },
    inputAddrError: {
        fontSize: 10,
        color: 'red',
    },
    itemLegalAgreement: {
        textAlign: 'center',
    },
    splashButton: {
        textDecoration: 'none',
        padding: '.5em 1em',
        borderRadius: 4,
    },
    buttonDisabled: {
        backgroundColor: '#eee',
        color: '#555',
    },
    buttonEnabled: {
        backgroundColor: '#F92A82',
        color: '#fff',
    },
    transferNote: {
        textAlign: 'center',
        fontSize: 10,
    },
    transferStatus: {
        textAlign: 'center',
    },
    loadingSpinner: {
        height: 16,
    },
    txError: {
        textAlign: 'center',
        color: 'red',
    },
};

export default class SendTulipModal extends Component {
    get _dispatch() {
        return this.props.dispatch;
    }

    constructor(props) {
        super(props);

        this.state = {
            recipientAddress: '',
            metamaskWorking: false,
            ethTransaction: null,
            txError: false,
        };
    }

    render() {
        let {tulipId, appearance} = this.props.forTulip;
        if (this.props.v2) {
            var {petal, stem, face, rarity, name: species, gen} = appearance;
            var tulipImage = (<TulipImage
                loading={false}
                url={appearance.image}
            />);
        } else {
            var [petal, stem, face, rarity, species, gen] = appearance;
            var tulipImage = (<TulipImage
                loading={false}
                petal={petal}
                stem={stem}
                face={face}
                gen={gen}
            />);
        }
        let isAddressValid = ethers.utils.isAddress(this.state.recipientAddress);

        let sendButton;
        if (this.state.metamaskWorking) {
            sendButton = <span style={Styles.transferStatus}><img style={Styles.loadingSpinner}
                                                                  src={getAsset("animation/loading.gif")}/>&nbsp;Waiting for network...(may take a few minutes)</span>;
        } else if (this.state.ethTransaction) {
            sendButton = <span style={Styles.transferStatus}>Transaction sent. Track it <a href={"https://etherscan.io/tx/"+this.state.ethTransaction} className="hoverUnderline" target="_blank">here</a>!</span>;
        } else {
            sendButton = (
                <button disabled={!isAddressValid} style={mergeStyles(Styles.splashButton, isAddressValid ? Styles.buttonEnabled : Styles.buttonDisabled)}
                        onClick={() => {
                            this.setState({metamaskWorking: true, txError: null, ethTransaction: null});
                            this._dispatch(Actions.transferTulip(tulipId, this.state.recipientAddress))
                                .then(resp => {
                                    console.log(resp);
                                    this.setState({metamaskWorking: false, ethTransaction: resp.hash});
                                })
                                .catch(err => {
                                    console.log(err);
                                    this.setState({metamaskWorking: false, txError: err});
                                });
                        }}>
                    { "Transfer" }
                </button>
            );
        }

        let sendError;
        if (this.state.txError) {
            sendError = (
                <span style={Styles.txError}>Transaction error.</span>
            );
        }

        let inputAddrError = (
            <span style={Styles.inputAddrError}>Invalid ETH address.</span>
        );

        return (
            <Modal
                style={{
                    content: {
                        borderRadius: '4px',
                        bottom: 'auto',
                        minHeight: '10rem',
                        left: '50%',
                        paddingLeft: '2rem',
                        paddingRight: '2rem',
                        position: 'fixed',
                        right: 'auto',
                        top: '50%',
                        transform: 'translate(-50%,-50%)',
                        minWidth: '10rem',
                        width: '80%',
                        maxWidth: '40rem'
                    }
                }}
                onRequestClose={this.props.onRequestClose}
                isOpen={true}>

                <div style={Styles.controlArea}><span style={Styles.closeButton} onClick={this.props.onRequestClose}>Close</span></div>
                <div style={Styles.content}>
                    <div style={Styles.tulipImageContainer}>
                        {tulipImage}
                    </div>
                    <div style={Styles.detailsContainer}>
                        <div>
                        You are transferring a <strong>{species}</strong>.<br />
                        </div>
                        <br />
                        <div style={Styles.inputLine}>
                            <div>Recipient ETH Address:&nbsp;&nbsp;</div>
                            <div style={Styles.inputBoxAndError}>
                                <input type="text" value={this.state.recipientAddress} onChange={event => this.setState({recipientAddress: event.target.value})} style={{flex: 1}} />
                                {this.state.recipientAddress.length > 0 && !isAddressValid && inputAddrError}
                            </div>
                        </div>
                        <br />
                        <span style={Styles.itemLegalAgreement}>By proceeding, you agree to the <a href="/legal/terms" className="hoverUnderline">Terms of Use</a> and <a href="/legal/privacy" className="hoverUnderline">Privacy Policy</a>.</span>
                        <br />
                        {sendError}
                        {sendButton}
                        <br />
                        <span style={Styles.transferNote}>Note: Transfers are final and irreversible. For gas, we recommend using the MetaMask gas limit recommendation and <a href="https://ethgasstation.info/" className="hoverUnderline" target="_blank">a suitable gas price</a>.</span>
                    </div>
                </div>
                <div style={Styles.controlArea}></div>
            </Modal>
        );
    }
}
