const BATTLE_STAT_NAMES = [
    'Damage',
    'Armor',
    'Strength',
    'Agility',
    'Intelligence',
    'Health',
];

export default BATTLE_STAT_NAMES;
