import Strings from 'darwin/Strings';

const genInfo = {
    0: {
        name: 'Origins',
        seedName: 'A Humble Seed',
        seedDescription: Strings.shopGen0SeedDescription,
        learnMoreUrl: 'https://medium.com/@ethertulips/origins-generation-0-tulips-249a99b02203',
    },
    1: {
        name: 'Seedology 101',
        seedName: 'A Seedy Seed',
        seedDescription: Strings.shopGen1SeedDescription,
        learnMoreUrl: 'https://medium.com/@ethertulips/introducing-gen-1-a-sneak-peek-c4ad2531075d',
    }
};

export default genInfo;