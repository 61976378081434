const STRINGS = {
    socialRedditUrl: 'https://www.reddit.com/r/ethertulips',
    socialDiscordUrl: 'https://discord.gg/7xBAV9n6uD',
    socialTwitterUrl: 'https://www.twitter.com/ethertulips',
    socialMediumUrl: 'https://www.medium.com/@ethertulips',

    shopGen0SeedDescription: 'We found a pack of these in our garage. The labels seem to all be in Dutch except for one that says "Expiration: February 1637." None of us can read Dutch, but there appear to be a lot of warning signs and something about a "bubble." Whatever that means...',
    shopGen1SeedDescription: 'We took all the money from Gen 0 and went on a trip to the Dutch motherland. While we were there, we consulted the most renowned Tulipologists and Seedologists to create a new, never-before-seen seed. Introducing the Generation-1 Seed. It\'s unapologetically seedy.',
};

export default STRINGS;
