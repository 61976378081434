import Apis from 'darwin/lib/api';
const Api = Apis.v2Api;

export default function validateLoadout(loadout, starter) {
    return Api.validateOwnership(Object.keys(loadout))
        .then(valid => {
            if (Object.keys(loadout) == 0 && starter === null) {
                return true;
            }
            if (!valid || !loadout[starter] || loadout.length > 6) {
                return false;
            }
            return true;
        });
}
