const keyMirror = require('keymirror');

export default keyMirror({
    REQUEST_NUM_OWNED_TULIPS: null,
    RECEIVE_NUM_OWNED_TULIPS: null,
    REQUEST_NUM_OWNED_TULIPS_FAILED: null,
    REQUEST_MORE_OWNED_TULIPS: null,
    RECEIVE_MORE_OWNED_TULIPS: null,
    REQUEST_MORE_OWNED_TULIPS_FAILED: null,
    REQUEST_OWNED_TULIPS: null,
    RECEIVE_OWNED_TULIPS: null,
    REQUEST_OWNED_TULIPS_FAILED: null,
    REQUEST_BUY_TULIPS: null,
    BOUGHT_TULIPS: null,
    REQUEST_BUY_TULIPS_FAILED: null,
    REQUEST_SHOP_GEN_INFO: null,
    RECEIVE_SHOP_GEN_INFO: null,
    REQUEST_SHOP_GEN_INFO_FAILED: null,
    TRANSFER_TULIP_REQUEST: null,
    TRANSFER_TULIP_SUCCESS: null,
    TRANSFER_TULIP_FAILED: null,
    SWAP_API: null,
});
