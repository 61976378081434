import React, { Component } from 'react';

import etherTulipsPagify from 'darwin/components/etherTulipsPagify';

import LandingPage from 'darwin/components/LandingPage';

class WelcomeScene extends Component {
    static KEY = 'welcome';

    componentWillMount() {
        document.title = 'EtherTulips';
    }

    render() {
        return (
            <div>
                <LandingPage />
            </div>
        );
    }
}

export default etherTulipsPagify(WelcomeScene);