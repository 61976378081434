import React, { Component } from 'react';

import mergeStyles from 'darwin/lib/styling/mergeStyles';
import RarityLevels from 'darwin/lib/constants/RarityLevels';

import TulipImage from 'darwin/components/TulipImage';

var Styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        cursor: 'pointer',
    },
    tulipContainer: {
        backgroundColor: '#eee',
        borderRadius: 8,
        position: 'relative',
        paddingTop: 20,
    },
    tulipBpOverlay: {
        position: 'absolute',
        top: 8,
        right: 8,
        backgroundColor: 'rgba(255, 255, 255, 0.35)',
        height: 16,
        borderRadius: 12,
        fontSize: 12,
        paddingLeft: 4,
        paddingRight: 4,
    },
    tulipRarityOverlay: {
        position: 'absolute',
        top: 8,
        left: 8,
        backgroundColor: 'rgba(255, 255, 255, 0.35)',
        height: 16,
        borderRadius: 12,
        fontSize: 12,
        paddingLeft: 4,
        paddingRight: 4,
    },
    tulipSendOverlay: {
        position: 'absolute',
        bottom: 8,
        right: 8,
        height: 16,
        borderRadius: 16,
        border: '1px solid #333',
        padding: '6px 10px 10px 8px',
    },
    captionContainer: {
        textAlign: 'center',
        fontSize: 'smaller',
        marginTop: 8,
    },
    speciesCaption: {
        fontSize: 'larger',
        fontWeight: 'bold',
        fontStyle: 'italic',
    },
    disabledCell: {
        filter: 'opacity(0.2)',
    },
};

export default class TulipCell extends Component {
    constructor(props) {
        super(props);

        this.state = {
            mouseOver: false,
            filtered: null,
        };
    }

    componentWillMount() {
        if (this.props.filter) {
            this.props.filter(this.props.tulip.id)
                .then(filtered => this.setState({ filtered }));
        }
    }

    render() {
        let caption, image, infoOnClick, sendOnClick;
        let rarityView = null;
        let bpView = null;

        if (this.props.loading) {
            caption =
                <div>
                    <div style={Styles.speciesCaption}>Loading</div>
                    <div>...</div>
                </div>;
            image =
                <TulipImage
                    loading={true}
                    petal={null}
                    stem={null}
                    face={null}
                />;

        } else if (this.props.v2) {
            ({ caption, image, infoOnClick, sendOnClick, rarityView, bpView } = this.renderV2());
        } else {
            ({ caption, image, infoOnClick, sendOnClick, rarityView, bpView } = this.renderV1());
        }

        const sendIcon = (
            <div style={Styles.tulipSendOverlay} onClick={sendOnClick}>
                <span className="fa fa-send" style={{fontSize: 12}}></span>
            </div>
        );

        let containerStyle = Styles.container;
        if (this.props.disabled || this.state.filtered) {
            containerStyle = mergeStyles(containerStyle, Styles.disabledCell);
        }

        return (
            <div
                style={containerStyle}
                onMouseOver={() => this.setState({ mouseOver: true })}
                onMouseLeave={() => this.setState({ mouseOver: false })}
            >
                <div style={Styles.tulipContainer}>
                    <div onClick={infoOnClick}>
                        {image}
                    </div>
                    <div style={Styles.tulipBpOverlay} onClick={infoOnClick}>
                        {bpView}
                    </div>
                    <div style={Styles.tulipRarityOverlay}>
                        {rarityView}
                    </div>
                    {this.state.mouseOver && this.props.onSendClick && sendIcon}
                </div>
                <div style={Styles.captionContainer}>
                    {caption}
                </div>
            </div>
        );
    }

    renderV1() {
        let petal = this.props.appearance[0];
        let stem = this.props.appearance[1];
        let face = this.props.appearance[2];
        let rarity = this.props.appearance[3];
        let species = this.props.appearance[4];
        let gen = this.props.appearance[5];

        let bp = this.props.battleStats[0];
        let battleStats = this.props.battleStats.slice(1);

        let rarityStyle = {
            color: RarityLevels[rarity].color,
        };

        let rarityName = RarityLevels[rarity].name;

        if (rarity == 8) {
            rarityStyle = mergeStyles(rarityStyle, { textShadow: '1px 1px #FFFF00' });
            rarityName =
                <span>
                    &#127770; {rarityName} &#127770;
                </span>;
        }

        const infoOnClick = () => this.props.onClick && this.props.onClick(this.props.tulip, this.props.appearance, this.props.battleStats, species);
        const sendOnClick = () => this.props.onSendClick && this.props.onSendClick(this.props.tulip.id, this.props.appearance);

        const rarityView = (
            <div style={rarityStyle}>{rarityName}</div>
        );
        const bpView = (
            <span>BP {bp}</span>
        );
        const caption =
            <div>
                <div style={Styles.speciesCaption}>{species}</div>
                <div>Gen {gen}</div>
            </div>;
        const image =
            <TulipImage
                loading={false}
                petal={petal}
                stem={stem}
                face={face}
                gen={gen}
            />;

        return { caption, image, infoOnClick, sendOnClick, rarityView, bpView };
    }

    renderV2() {
        const data = this.props.appearance;
        const image =
            <TulipImage
                loading={false}
                url={data.image}
            />;
        const sendOnClick = () => this.props.onSendClick && this.props.onSendClick(this.props.tulip.id, data);

        if (data.name == 'Gen 2021 Seed') {
            let rarityStyle = {
                color: RarityLevels[1].color,
            };
            const caption = (
                <div>
                    <div style={Styles.speciesCaption}>Tulip Seed</div>
                    <div>Gen 2021</div>
                </div>
            );
            const rarityView = (
                <div style={rarityStyle}>??????</div>
            );
            const bpView = (
                <span>??</span>
            );

            return { caption, image, rarityView, bpView, sendOnClick };
        }

        let bp = data.bp;
        let allBattleStats = [data.bp, data.damage, data.armor, data.strength, data.agility, data.intelligence, data.health];
        let battleStats = allBattleStats.slice(1);

        let rarityStyle = {
            color: RarityLevels[data.rarity].color,
        };

        let rarityName = RarityLevels[data.rarity].name;

        if (data.rarity == 8) {
            rarityStyle = mergeStyles(rarityStyle, { textShadow: '1px 1px #FFFF00' });
            rarityName =
                <span>
                    &#127770; {rarityName} &#127770;
                </span>;
        }

        const infoOnClick = () => this.props.onClick && !this.state.filtered && this.props.onClick(this.props.tulip, this.props.appearance, allBattleStats, data);

        const rarityView = (
            <div style={rarityStyle}>{rarityName}</div>
        );
        const bpView = (
            <span>BP {data.bp}</span>
        );
        const claimPrice = (data.gen == 2021 ? '(0.025 ETH)' : '(0.01 ETH)');
        const caption =
            <div>
                <div style={Styles.speciesCaption}>{data.name}</div>
                <div>Gen {data.gen} {this.props.claimPrices && !this.state.filtered && claimPrice}</div>
            </div>;

        return { caption, image, infoOnClick, sendOnClick, rarityView, bpView };
    }
}
