import React, { Component } from 'react';

import etherTulipsPagify from 'darwin/components/etherTulipsPagify';

import Success from 'darwin/components/Success';

const Styles = {
    container: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        flexDirection: 'column',
        marginTop: 32,
    },
    text: {
        flex: 1,
        maxWidth: 1024,
    },
};

class PrivacyPolicyScene extends Component {
    static KEY = 'legal/privacy';

    componentWillMount() {
        document.title = 'EtherTulips: Privacy Policy';
    }
    render() {
        return (
            <div style={Styles.container}>
                <div style={Styles.text}>
                    <h1>Privacy Policy</h1>
                    
                    <br />
                    Last Updated: 8/30/2021
                    <br />

                    This Privacy Policy explains Ether Tulips' practices regarding the collection, use and disclosure of information that we receive through our Services. This Privacy Policy does not apply to any third-party websites, services or applications, even if they are accessible through our Services. Also, please note that, unless we define a term in this Privacy Policy, all capitalized terms used in this Privacy Policy have the same meanings as in our Terms of Service, located at https://ethertulips.com/legal/terms. So, please make sure that you have read and understand our Terms of Service.
                    <br />
                    <br />

                    1. Revisions to this Privacy Policy. Any information that is collected via our Services is covered by the Privacy Policy in effect at the time such information is collected. We may revise this Privacy Policy from time to time. If we make any material changes to this Privacy Policy, we’ll notify you of those changes by posting them on the Services or by sending you an email or other notification, and we’ll update the “Last Updated Date” above to indicate when those changes will become effective.
                    <br />
                    2. Information Collected or Received from You. Our primary goals in collecting information are to provide and improve our Services, to administer your use of the Services, and to enable you to enjoy and easily navigate our Services.
                    <br />
                    (a) Information Collected to Operate Our Services. Except as set forth in section 2(b) below, we do not require you to provide us with information that can be used to identify you, such as your name, email address, postal address and phone number (“PII”), in order to use our Services. We do, however, collect information such as your Ethereum network address to provide our Services to you. We may also collect information such as your IP address, web browser type, operating system, and data about your usage of our Services for analytical purposes to improve our provision of Services to you.  
                    <br />
                    (b) Information Submitted by Users. We may offer you opportunities to send digital items provided through the Services to friends or other users.  If you attempt to send digital items to another user through our Services, we may collect certain PII that you provide to us through the Site about you and the intended recipient, including your name, the recipient’s name, and the recipient’s Ethereum network address. We will only use this information to provide our Services to you, including facilitating any transactions that you initiate.    
                    <br />
                    (c) Information Collected Using Cookies and other Web Technologies. Like many website owners and operators, we use automated data collection tools such as Cookies and Web Beacons to collect certain information.
                    <br />
                    “Cookies” are small text files that are placed on your device by a web server when you access our Services. We may use both session Cookies and persistent Cookies to identify that you’ve logged in to the Services and to tell us how and when you interact with our Services. We may also use Cookies to monitor aggregate usage and web traffic routing on our Services and to customize and improve our Services. Unlike persistent Cookies, session Cookies are deleted when you log off from the Services and close your browser. Although most browsers automatically accept Cookies, you can change your browser options to stop automatically accepting Cookies or to prompt you before accepting Cookies. Please note, however, that if you don’t accept Cookies, you may not be able to access all portions or features of the Services. Some third-party services providers that we engage (including third-party advertisers) may also place their own Cookies on your device. Note that this Privacy Policy covers only our use of Cookies and does not include use of Cookies by such third parties.
                    <br />

                    <br />
                    “Web Beacons” (also known as web bugs, pixel tags or clear GIFs) are tiny graphics with a unique identifier that may be included on our Services for several purposes, including to deliver or communicate with Cookies, to track and measure the performance of our Services, to monitor how many visitors view our Services, and to monitor the effectiveness of our advertising. Unlike Cookies, which are stored on the device, Web Beacons are typically embedded invisibly on web pages (or in an e-mail).
                    <br />

                    <br />
                    “Log Data” means certain information about how a person uses our Services, including both Account holders and non-Account holders (either, a “User”). Log Data may include information such as a User’s Internet Protocol (IP) address, browser type, operating system, the web page that a User was visiting before accessing our Services, the pages or features of our Services to which a User browsed and the time spent on those pages or features, search terms, the links on our Services that a User clicked on and other statistics. We use Log Data to administer the Services and we analyze (and may engage third parties to analyze) Log Data to improve, customize and enhance our Services by expanding their features and functionality and tailoring them to our Users’ needs and preferences. We may use a person’s IP address to generate aggregate, non-identifying information about how our Services are used.
                    <br />

                    <br />
                    (d) Information Sent by Your Mobile Device. We collect certain information that your mobile device sends when you use our Services, like a device identifier, user settings and the operating system of your device, as well as information about your use of our Services.
                    <br />
                    (e) Location Information. When you use our App, we may collect and store information about your location by converting your IP address into a rough geo-location or by accessing your mobile device’s GPS coordinates or coarse location if you enable location services on your device. We may use location information to improve and personalize our Services for you. If you do not want us to collect your device’s GPS coordinates, you may disable that feature on your mobile device.
                    <br />
                    3. Information that We Share with Third Parties. We will not share any PII that we have collected from or regarding you except as described below:
                    <br />
                    (a) Information Shared with Our Services Providers. We may engage third-party services providers to work with us to administer and provide the Services. These third-party services providers have access to your PII only for the purpose of performing services on our behalf.  
                    <br />
                    (b) Information Shared with other Third Parties. We may share anonymized or aggregated data we collect from the use of the Services, such as de-identified demographic information, de-identified location information, information about the computer or device from which you access the Services, market trends and other analysis that we create based on the information we receive from you and other users.
                    <br />
                    (c) Information Shared with Web Analytics Services Providers. We use Google Analytics, a service provided by Google, Inc. (“Google”), to gather information about how users engage with our Site and Services. For more information about Google Analytics, please visit www.google.com/policies/privacy/partners/. You can opt out of Google’s collection and processing of data generated by your use of the Services by going to http://tools.google.com/dlpage/gaoptout.
                    <br />
                    (d) Information Disclosed in Connection with Business Transactions. Information that we collect from our users, including PII, is considered to be a business asset. Thus, if we are acquired by a third party as a result of a transaction such as a merger, acquisition or asset sale or if our assets are acquired by a third party in the event we go out of business or enter bankruptcy, some or all of our assets, including your PII, may be disclosed or transferred to a third party acquirer in connection with the transaction.
                    <br />
                    (e) Information Disclosed for Our Protection and the Protection of Others. We cooperate with government and law enforcement officials or private parties to enforce and comply with the law. We may disclose any information about you to government or law enforcement officials or private parties as we, in our sole discretion, believe necessary or appropriate: (i) to respond to claims, legal process (including subpoenas); (ii) to protect our property, rights and safety and the property, rights and safety of a third party or the public in general; and (iii) to stop any activity that we consider illegal, unethical or legally actionable activity.
                    <br />
                    4. Your Choices. We offer you choices regarding the collection, use and sharing of any PII you provide to us and we’ll respect the choices you make. Please note that if you decide not to provide us with the PII that we request, you may not be able to access all of the features of the Services.
                    <br />
                    (a) Modifying Your Information. You can learn more about the PII we have collected about you and modify, correct, or delete it by contacting us at contact@ethertulips.com with your request. We’ll take steps to delete your information as soon we can, but some information may remain in archived/backup copies for our records or as otherwise required by law.
                    <br />
                    5. Responding to Do Not Track Signals. Our Site does not have the capability to respond to “Do Not Track” signals received from various web browsers.
                    <br />
                    6. The Security of Your Information. We take reasonable administrative, physical and electronic measures designed to protect the information that we collect from or about you (including your PII) from unauthorized access, use or disclosure. When you enter sensitive information on our forms, we encrypt this data using SSL or other technologies. Please be aware, however, that no method of transmitting information over the Internet or storing information is completely secure. Accordingly, we cannot guarantee the absolute security of any information.
                    <br />
                    7. Links to Other Sites Our Services may contain links to websites and services that are owned or operated by third parties (each, a “Third-party Service”). Any information that you provide on or to a Third-party Service or that is collected by a Third-party Service is provided directly to the owner or operator of the Third-party Service and is subject to the owner’s or operator’s privacy policy. We’re not responsible for the content, privacy or security practices and policies of any Third-party Service. To protect your information we recommend that you carefully review the privacy policies of all Third-party Services that you access.
                    <br />
                    8. International Transfer Your PII may be transferred to, and maintained on, computers located outside of your state, province, country or other governmental jurisdiction where the privacy laws may not be as protective as those in your jurisdiction. If you’re located outside the United States and choose to provide your PII to us, we may transfer your PII to the United States and process it there.
                    <br />
                    9. Our Policy Toward Children. Our Services are not directed to children under 13 and we do not knowingly collect PII from children under 13. If we learn that we have collected PII of a child under 13 we will take steps to delete such information from our files as soon as possible.
                    <br />
                    10. Your California Privacy Rights. California residents may request and obtain from us, once a year, free of charge, a list of third parties, if any, to which we disclosed their PII for direct marketing purposes during the preceding calendar year and the categories of PII shared with those third parties. If you are a California resident and wish to obtain that information, please submit your request by sending us an email at  contact@ethertulips.com with “California Privacy Rights” in the subject line or by writing to us at 1309 Coffeen Avenue STE 1200, Sheridan, WY 82801.
                    <br />
                    11. Questions? Please contact us at contact@ethertulips.com if you have any questions about our Privacy Policy.
                    <br />
                </div>
            </div>
        );
    }

}

export default etherTulipsPagify(PrivacyPolicyScene);
