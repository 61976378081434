import ActionType from 'darwin/actions/ActionType';

const initialState = {fetching: false, gens: null};

export default function reducer(state = initialState, action = {}) {
    let newState = {
        ...state,
    };
    if (state.gens) {
        newState.gens = {...state.gens};
    }

    switch (action.type) {
        case ActionType.REQUEST_SHOP_GEN_INFO:
            newState.fetching = true;
            break;
        case ActionType.RECEIVE_SHOP_GEN_INFO:
            let {price, priceIncreaseDetails: [futurePrice, blocksRemaining, changeBlock]} = action.info;
            if (!newState.gens) {
                newState.gens = {};
            }
            newState.gens[String(action.gen)] = { price: price.toBigNumberJs(), futurePrice: futurePrice.toBigNumberJs(), blocksRemaining: blocksRemaining.toBigNumberJs(), changeBlock: changeBlock.toBigNumberJs() };
            newState.fetching = false;
            break;
        case ActionType.REQUEST_SHOP_GEN_INFO_FAILED:
            newState.fetching = false;
            break;
    }
    return newState;
}