import React, { Component } from 'react';

import EnvironmentVariables from 'darwin/EnvironmentVariables';

import TulipCell from './TulipCell';
import EmptyCell from './EmptyCell';

import SceneLoadingIndicator from 'darwin/components/SceneLoadingIndicator';

import mergeStyles from 'darwin/lib/styling/mergeStyles';

var Styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    gridContainer: {
        display: 'flex',
        justifyContent: 'center',
        padding: 32,
    },
    grid: {
        flex: 1,
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
        gridGap: '16px 8px',
        justifyContent: 'center',
        maxWidth: 1024,
        minWidth: 170,
    },
};

export default class MyTulipsGrid extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            appearances: null,
            battleStats: null,
        };

        this.appearancesCache = new Map();
        this.battleStatsCache = new Map();
    }

    componentDidMount() {
        this._componentReceivesProps(this.props);
    }

    componentWillReceiveProps(nextProps) {
        this._componentReceivesProps(nextProps);
    }

    render() {
        let sortedTulips = this.props.tulips.slice();
        switch (this.props.sortBy) {
            case 'new':
                sortedTulips = sortedTulips.sort((a, b) => {
                    return b.createTime - a.createTime;
                });
                break;
            case 'old':
                sortedTulips = sortedTulips.sort((a, b) => {
                    return a.createTime - b.createTime;
                });
                break;
            case 'rarity':
                sortedTulips = sortedTulips.sort((a, b) => {
                    if (!this.appearancesCache.has(a.genes)) {
                        return 1; // move unloaded tulips to the back
                    }
                    if (!this.appearancesCache.has(b.genes)) {
                        return -1; // same as above
                    }
                    let tulipAAppearance = this.appearancesCache.get(a.genes);
                    let tulipBAppearance = this.appearancesCache.get(b.genes);
                    let tulipARarity = tulipAAppearance[3];
                    let tulipBRarity = tulipBAppearance[3];
                    return tulipBRarity - tulipARarity;
                });
                break;
            case 'bp':
                sortedTulips = sortedTulips.sort((a, b) => {
                    if (!this.battleStatsCache.has(a.genes)) {
                        return 1; // move unloaded tulips to the back
                    }
                    if (!this.battleStatsCache.has(b.genes)) {
                        return -1; // same as above
                    }
                    let tulipABattleStats = this.battleStatsCache.get(a.genes);
                    let tulipBBattleStats = this.battleStatsCache.get(b.genes);
                    let tulipARarity = tulipABattleStats[0];
                    let tulipBRarity = tulipBBattleStats[0];
                    return tulipBRarity - tulipARarity;
                });
                break;
        }
        let cells = this._renderCells(sortedTulips);
        return (
            <div style={Styles.container}>
                <div style={Styles.gridContainer}>
                    <div style={Styles.grid}>
                        {cells}
                    </div>
                </div>
                <div>{ !this.state.loaded && <SceneLoadingIndicator /> }</div>
            </div>
        );
    }

    _renderCells(tulips) {
        let cells = [];
        for (let i = 0; i < tulips.length; i++) {
            if (this.appearancesCache.has(tulips[i].genes)) {
                const disabled = this.props.onlyEligible && tulips[i].id.toNumber() >= 7251;
                cells.push(
                    <TulipCell
                        onClick={this.props.onCellSelected}
                        onSendClick={this.props.onCellSendClicked}
                        loading={false}
                        key={tulips[i].genes}
                        tulip={tulips[i]}
                        appearance={this.appearancesCache.get(tulips[i].genes)}
                        battleStats={this.battleStatsCache.get(tulips[i].genes)}
                        disabled={disabled}
                    />
                );
            } else {
                cells.push(
                    <TulipCell
                        loading={true}
                        key={tulips[i].genes}
                        tulip={tulips[i]}
                        appearance={null}
                        battleStats={null}
                    />
                );
            }
        }
        while (cells.length<6){
            cells.push(
                <EmptyCell />
            );
        }
        return cells;
    }

    _componentReceivesProps(props) {
        let newTulips = [];
        let complete = true;
        for (let tulip of props.tulips) {
            if (!this.appearancesCache.has(tulip.genes)) {
                newTulips.push(tulip.genes);
            }
        }

        if (newTulips.length == 0) {
            return;
        } else if (newTulips.length > 60) {
            complete = false;
            newTulips = newTulips.slice(0, 60);
        }

        this.setState({
            loaded: false,
        });

        let genes = newTulips.join(',');
        let API_URL_BASE = EnvironmentVariables.LAMBDA_API_BASE;

        let url = API_URL_BASE + genes;
        let that = this;

        fetch(url, {mode: 'cors'}).then(function(response) {
            if (response.status != 200) {
                console.log(response.status);
            } else {
                response.json().then(function(data) {
                    for (let i = 0; i < data.a.length; i++) {
                        that.appearancesCache.set(newTulips[i], data.a[i]);
                        that.battleStatsCache.set(newTulips[i], data.b[i]);
                    }

                    that.setState({
                        loaded: true,
                        appearances: Array.from(that.appearancesCache.values()),
                        battleStats: Array.from(that.battleStatsCache.values()),
                    });

                    if (!complete) {
                        that._componentReceivesProps(props);
                    }
                });
            }
        }).catch(function(error) {
            console.log(error);
        });
    }
}
