const CONSTANTS = {
    blockTimeSeconds: 14,
    maxOwnedTulipsBatchSize: 60,
    myTulipsPaginationFetchMoreDistanceThreshold: 512,
    gen2021StartSaleTimeMilliseconds: 1630368000000,
    gardenStartSaleTimeMilliseconds: 1633136400000,
    // gen2021StartSaleTimeMilliseconds: 0,
};

export default CONSTANTS;
