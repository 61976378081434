import React, { Component } from 'react';

import etherTulipsPagify from 'darwin/components/etherTulipsPagify';

import My404Page from 'darwin/components/My404Page';

class My404Component extends Component {
    static KEY = '404';

    componentWillMount() {
        document.title = 'EtherTulips';
    }

    render() {
        return (
            <div>
                <My404Page/>
            </div>
        );
    }
}

export default etherTulipsPagify(My404Component);