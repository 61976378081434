import getAsset from 'darwin/lib/assets/getAsset';

export default class DuelSoundboard {
    constructor() {
        this.music = new Audio(getAsset('audio/duelBackground.mp3'));
        this.music.loop = true;
        this.music.volume = .4;

        this.smack = new Audio(getAsset('audio/smack0.mp3'));
        this.screams = [
            'scream0',
            'scream1',
            'scream2',
            'scream3',
        ].map((name) => new Audio(getAsset('audio/' + name + '.mp3')));
    }

    playMusic() {
        this.music.play()
            .then(_ => this.backgroundOn = true)
            .catch(_ => this.backgroundOn = false);
    }

    playMove() {
        if (!this.backgroundOn) {
            this.playMusic();
        }

        this.smack.play();
        setTimeout(() => {
            let i = Math.floor(Math.random() * this.screams.length);
            this.screams[i].play();
        }, 800);
    }
}
