import React, { Component } from 'react';
import Api from 'darwin/lib/api';
import Modal from 'react-modal';
import RarityLevels from 'darwin/lib/constants/RarityLevels';
import mergeStyles from 'darwin/lib/styling/mergeStyles';

import etherTulipsPagify from 'darwin/components/etherTulipsPagify';
import sceneRequiresApi from 'darwin/components/sceneRequiresApi';
import SceneLoadingIndicator from 'darwin/components/SceneLoadingIndicator';

import BattleStatsChart from 'darwin/components/TulipInfoModal/BattleStatsChart';

import MarketplacePage from 'darwin/components/MarketplacePage';

import StorageKey from 'darwin/lib/storage/StorageKey';

import Constants from 'darwin/Constants';

import 'styles/rarebits.css';

const Styles = {
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 24,
        flex: 1,
    },
    marketplaceCenter: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    container: {
        maxWidth: 1024,
        display: 'flex',
        flexDirection: 'column',
        marginTop: 24,
        marginBottom: 16,
    },
    blockMessage: {
        fontSize: 'large',
        borderRadius: 5,
        marginBottom: 24,
        padding: '.75rem 1.25rem',
        color: '#004085',
        backgroundColor: '#cce5ff',
        border: '2px solid #b8daff',
        flex: 1,
        marginLeft: 32,
        marginRight: 32,
    },
    headerFlexContainer: {
        display: 'flex',
        flexDirection: 'row',
        paddingLeft: 32,
        paddingRight: 32,
        paddingTop: 8,
        justifyContent: 'center',
        borderBottom: '2px solid #ddd',
    },
    header: {
        maxWidth: 1024,
        flex: 1,
        paddingBottom: 16,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
    },
    headerLeft: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
    },
    headerTitle: {
        fontSize: 48
    },
    spacer: {
        flex: 1,
    },
    listings: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    listingEntry: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: 8,
    },
    tulipContainer: {
        position: 'relative',
    },
    tulipBpOverlay: {
        position: 'absolute',
        bottom: 8,
        right: 24,
        backgroundColor: 'rgba(238, 238, 238, 0.65)',
        height: 16,
        borderRadius: 12,
        fontSize: 12,
        paddingLeft: 4,
        paddingRight: 4,
    },
    tulipRarityOverlay: {
        position: 'absolute',
        bottom: 8,
        left: 24,
        backgroundColor: 'rgba(238, 238, 238, 0.65)',
        height: 16,
        borderRadius: 12,
        fontSize: 12,
        paddingLeft: 4,
        paddingRight: 4,
    },
    speciesCaption: {
        fontSize: 14,
        fontWeight: 'bold',
        fontStyle: 'italic',
    },
    modalControlArea: {
        height: 24,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    modalCloseButton: {
        cursor: 'pointer',
    },
    modalBody: {
        display: 'flex',
        flexDirection: 'column',
    },
    modalImageAndDescription: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    modalDescription: {
        marginLeft: 16,
        display: 'flex',
        flexDirection: 'column',
    },
    modalTulipName: {
        fontSize: 20,
        fontWeight: 'bold',
        marginBottom: 4,
    },
    modalTulipDetails: {
        marginBottom: 8,
    },
    poweredByRarebits: {
        fontSize: 12,
        color: '#3453B1',
        fontWeight: 'bold',
    },
    manageListingsButton: {
        backgroundColor: 'rgb(88, 193, 93)',
        color: '#fff',
        textDecoration: 'none',
        padding: '.5em 1em',
        borderRadius: 4,
    },
    agreementContainer: {
        marginTop: 24,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: 512,
    },
    agreementContainerHeader: {
        fontWeight: 'bold',
        fontSize: 20,
        marginBottom: 8,
    },
    agreementHideFuture: {
        fontSize: 12,
    },
};

class MarketplaceScene extends Component {
    static KEY = 'marketplace';

    state = {
        blocked: true,
        page: 0,
        auctions: null,
        isFetchingMore: false,
        viewDetailsForListing: null,
        didAgreeToTerms: JSON.parse(localStorage.getItem(StorageKey.MARKETPLACE_AGREEMENT)) || false,
        hideTermsFutureChecked: true,
    };

    constructor(props) {
        super(props);

        this.scrollHandler = () => this._onScroll();
        this.resizeHandler = () => this._onScroll();

        this.containerRef = null;
    }

    componentWillMount() {
        document.title = 'EtherTulips: Marketplace';

        window.addEventListener('scroll', this.scrollHandler);
        window.addEventListener('resize', this.scrollHandler);

        Api.ready()
            .then(() => {
                return new Promise((resolve, reject) => {
                    window.callback = ipData => {
                        var countryCode = ipData.country;
                        var blacklist = [
                            'US', // United States
                            'UM', // United States Minor Outlying Islands
                            'VI', // Virgin Islands, U.S.
                            'PR', // Puerto Rico
                            'AS', // American Samoa
                            'GU', // Guam
                            'MP', // Northern Mariana Islands
                            'CA', // Canada
                        ];

                        if (blacklist.includes(countryCode)) {
                            this.setState({
                                blocked: true,
                            });
                        } else {
                            this.setState({
                                blocked: false,
                            });
                        }

                        resolve();
                    };

                    var ipDataScript = document.createElement('script');
                    ipDataScript.src = 'https://ipapi.co/jsonp';
                    var s = document.getElementsByTagName('script')[0];
                    s.parentNode.insertBefore(ipDataScript, s);
                });
            })
            .then(() => {
                // we use web3 0.20 and rarebits uses 1.0...
                // the solution? inject web3 1.0 before loading the rarebits SDK
                var web3script = document.createElement('script');
                var s = document.getElementsByTagName('script')[0];
                web3script.src = 'https://rarebits.io/embed/web3.min.js';
                web3script.addEventListener(
                    'load',
                    e => {
                        // web3 1.0 now injected
                        function async(u, c) {
                            var d = document, t = 'script',
                                o = d.createElement(t),
                                s = d.getElementsByTagName(t)[0];
                            var ts = Math.round((new Date()).getTime() / 1000);
                            o.src = u + ( u.indexOf("?") >= 0 ? "&" : "?") + 'ref=' + encodeURIComponent(window.location.href) + "&_ts=" + ts;
                            if (c) { o.addEventListener('load', function (e) { c(null, e); }, false); }
                            s.parentNode.insertBefore(o, s);
                        }
                        async('https://rarebits.io/embed/express.js', () => {
                            const RareBitsExpress = window.RareBitsExpress;
                            RareBitsExpress.init({contractAddress: "0x995020804986274763dF9deb0296B754f2659cA1"});

                            this.setState({page: 2})
                            let auctions = [];
                            RareBitsExpress.getLiveAuctions(
                                1,
                                data => {
                                    auctions = [...auctions, ...data.objects];
                                    RareBitsExpress.getLiveAuctions(
                                        2,
                                        data => {
                                            auctions = [...auctions, ...data.objects];
                                            this.setState({auctions});
                                        },
                                    );
                                },
                            );
                        });
                    },
                    false
                );
                s.parentNode.insertBefore(web3script, s);
            });
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.scrollHandler);
        window.removeEventListener('resize', this.scrollHandler);
    }

    render() {
        if (!this.state.auctions) {
            return (
                <div style={Styles.wrapper}>
                    <SceneLoadingIndicator />
                </div>
            );
        }

        let entries = [];
        for (let idx in this.state.auctions) {
            let token = this.state.auctions[idx];

            entries.push(this._renderTulipListing(token))
        }

        let body = this.state.didAgreeToTerms
            ? (
                <div style={Styles.container}>
                    {this.state.blocked && this._renderBlockedWarning()}
                    <div style={Styles.listings}>
                        {entries}
                    </div>
                </div>
            )
            : this._renderAgreement();

        return (
            <div style={Styles.wrapper} ref={ref => this.containerRef = ref}>
                { this.state.viewDetailsForListing !== null && this._renderListingDetailsModal()}
                {this._renderHeader()}
                <div style={Styles.marketplaceCenter}>
                    {body}
                </div>
            </div>
        );
    }

    _renderAgreement() {
        return (
            <div style={Styles.agreementContainer}>
                <span style={Styles.agreementContainerHeader}>Welcome to the EtherTulips Marketplace!</span>
                <div>Before continuing, please review the following:</div>
                <div>
                    <ul>
                        <li style={{marginBottom: 4}}>EtherTulips provides digital tulips solely for <strong>entertainment</strong> purposes.</li>
                        <li style={{marginBottom: 4}}>The digital tulips are <strong>not</strong> investments and there is no guarantee of resale value of any nature.</li>
                        <li style={{marginBottom: 4}}>We cannot reverse or modify any transactions conducted on the marketplace.</li>
                        <li style={{marginBottom: 4}}>We use a third-party, Rare Bits, to facilitate the marketplace. Please review Rare Bits' Terms of Service and Privacy Policy available at <a href="https://rarebits.io/" target="_blank">https://rarebits.io/</a>.</li>
                        <li style={{marginBottom: 4}}>Availability of the marketplace may be restricted in certain regions, such as the United States, due to local regulations.</li>
                    </ul>
                </div>
                <div>
                    By proceeding, you agree to the <a href="/legal/terms" className="hoverUnderline">Terms of Use</a> and <a href="/legal/privacy" className="hoverUnderline">Privacy Policy</a>.
                </div>
                <div style={{marginTop: 24, marginBottom: 24}}>
                    <a href="#" style={Styles.manageListingsButton} onClick={() => this._onMarketplaceAgree()}>
                        I understand
                    </a>
                </div>
                <div style={Styles.agreementHideFuture}><input type="checkbox" checked={this.state.hideTermsFutureChecked} onChange={ev => this.setState({hideTermsFutureChecked: ev.target.checked})} id="hideNextTime"/> <label htmlFor="hideNextTime">Don't show this message next time</label></div>
            </div>
        );
    }

    _renderTulipListing(listingObject) {
        let bp = listingObject.properties[0].value;
        let rarity = listingObject.properties[8].value;
        let rarityLevel = RarityLevels[rarity];
        let rarityName = rarityLevel.name;
        let rarityStyle = {color: rarityLevel.color};
        if (rarity == 8) {
            rarityStyle = mergeStyles(rarityStyle, { textShadow: '1px 1px #FFFF00' });
            rarityName =
                <span>&#127770; {rarityName} &#127770;</span>;
        }
        let rarityView = (
            <div style={rarityStyle}>{rarityName}</div>
        );
        let bpView = (
            <span>BP {bp}</span>
        );
        return (
            <div style={Styles.listingEntry}>
                <div style={Styles.tulipContainer}>
                    <img src={listingObject.image_url} onClick={() => this.setState({viewDetailsForListing: listingObject})} style={{cursor: 'pointer'}} />
                    <div style={Styles.tulipBpOverlay}>
                        {bpView}
                    </div>
                    <div style={Styles.tulipRarityOverlay}>
                        {rarityView}
                    </div>
                </div>
                <div style={{paddingBottom: 8, textAlign: 'center', cursor: 'pointer'}} onClick={() => this.setState({viewDetailsForListing: listingObject})}>
                    <div style={Styles.speciesCaption}>{listingObject.name}</div>
                    <div style={{fontSize: 13.33}}>Gen {listingObject.properties[7].value}</div>
                </div>
                <div style={{textAlign: 'center'}}>
                    { !this.state.blocked && <div data-rarebits="1" data-token-id={listingObject.token_id} /> }
                    { this.state.blocked && <div style={{fontSize: 12, marginTop: -8}}><span className="fa fa-tag" style={{color: '#FF7F50'}}></span>&nbsp;Ξ{listingObject.current_price_ether.toFixed(4)}</div>}
                </div>
            </div>
        )
    }

    _renderListingDetailsModal() {
        let token = this.state.viewDetailsForListing;
        let rarity = token.properties[8].value;
        let rarityLevel = RarityLevels[rarity];
        let rarityName = rarityLevel.name;
        let rarityStyle = {color: rarityLevel.color};
        if (rarity == 8) {
            rarityStyle = mergeStyles(rarityStyle, { textShadow: '1px 1px #FFFF00' });
            rarityName =
                <span>&#127770; {rarityName} &#127770;</span>;
        }
        let battleStats = [
            token.properties[1].value,
            token.properties[2].value,
            token.properties[3].value,
            token.properties[4].value,
            token.properties[5].value,
            token.properties[6].value,
        ];

        return (
            <Modal
                style={{
                    content: {
                        borderRadius: '4px',
                        bottom: 'auto',
                        minHeight: '10rem',
                        left: '50%',
                        paddingLeft: '2rem',
                        paddingRight: '2rem',
                        position: 'fixed',
                        right: 'auto',
                        top: '50%',
                        transform: 'translate(-50%,-50%)',
                        minWidth: '10rem',
                        width: '80%',
                        maxWidth: '40rem'
                    }
                }}
                onRequestClose={() => this.setState({viewDetailsForListing: null})}
                isOpen={true}>

                <div style={Styles.modalControlArea}><span style={Styles.modalCloseButton} onClick={() => this.setState({viewDetailsForListing: null})}>Close</span></div>
                <div style={Styles.modalBody}>
                    <div style={Styles.modalImageAndDescription}>
                        <div style={Styles.tulipContainer}>
                            <img src={token.image_url} />
                        </div>
                        <div style={Styles.modalDescription}>
                            <span style={Styles.modalTulipName}>{token.name}</span>
                            <span style={Styles.modalTulipDetails}>Gen {token.properties[7].value} &middot; <span style={rarityStyle}>{rarityName}</span> &middot; BP {token.properties[0].value} &middot; <span className="fa fa-tag" style={{color: '#FF7F50'}}></span>&nbsp;Ξ{token.current_price_ether.toFixed(4)}</span>
                            {!this.state.blocked && <div data-rarebits="1" data-token-id={token.token_id} />}
                        </div>
                    </div>
                    <div>
                        <BattleStatsChart battleStats={battleStats} />
                    </div>
                </div>
                <div style={Styles.modalControlArea}></div>
            </Modal>
        );
    }

    _renderBlockedWarning() {
        return (
            <span style={Styles.blockMessage}>
                The marketplace is restricted in your region due to local regulations. Buying and selling are disabled. <a href="https://medium.com/p/d68cf64e7445" target="_blank" style={{textDecoration: 'underline'}}>Learn more.</a>
            </span>
        );
    }

    _renderHeader() {
        return (
            <div style={Styles.headerFlexContainer}>
                <div style={Styles.header}>
                    <div style={Styles.headerLeft}>
                        <span style={Styles.headerTitle}>Marketplace</span>
                        <span style={{width: 16}} />
                        <a href="https://www.rarebits.io" target="_blank" style={Styles.poweredByRarebits} className="hoverUnderlinePreserveColor">Powered by RARΞ BITS</a>
                    </div>
                    <div style={Styles.spacer}></div>
                    <div style={Styles.headerRight}>
                        {!this.state.blocked && (
                            <a href="/marketplace/manage" style={Styles.manageListingsButton}>
                                Manage Listings
                            </a>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    _loadMoreListings() {
        let nextPage = this.state.page + 1;
        this.setState({page: this.state.page + 2});
        let auctions = [];
        const RareBitsExpress = window.RareBitsExpress;
        RareBitsExpress.getLiveAuctions(
            nextPage,
            data => {
                auctions = [...auctions, ...data.objects];
                RareBitsExpress.getLiveAuctions(
                    nextPage + 1,
                    data => {
                        auctions = [...auctions, ...data.objects];
                        this.setState({auctions: [...this.state.auctions, ...auctions], isFetchingMore: false});
                    },
                );
            },
        );
    }

    _onScroll() {
        if (this.state.auctions === null || this.state.isFetchingMore) {
            return;
        }
        if (this.containerRef) {
            let distance = this.containerRef.getBoundingClientRect().bottom - window.innerHeight;
            if (distance < Constants.myTulipsPaginationFetchMoreDistanceThreshold) {
                this.setState({isFetchingMore: true})
                this._loadMoreListings();
            }
        }
    }

    _onMarketplaceAgree() {
        this.setState({
            didAgreeToTerms: true,
        });
        if (this.state.hideTermsFutureChecked) {
            localStorage.setItem(StorageKey.MARKETPLACE_AGREEMENT, JSON.stringify(true));
        }
    }
}

let apiRequiredScene = sceneRequiresApi(MarketplaceScene)
export default etherTulipsPagify(apiRequiredScene);
