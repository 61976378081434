import React, { Component } from 'react';

import etherTulipsPagify from 'darwin/components/etherTulipsPagify';

import Success from 'darwin/components/Success';

const Styles = {
    container: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    }, 
    containerText: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        margin: '24px 0',
        justifyContent: 'center',
    },  
    containerText2: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },         
    a :{
        alignItems: 'center',
    }, 
};

class SuccessScene extends Component {
    static KEY = 'purchase_success';

    componentWillMount() {
        document.title = 'EtherTulips';
    }
    render() {
        let params = new URLSearchParams(this.props.location.search);
        return (
            <div style={Styles.a}>
                <Success />
                <div style={Styles.containerText}>    
                    <span style={Styles.container}> You've sent a transaction for {params.get('purchased')} tulips! Growing time depends on the gas price.  Stay tuned! </span>
                </div> 
                <div style={Styles.containerText}>  
                    <span style={Styles.container}>  Track your transaction&nbsp;<u><a href={'https://etherscan.io/tx/'+params.get('transactionHash')} className="hoverUnderline"> here</a></u>.</span>
                </div> 
                <div style={Styles.containerText}>  
                    If completed, your tulips will show up on&nbsp;<u><a href="/my_tulips" className="hoverUnderline"> My Tulips</a></u>.
                </div> 

            </div>
        );
    }

}

export default etherTulipsPagify(SuccessScene);