import React, { Component } from 'react';

import mergeStyles from 'darwin/lib/styling/mergeStyles';

var Styles = {
	moveButton: {
        border: '1px solid #000',
        color: '#fff',
        padding: 8,
        borderRadius: 5,
        marginRight: 6,
        textAlign: 'center',
        cursor: 'pointer',
        userSelect: 'none',
	},
};

export default class DuelMoveButton extends Component {
    render() {
        let iconClass = "fa fa-" + this.props.icon;
        let customStyle = {
            backgroundColor: this.props.background,
            borderColor: this.props.border,
            minWidth: (!this.props.minWidthAmount && this.props.minWidthAmount !== 0) ? 120 : this.props.minWidthAmount,
        };

        return (
            <div onClick={this.props.onClick} style={mergeStyles(Styles.moveButton, customStyle)}>
                <span className={iconClass}></span>{this.props.name && <span>&nbsp; {this.props.name}</span>}
            </div>
        );
    }
}
