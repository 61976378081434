import React, { Component } from 'react';
import { connect } from 'react-redux';

import Constants from 'darwin/Constants';
import Actions from 'darwin/actions';

import NoTulipIndicator from 'darwin/components/NoTulipIndicator'
import MyTulipsGrid from 'darwin/components/MyTulipsGrid';

const Styles = {
    container: {
        marginTop: 4,
        border: '1px solid #ccc',
        padding: 8,
        borderRadius: 4,
        maxHeight: 256,
        overflow: 'auto',
    },
    spacer: {
        flex: 1,
    },
};

class TulipPicker extends Component {
    get _dispatch() {
        return this.props.dispatch;
    }

    constructor(props) {
        super(props);

        this.containerRef = null;

        this.scrollHandler = () => this._onScroll();
        this.resizeHandler = () => this._onScroll();

        this.state = {
            sortBy: null,
        };
    }

    componentWillMount() {
        if (this.props.v1) {
            this._dispatch(Actions.swapApi());
        }
        if (!this.props.tulips) {
            this._dispatch(Actions.fetchMoreOwnedTulips(0, false));
        }
        if (this.props.totalNumOwnedTulips === null) {
            this._dispatch(Actions.fetchNumOwnedTulips(false));
        }

        window.addEventListener('resize', this.scrollHandler);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.scrollHandler);
    }

    render() {
        if (this.props.isLoading || !this.props.tulips) {
            var body = (
                <div style={{marginTop: 64}}><span className="fa fa-spinner" style={{color: '#555'}}></span></div>
            );
        } else if (this.props.tulips.length == 0) {
            var body = (
                <span>You don't have any tulips yet! Head over to the shop to get your first.</span>
            );
        } else {
            // var body = [];
            // for (let tulip of this.props.tulips) {
            //     const tulipId = tulip.id.toString();
            //     const imageUrl = tulip.id.gt(7250) ? `https://tokens.ethertulips.com/design/v2/${tulipId}.png` : `https://tokens.ethertulips.com/design/v1/${tulipId}.png`;
            //     body.push(
            //         <div style={{display: 'flex', flexDirection: 'row', paddingTop: 4, paddingBottom: 4}}>
            //             <img src={imageUrl} style={{width: 24, height: 24}}/>
            //         </div>
            //     );
            // };
            var body = (
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <div style={{width: 0, flex: 1}}>
                        <MyTulipsGrid
                            padding={0}
                            tulips={this.props.tulips}
                            onCellSelected={(tulip, appearance, battle, data) => this.props.onSelected(tulip, data)}
                            sortBy={this.state.sortBy}
                            v2={true}
                            filter={this.props.filter}
                            claimPrices={this.props.claimPrices}
                        />
                    </div>
                </div>
            );
        }

        return (
            <div style={Styles.container} ref={ref => this.containerRef = ref} onScroll={this.scrollHandler}>
                {body}
            </div>
        );
    }

    _onScroll() {
        if (this.props.isLoading || this.props.isFetchingMore) {
            return;
        }

        if (this.props.numTulipsRemaining && this.props.numTulipsRemaining.toNumber() > 0) {
            if (this.containerRef) {
                const distance = this.containerRef.scrollHeight - (this.containerRef.scrollTop + this.containerRef.offsetHeight);
                if (distance < 112) {
                    this._dispatch(Actions.fetchMoreOwnedTulips(this.props.tulips.length, false));
                }
            }
        }
    }
}

const mapStateToProps = state => {
    return {
        isLoading: state.ownedTulips.fetching,
        isFetchingMore: state.ownedTulips.fetchingMore,
        tulips: state.ownedTulips.tulips,
        totalNumOwnedTulips: state.ownedTulips.totalNumOwnedTulips,
        numTulipsRemaining: state.ownedTulips.numTulipsRemaining,
        v1: state.ownedTulips.v1,
    };
};

export default connect(mapStateToProps)(TulipPicker);
