import React, { Component } from 'react';

import mergeStyles from 'darwin/lib/styling/mergeStyles';
import getAsset from 'darwin/lib/assets/getAsset';

var Styles = {
    posRelative: {
        position: 'relative',
        top: 0,
        left: 0,
    },
    posAbsolute: {
        position: 'absolute',
        top: 0,
        left: 0,
    },
};

export default class TulipImage extends Component {
    render() {
        if (this.props.loading) {
            return (
                <div style={Styles.square}>
                    <img src={getAsset("loadingTulip.png")} style={Styles.square} alt="loading" />
                </div>
            );
        }

        let size = this.props.size || 152;
        let sizeStyle = {
            width: size,
            height: size,
        };

        let stemStyle = {};
        if (this.props.shadow) {
            stemStyle = {
                filter: 'drop-shadow(5px 5px 5px #444)',
            };
        }

        if (this.props.url) {
            return (
                <div style={mergeStyles(sizeStyle, Styles.posRelative)}>
                    <img src={this.props.url} style={mergeStyles(Styles.posAbsolute, sizeStyle)} alt="tulip image" />
                </div>
            );
        } else {
            let genDir = 'gen' + this.props.gen + '/';
            let petalSrc = getAsset(genDir + 'petals/' + this.props.petal + '.png');
            let stemSrc = getAsset(genDir + 'stems/' + this.props.stem + '.png');
            let faceSrc = getAsset(genDir + 'faces/' + this.props.face + '.png');

            return (
                <div style={mergeStyles(sizeStyle, Styles.posRelative)}>
                    <img src={stemSrc} style={mergeStyles(Styles.posRelative, mergeStyles(sizeStyle, stemStyle))} />
                    <img src={petalSrc} style={mergeStyles(Styles.posAbsolute, sizeStyle)} />
                    <img src={faceSrc} style={mergeStyles(Styles.posAbsolute, sizeStyle)} />
                </div>
            );
        }
    }
}
