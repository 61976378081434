import * as React from 'react';

import Apis from 'darwin/lib/api';

const v2Api = Apis.v2Api;

const Styles = {
};

export default function PlantedTulip(props) {
    const [imageUrl, setImageUrl] = React.useState(null);

    const { tulipId } = props;

    React.useEffect(() => {
        v2Api.tokenUri(tulipId)
            .then(uri => fetch(uri))
            .then(res => res.json())
            .then(data => setImageUrl(data.image));
    }, [tulipId]);

    return (imageUrl === null
        ? <span className="fa fa-spinner" style={{color: '#555'}}></span>
        : <img src={imageUrl} alt={props.tulipId} style={{ width: '100%', height: '100%'}} />
    );
}
