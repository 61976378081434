import Web3 from 'web3';
import {ethers} from 'ethers';

const ready = new Promise(function(resolve, reject) {
  if (document.readyState === 'complete') {
    resolve();
  }
  window.addEventListener('load', function() {
    resolve();
  });
});

export default async function getProvider() {
  await ready;

  if (window.ethereum) {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    await provider.send('eth_requestAccounts', []);
    return provider;
  } else {
    throw 'Metamask not installed.';
  }
}