import React, { Component } from 'react';

import etherTulipsPagify from 'darwin/components/etherTulipsPagify';

const Styles = {
    container: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        flexDirection: 'column',
        marginTop: 32,
    },
    text: {
        flex: 1,
        maxWidth: 1024,
    },
    link: {
        textDecoration: 'underline',
    },
};

class AttributionsScene extends Component {
    static KEY = 'legal/attributions';

    componentWillMount() {
        document.title = 'EtherTulips: Attributions';
    }

    render() {
        return (
            <div style={Styles.container}>
                <div style={Styles.text}>
                    <h1>Attributions</h1>
                    <h2>Sounds</h2>
                    <ul>
                        <li>
                            <a href="https://freesound.org/people/Sirkoto51/sounds/338817/" style={Styles.link} target="_blank">
                                RPG Battle Loop #1
                            </a> by Sirkoto51 on freesound.org
                        </li>
                        <li>
                            <a href="https://freesound.org/people/Ekokubza123/sounds/104183/" style={Styles.link} target="_blank">
                                punch.wav
                            </a> by Ekokubza123 on freesound.org
                        </li>
                        <li>
                            <a href="https://freesound.org/people/xtrgamr/sounds/261855/" style={Styles.link} target="_blank">
                                that must hurt >> Hit, OWWWWWWWW!.wav
                            </a> by xtrgamr on freesound.org
                        </li>
                        <li>
                            <a href="https://freesound.org/people/AlineAudio/sounds/416541/" style={Styles.link} target="_blank">
                                Screaming - Shouxtrgamrting - Yelling 1 >> Male_Scream_5.wav
                            </a> by AlineAudio on freesound.org
                        </li>
                        <li>
                            <a href="https://freesound.org/people/sironboy/sounds/132106/" style={Styles.link} target="_blank">
                                Screams >> Woman Scream
                            </a> by sironboy on freesound.org
                        </li>
                        <li>
                            <a href="https://freesound.org/people/TheSubber13/sounds/239900/" style={Styles.link} target="_blank">
                                Screams » Scream 1
                            </a> by TheSubber13 on freesound.org
                        </li>

                    </ul>
                </div>
            </div>
        );
    }
}

export default etherTulipsPagify(AttributionsScene);
