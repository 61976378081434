import {ethers} from 'ethers';

import getProvider from 'darwin/lib/web3/getProvider';
import deployedAddresses from 'darwin/lib/api/deployedAddresses.json';

import V1Contract from 'contracts/TulipCore.json';
import V2Contract from 'contracts/EtherTulipsV2.json';
import BridgeContract from 'contracts/EtherTulipsBridge.json';
import GardenContract from 'contracts/TulipGarden.json';

import {mapTulip} from './mappers';

class ContractAPI {
    constructor(contract, addresses) {
        // DON'T access these directly; use the provider() and account() promises
        // or, if you're feeling frisky, use maybeGetProvider() or maybeGetAccount()
        this.contract = contract;
        this.addresses = addresses;
        this._provider = null;
        this._account = null;
        this._contractAddress = null;
        this._readyPromise = this.initProvider()
            .then(() => this.loadAccount());
    }

    initProvider() {
        return getProvider()
            .then((provider) => {
                this._provider = provider;
                return provider.getNetwork();
            }).then(network => {
                const { chainId } = network;
                this._contractAddress = this.addresses[chainId];
                if (this._contractAddress === undefined) {
                    console.log('unsupported network', chainId);
                }
            });
    }

    async loadAccount() {
        const signer = this._provider.getSigner();
        const address = await signer.getAddress();

        if (!address) {
            throw 'No connected account';
        }

        this._account = address;

        return this._account;
    }

    ready() {
        return this._readyPromise;
    }

    account() {
        return this.ready().then(() => this._account);
    }

    provider() {
        return this.ready().then(() => this._provider);
    }

    contractAddress() {
        return this.ready().then(() => this._contractAddress);
    }

    maybeGetAccount() {
        return this._account;
    }

    maybeGetProvider() {
        return this._provider;
    }

    maybeGetContractAddress() {
        return this._contractAddress;
    }

    async _deployedContract() {
        const provider = await this.provider();
        const contractAddress = await this.contractAddress();
        return new ethers.Contract(
            contractAddress,
            this.contract,
            provider,
        );
    }

    async _deployedContractWithAccount() {
        const [provider, account] = await Promise.all([this.provider(), this.account()]);
        const contractAddress = await this.contractAddress();
        const contract = new ethers.Contract(
            contractAddress,
            this.contract,
            provider.getSigner(),
        );

        return [account, contract];
    }

    async _getContractAddress(provider) {
        const { chainId } = await provider.getNetwork();
        const address = this.addresses[chainId];
        if (address === undefined) {
            throw "unsupported network";
        }
        return address;
    }
}

class V1API extends ContractAPI {
    constructor() {
        super(V1Contract, deployedAddresses.v1);
    }

    getNumOwnedTulips() {
        return this._deployedContractWithAccount()
            .then(([acct, contr]) => {
                return contr.balanceOf(acct);
            });
    }

    fetchOwnedTulipsBatch(offset, maxAmount) {
        let deployed, account, amountRemaining;
        let tIds;
        return this._deployedContractWithAccount()
            .then(([acct, contr]) => {
                deployed = contr;
                account = acct;
                return deployed.myTulipsBatched(offset, maxAmount);
            })
            .then(([tulipIds, remaining]) => {
                amountRemaining = remaining;
                tIds = tulipIds;
                return Promise.all(
                    tulipIds.map(id => deployed.getTulip(id.toNumber())),
                );
            })
            .then(res => {
                let tulipData = [res.map(mapTulip), amountRemaining];
                for (let i = 0; i < tulipData[0].length; i++) {
                    tulipData[0][i].id = tIds[i];
                }
                return tulipData;
            });
    }

    countEligibleTulips(maxAmount) {
        const getNextBatch = (acct, contr, offset) => {
            return contr.myTulipsBatched(offset, maxAmount)
                .then(([tulipIds, remaining]) => {
                    let count = 0;
                    for (const tulipId of tulipIds) {
                        if (tulipId <= 7250) {
                            count++;
                        }
                    }

                    if (remaining > 0) {
                        return getNextBatch(acct, contr, offset + tulipIds.length)
                            .then(add => count + add);
                    } else {
                        return count;
                    }
                });
        };

        return this._deployedContractWithAccount()
            .then(([acct, contr]) => {
                return getNextBatch(acct, contr, 0);
            });
    }

    buyTulips(amount, gen, weiValue) {
        return this._deployedContractWithAccount()
            .then(([account, contr]) => {
                return contr.buyTulips(amount, gen, {value: weiValue.toString()});
            });
    }

    transferTulip(id, to) {
        return this._deployedContractWithAccount()
            .then(([account, contr]) => {
                return contr.transfer(to, id);
            });
    }

    approveBridge(id) {
        let contr;
        return Promise.all([this.provider(), this._deployedContractWithAccount()])
            .then(([provider, [account, contr_]]) => {
                contr = contr_;
                return provider.getNetwork();
            }).then(network => {
                var chainId = network.chainId;
                const bridgeAddress = deployedAddresses.bridge[chainId];
                return contr.approve(bridgeAddress, id);
            });
    }

    bridgeApproved(id) {
        return Promise.all([this.provider(), this._deployedContract()])
            .then(([provider, contr]) => {
                return Promise.all([provider.getNetwork(), contr.tulipToApproved(id)]);
            }).then(([network, addr]) => {
                var chainId = network.chainId;
                return addr == deployedAddresses.bridge[chainId];
            });
    }

    getPriceForGen(gen) {
        return this._deployedContract()
            .then(contr => {
                return contr.price(gen);
            });
    }

    getPriceIncreaseDetailsForGen(gen) {
        return this._deployedContract()
            .then(contr => {
                return contr.nextPrice(gen);
            });
    }
}

class V2API extends ContractAPI {
    constructor() {
        super(V2Contract, deployedAddresses.v2);
    }

    getNumOwnedTulips() {
        return this._deployedContractWithAccount()
            .then(([acct, contr]) => {
                return contr.balanceOf(acct);
            });
    }

    fetchOwnedTulipsBatch(offset, maxAmount) {
        let deployed, account, total;
        let tIds = [];
        return this._deployedContractWithAccount()
            .then(([acct, contr]) => {
                deployed = contr;
                account = acct;
                return deployed.balanceOf(acct);
            })
            .then((balance) => {
                total = balance;
                const promises = [];
                for (let idx = offset; idx < offset + maxAmount && idx < balance.toNumber(); idx++) {
                    promises.push(deployed.tokenOfOwnerByIndex(account, idx));
                }
                return Promise.all(promises);
            })
            .then((tokenIds) => {
                const promises = [];
                for (const id of tokenIds) {
                    tIds.push(id);
                    promises.push(deployed.tokenURI(id));
                }
                return Promise.all(promises);
            })
            .then((urls) => {
                const remaining = total - offset - urls.length;
                let tulipData = [];
                for (let i = 0; i < urls.length; i++) {
                    tulipData.push({ url: urls[i], id: tIds[i] });
                }
                return [tulipData, remaining];
            });
    }

    mintTulips(amount, weiValue) {
        return this._deployedContractWithAccount()
            .then(([account, contr]) => {
                return contr.mint(amount, {value: weiValue.toString()});
            });
    }

    transferTulip(id, to) {
        return this._deployedContractWithAccount()
            .then(([account, contr]) => {
                return contr['safeTransferFrom(address,address,uint256)'](account, to, id);
            });
    }

    approveBridge(id) {
        let contr;
        return Promise.all([this.provider(), this._deployedContractWithAccount()])
            .then(([provider, [account, contr_]]) => {
                contr = contr_;
                return provider.getNetwork();
            }).then(network => {
                var chainId = network.chainId;
                const bridgeAddress = deployedAddresses.bridge[chainId];
                return contr.setApprovalForAll(bridgeAddress, true);
            });
    }

    bridgeApproved(id) {
        let contr, account, bridgeAddress, approved;
        return Promise.all([this.provider(), this._deployedContractWithAccount()])
            .then(([provider, [account_, contr_]]) => {
                contr = contr_;
                account = account_;
                return Promise.all([provider.getNetwork(), contr.getApproved(id)]);
            }).then(([network, approvedAddr]) => {
                bridgeAddress = deployedAddresses.bridge[network.chainId];
                approved = bridgeAddress == approvedAddr;
                return contr.isApprovedForAll(account, bridgeAddress);
            }).then(approvedForAll => {
                return approved || approvedForAll;
            });
    }

    validateOwnership(ids) {
        let account;
        return this._deployedContractWithAccount()
            .then(([account_, contr]) => {
                account = account_;
                const promises = ids.map(id => contr.ownerOf(id));
                return Promise.all(promises);
            }).then(owners => {
                for (const owner of owners) {
                    if (owner != account) {
                        return false;
                    }
                }
                return true;
            });
    }

    getPrice() {
        return this._deployedContract()
            .then(contr => {
                return contr.price();
            });
    }

    salesStarted() {
        return this._deployedContract()
            .then(contr => {
                return contr.salesStarted();
            });
    }

    totalSupply() {
        return this._deployedContract()
            .then(contr => {
                return contr.totalSupply();
            });
    }

    tokenUri(tokenId) {
        return this._deployedContract().then(contr => contr.tokenURI(tokenId));
    }

    ownerOf(tokenId) {
        return this._deployedContract().then(contr => contr.ownerOf(tokenId));
    }
}

class BridgeAPI extends ContractAPI {
    constructor() {
        super(BridgeContract, deployedAddresses.bridge);
    }

    v1ToV2(id) {
        return this._deployedContractWithAccount()
            .then(([account, contr]) => {
                return contr.v1ToV2(id);
            });
    }

    v2ToV1(id) {
        return this._deployedContractWithAccount()
            .then(([account, contr]) => {
                return contr.v2ToV1(id);
            });
    }
}

class GardenAPI extends ContractAPI {
    constructor() {
        super(GardenContract, deployedAddresses.garden);
    }

    plotClaimed(id) {
        return this._deployedContract()
            .then(contr => {
                return contr.plotClaimed(id);
            });
    }

    plotInfo(id) {
        return this._deployedContract()
            .then(contr => {
                return Promise.all([
                    contr.ownerOf(id),
                    contr.plotToNftId(id),
                    contr.plotToNftAddress(id),
                    contr.plotData(id),
                ]);
            })
            .then(([owner, nftId, nftAddress, data]) => {
                return { owner, nftId, nftAddress, data };
            });
    }

    prices() {
        return this._deployedContract()
            .then(contr => {
                return Promise.all([
                    contr.PLOT_PRICE_EMPTY(),
                    contr.PLOT_PRICE_GEN_0_1(),
                    contr.PLOT_PRICE_GEN_2021(),
                ]);
            }).then(([empty, gen01, gen2021]) => {
                return { empty, gen01, gen2021 };
            });
    }

    claimEmpty(plotId) {
        let contr;
        return this._deployedContractWithAccount()
            .then(([account, _contr]) => {
                contr = _contr;
                return Promise.all([
                    contr.salesStarted(),
                    contr.PLOT_PRICE_EMPTY(),
                    contr.plotClaimed(plotId),
                ]);

            }).then(([started, price, claimed]) => {
                if (!started) {
                    throw new Error('Sales have not started');
                } else if (claimed) {
                    throw new Error('Plot already claimed');
                }
                return contr.claim(plotId, { value: price });
            });
    }

    claimWithTulip(plotId, tulipId) {
        let contr;
        return this._deployedContractWithAccount()
            .then(([account, _contr]) => {
                contr = _contr;
                const pricePromise = (tulipId <= 7250
                    ? contr.PLOT_PRICE_GEN_0_1()
                    : contr.PLOT_PRICE_GEN_2021()
                );
                return Promise.all([
                    contr.salesStarted(),
                    pricePromise,
                    contr.plotClaimed(plotId),
                    contr.tulipRedeemed(tulipId),
                ]);

            }).then(([started, price, claimed, redeemed]) => {
                if (!started) {
                    throw new Error('Sales have not started');
                } else if (claimed) {
                    throw new Error('Plot already claimed');
                } else if (redeemed) {
                    throw new Error('Tulip already redeemed');
                }

                return contr.claimWithTulip(plotId, tulipId, { value: price });
            });
    }

    plant(plotId, nftId, nftAddress) {
        return this._deployedContractWithAccount()
            .then(([account, contr]) => {
                return contr.plant(plotId, nftId, nftAddress);
            });
    }

    uproot(plotId) {
        return this._deployedContractWithAccount()
            .then(([account, contr]) => {
                return contr.uproot(plotId);
            });
    }

    nftPlanted(nftAddress, nftId) {
        return this._deployedContract()
            .then(contr => contr.nftPlanted(nftAddress, nftId));
    }

    tulipIrredeemable(v2Address, tulipId) {
        return this._deployedContract()
            .then(contr => {
                return Promise.all([
                    contr.nftPlanted(v2Address, tulipId),
                    contr.tulipRedeemed(tulipId),
                ]);
            }).then(([planted, redeemed]) => planted || redeemed);
    }
}

const v1Api = new V1API();
const v2Api = new V2API();
const bridgeApi = new BridgeAPI();
const gardenApi = new GardenAPI();

export default { v1Api, v2Api, bridgeApi, gardenApi };
