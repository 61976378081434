import React, { Component } from 'react';
import Modal from 'react-modal';

import {ethers} from 'ethers';

import Actions from 'darwin/actions';

import Apis from 'darwin/lib/api';
import getAsset from 'darwin/lib/assets/getAsset';
import mergeStyles from 'darwin/lib/styling/mergeStyles';

import TulipImage from 'darwin/components/TulipImage';

const {v1Api, v2Api, bridgeApi} = Apis;

var Styles = {
    modalContent: {
        borderRadius: '4px',
        bottom: 'auto',
        minHeight: '10rem',
        left: '50%',
        paddingLeft: '2rem',
        paddingRight: '2rem',
        position: 'fixed',
        right: 'auto',
        top: '50%',
        transform: 'translate(-50%,-50%)',
        minWidth: '10rem',
        width: '80%',
        maxWidth: '40rem',
    },
    controlArea: {
        height: 24,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    closeButton: {
        cursor: 'pointer',
    },
    content: {
        display: 'flex',
        flexDirection: 'row',
    },
    tulipImageContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    detailsContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
    },
    inputLine: {
        display: 'flex',
        flexDirection: 'row',
    },
    inputBoxAndError: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
    },
    inputAddrError: {
        fontSize: 10,
        color: 'red',
    },
    itemLegalAgreement: {
    },
    splashButton: {
        textDecoration: 'none',
        padding: '.5em 1em',
        borderRadius: 4,
    },
    buttonDisabled: {
        backgroundColor: '#eee',
        color: '#555',
    },
    buttonEnabled: {
        backgroundColor: '#F92A82',
        color: '#fff',
    },
    transferNote: {
        textAlign: 'center',
        fontSize: 10,
    },
    transferStatus: {
        textAlign: 'center',
    },
    loadingSpinner: {
        height: 16,
    },
    txError: {
        textAlign: 'center',
        color: 'red',
    },
};

export default class BridgeModal extends Component {
    get _dispatch() {
        return this.props.dispatch;
    }

    constructor(props) {
        super(props);

        this.state = {
            approved: null,
            bridged: null,
            metamaskWorkingApproval: false,
            approvalWaitingConfirmation: false,
            metamaskWorkingBridge: false,
            ethTransactionApproval: null,
            ethTransactionBridge: null,
            txErrorApproval: false,
            txErrorBridge: false,
            bridgeTxHash: null,
        };
    }

    componentWillMount() {
        let {tulip, appearance} = this.props.forTulip;
        const tulipId = tulip.id.toNumber();
        const that = this;

        const api = (this.props.v1 ? v1Api : v2Api);
        api.bridgeApproved(tulipId)
            .then(approved => this.setState({ approved }))
            .catch(err => {
                console.log(err);
            });
    }

    render() {
        let {tulip, appearance} = this.props.forTulip;
        const tulipId = tulip.id.toNumber();
        if (this.props.v1) {
            var [petal, stem, face, rarity, species, gen] = appearance;
            var tulipImage = (<TulipImage
                loading={false}
                petal={petal}
                stem={stem}
                face={face}
                gen={gen}
            />);
        } else {
            var {petal, stem, face, rarity, name: species, gen} = appearance;
            var tulipImage = (<TulipImage
                loading={false}
                url={appearance.image}
            />);
        }

        let bridgingEnabled = true;

        if (tulipId >= 7251) {
            var content = (
                <div style={{display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'center'}}>
                    <span>
                        Your Gen {gen} <strong>{species}</strong> is not eligible for bridging. Only Gen 0 and Gen 1 tulips minted
                        in 2018 can be bridged between the legacy and upgraded contracts.
                    </span>
                </div>
            );
            bridgingEnabled = false;
        } else {
            const startName = (this.props.v1 ? 'Legacy' : 'Upgraded');
            const endName = (this.props.v1 ? 'Upgraded' : 'Legacy');
            const api = (this.props.v1 ? v1Api : v2Api);
            const that = this;

            if (this.state.approved === null) {
                // loading initial approval
                var page = (<>Loading approval status...</>);
            } else if (!this.state.approved) {
                // not approved
                const approve = () => {
                    that.setState({ metamaskWorkingApproval: true });
                    api.approveBridge(tulipId)
                        .then((tx) => {
                            that.setState({
                                metamaskWorkingApproval: false,
                                approvalWaitingConfirmation: true,
                                txErrorApproval: null,
                            });
                            return tx.wait();
                        })
                        .then(() => that.setState({
                            approved: true,
                            metamaskWorkingApproval: false,
                        }))
                        .catch(err => {
                            that.setState({
                                metamaskWorkingApproval: false, approvalWaitingConfirmation: false, txErrorApproval: err.message });
                        });
                }
                const plurality = (this.props.v1 ? '' : 's');
                var page = (
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <span>First, approve the bridge to manage your tulip{plurality} on the {startName.toLowerCase()} contract.</span><br />
                        {(this.state.metamaskWorkingApproval ? <span><img style={Styles.loadingSpinner} src={getAsset("animation/loading.gif")} />
                <span style={{marginLeft: 4, fontWeight: 600, fontSize: 14}}>Waiting for confirmation</span></span> : this.state.approvalWaitingConfirmation ? <span><img style={Styles.loadingSpinner} src={getAsset("animation/loading.gif")} />
                <span style={{marginLeft: 4, fontWeight: 600, fontSize: 14}}>Waiting for transaction to confirm</span></span> : <button style={{border: 'none', backgroundColor: 'blue', padding: 12, borderRadius: 8, color: 'white', fontWeight: 'bold'}} onClick={approve}>Approve Bridge</button>)}
                        {this.state.txErrorApproval && <div style={{color: 'red'}}>{this.state.txErrorApproval}</div>}
                    </div>
                );

            } else if (!this.state.bridged) {
                // ready for bridging
                const bridge = () => {
                    that.setState({ metamaskWorkingBridge: true, txErrorBridge: null });
                    const bridgeFn = (that.props.v1 ? 'v1ToV2' : 'v2ToV1');
                    bridgeApi[bridgeFn](tulipId)
                        .then((tx) => that.setState({
                            bridged: true,
                            bridgeTxHash: tx.hash,
                            metamaskWorkingBridge: false,
                        }))
                        .catch(err => {
                            that.setState({
                                metamaskWorkingBridge: false, txErrorBridge: err.message });
                        });
                }
                var page = (
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <span>Your tulip is approved and ready to be bridged.</span><br />
                        {!this.state.metamaskWorkingBridge && (
                                <button style={{border: 'none', backgroundColor: 'blue', padding: 12, borderRadius: 8, color: 'white', fontWeight: 'bold'}} className={this.props.v1 ? "rainbowBackground" : undefined} onClick={bridge}>Bridge to {endName} Contract</button>
                        )}
                        {this.state.metamaskWorkingBridge && <span><img style={Styles.loadingSpinner} src={getAsset("animation/loading.gif")} />
                <span style={{marginLeft: 4, fontWeight: 600, fontSize: 14}}>Waiting for confirmation</span></span>}
                        {this.state.txErrorBridge && <div style={{color: 'red'}}>{this.state.txErrorBridge}</div>}
                    </div>
                );
            } else {
                // completed
                var page = (
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <span style={{fontWeight: 'bold', color: 'green', marginBottom: 4}}>Transaction submitted!</span>
                        <button style={{border: 'none', backgroundColor: 'blue', padding: 12, borderRadius: 8, color: 'white', fontWeight: 'bold'}} onClick={() => window.open(`https://etherscan.io/tx/${this.state.bridgeTxHash}`,'_blank')}>Track on Etherscan <span className="fa fa-external-link"></span></button>
                    </div>
                );
            }

            var content = (
                <span>
                    <span>Bridging your Gen {gen} <strong>{species}</strong> from the {startName} Contract to the {endName} Contract.<br /><br /></span>
                    {page}
                </span>
            );
        }

        let txError;
        if (this.state.txError) {
            txError = (
                <span style={Styles.txError}>Transaction error.</span>
            );
        }

        return (
            <Modal
                style={{
                    content: Styles.modalContent,
                }}
                onRequestClose={this.props.onRequestClose}
                isOpen={true}>

                <div style={Styles.controlArea}><span style={Styles.closeButton} onClick={this.props.onRequestClose}>Close</span></div>
                <div style={Styles.content}>
                    <div style={Styles.tulipImageContainer}>
                        {tulipImage}
                    </div>
                    <div style={Styles.detailsContainer}>
                        { bridgingEnabled && (
                            <>
                                <span style={Styles.itemLegalAgreement}>By proceeding, you agree to the <a href="/legal/terms" className="hoverUnderline">Terms of Use</a> and <a href="/legal/privacy" className="hoverUnderline">Privacy Policy</a>.</span>
                                <br />
                            </>
                        )}
                        {content}
                        { bridgingEnabled && (
                            <>
                                <br />
                                {txError && <>{txError}<br /></>}
                                <span style={Styles.transferNote}>For gas, we recommend using the MetaMask gas limit recommendation and <a href="https://ethgasstation.info/" className="hoverUnderline" target="_blank">a suitable gas price</a>.</span>
                            </>
                        )}
                    </div>
                </div>
                <div style={Styles.controlArea}></div>
            </Modal>
        );
    }
}
