import React, { Component } from 'react';

import mergeStyles from 'darwin/lib/styling/mergeStyles';

import ShopTimeRemaining from './ShopTimeRemaining';

const Styles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 32,
    },
    itemImageContainer: {
        borderRadius: 8,
        cursor: 'pointer',
    },
    itemImage: {
        width: 152,
        height: 152,
    },
    itemDetails: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 16,
    },
    itemName: {
        fontSize: 20,
        color: '#2979FF',
    },
    itemPriceLine: {
        marginTop: 8,
    },
    itemEthSymbol: {
        fontSize: 20,
    },
    itemDescription: {
        marginTop: 8,
        fontStyle: 'italic',
        color: '#777',
    },
};

export default class ShopItemRow extends Component {
    render() {
        return (
            <div style={Styles.container}>
                <div style={mergeStyles(Styles.itemImageContainer, {backgroundColor: this.props.imageBackgroundColor})} onClick={this.props.onSelect}>
                    <div>
                        <img src={this.props.imageUrl} style={Styles.itemImage} />
                    </div>
                </div>
                <div style={Styles.itemDetails}>
                    <div>
                        <a href="#" onClick={this.props.onSelect} className="hoverUnderline" style={Styles.itemName}>
                            {this.props.name}
                        </a>
                    </div>
                    <div style={Styles.itemPriceLine}>
                        <span className="fa fa-tag" style={{color: '#FF7F50'}}></span>&nbsp;&nbsp;
                        <strong><span style={Styles.itemEthSymbol}>⟠</span>{this.props.price.toString()}</strong>
                    </div>
                    <div style={Styles.itemDescription}>
                        <span>{this.props.description}</span>
                    </div>
                </div>
            </div>
        );
    }
}
