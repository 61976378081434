import React, { Component } from 'react';

import Header from 'darwin/components/Header';
import Footer from 'darwin/components/Footer';

export default function etherTulipsPagify(SceneComponent) {
    return class extends Component {
        static KEY = SceneComponent.KEY;

        render() {
            return (
                <div style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
                    <Header pageKey={SceneComponent.KEY} />
                    <div style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
                        <SceneComponent {...this.props} />
                    </div>
                    <Footer />
                </div>
            );
        }
    }
}