import React, { Component } from 'react';
import { connect } from 'react-redux';

import Constants from 'darwin/Constants';
import Actions from 'darwin/actions';

import SceneLoadingIndicator from 'darwin/components/SceneLoadingIndicator';
import NoTulipIndicator from 'darwin/components/NoTulipIndicator'
import MarketplaceManageListingsGrid from 'darwin/components/MarketplaceManageListingsGrid';
import TulipInfoModal from 'darwin/components/TulipInfoModal';
import SendTulipModal from 'darwin/components/SendTulipModal';

import Api from 'darwin/lib/api';
import sceneRequiresApi from 'darwin/components/sceneRequiresApi';
import etherTulipsPagify from 'darwin/components/etherTulipsPagify';

import StorageKey from 'darwin/lib/storage/StorageKey';

const Styles = {
    container: {
        margin: '24px 0',
    },
    noticeFlexContainer: {
        display: 'flex',
        flexDirection: 'row',
        paddingLeft: 32,
        paddingRight: 32,
        paddingTop: 32,
        justifyContent: 'center',
    },
    notice: {
        flex: 1,
        maxWidth: 1024,
        border: '2px solid #FF7F50',
        padding: 8,
        height: 24,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 22,
    },
    headerFlexContainer: {
        display: 'flex',
        flexDirection: 'row',
        paddingLeft: 32,
        paddingRight: 32,
        paddingTop: 8,
        justifyContent: 'center',
        borderBottom: '2px solid #ddd',
    },
    header: {
        maxWidth: 1024,
        flex: 1,
        paddingBottom: 16,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
    },
    headerTitle: {
        fontSize: 48
    },
    headerRight: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    headerSortDropdown: {

    },
    spacer: {
        flex: 1,
    },
    manageListingsButton: {
        backgroundColor: 'rgb(88, 193, 93)',
        color: '#fff',
        textDecoration: 'none',
        padding: '.5em 1em',
        borderRadius: 4,
    },
    agreementContainer: {
        marginTop: 24,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: 512,
    },
    agreementContainerHeader: {
        fontWeight: 'bold',
        fontSize: 20,
        marginBottom: 8,
    },
    agreementHideFuture: {
        fontSize: 12,
    },
};

class MarketplaceManageListingsScene extends Component {
    static KEY = 'marketplace/manage';

    get _dispatch() {
        return this.props.dispatch;
    }

    constructor(props) {
        super(props);

        this.containerRef = null;

        this.scrollHandler = () => this._onScroll();
        this.resizeHandler = () => this._onScroll();

        this.state = {
            showingModalFor: null,
            sortBy: null,
            loaded: false,
            didAgreeToTerms: JSON.parse(localStorage.getItem(StorageKey.MARKETPLACE_AGREEMENT)) || false,
            hideTermsFutureChecked: true,
        };
    }

    componentWillMount() {
        document.title = 'EtherTulips: Manage Listings';
        if (!this.props.tulips) {
            this._dispatch(Actions.fetchMoreOwnedTulips(0));
        }
        if (this.props.totalNumOwnedTulips === null) {
            this._dispatch(Actions.fetchNumOwnedTulips());
        }

        window.addEventListener('scroll', this.scrollHandler);
        window.addEventListener('resize', this.scrollHandler);

        Api.ready()
            .then(() => {
                return new Promise((resolve, reject) => {
                    window.callback = ipData => {
                        var countryCode = ipData.country;
                        var blacklist = [
                            'US', // United States
                            'UM', // United States Minor Outlying Islands
                            'VI', // Virgin Islands, U.S.
                            'PR', // Puerto Rico
                            'AS', // American Samoa
                            'GU', // Guam
                            'MP', // Northern Mariana Islands
                            'CA', // Canada
                        ];

                        if (blacklist.includes(countryCode)) {
                            this.setState({
                                blocked: true,
                            });
                            this.props.history.push('/marketplace');
                        } else {
                            this.setState({
                                blocked: false,
                            });
                        }

                        resolve();
                    };

                    var ipDataScript = document.createElement('script');
                    ipDataScript.src = 'https://ipapi.co/jsonp';
                    var s = document.getElementsByTagName('script')[0];
                    s.parentNode.insertBefore(ipDataScript, s);
                });
            })
            .then(() => {
                // we use web3 0.20 and rarebits uses 1.0...
                // the solution? inject web3 1.0 before loading the rarebits SDK
                var web3script = document.createElement('script');
                var s = document.getElementsByTagName('script')[0];
                web3script.src = 'https://rarebits.io/embed/web3.min.js';
                web3script.addEventListener(
                    'load',
                    e => {
                        // web3 1.0 now injected
                        function async(u, c) {
                            var d = document, t = 'script',
                                o = d.createElement(t),
                                s = d.getElementsByTagName(t)[0];
                            var ts = Math.round((new Date()).getTime() / 1000);
                            o.src = u + ( u.indexOf("?") >= 0 ? "&" : "?") + 'ref=' + encodeURIComponent(window.location.href) + "&_ts=" + ts;
                            if (c) { o.addEventListener('load', function (e) { c(null, e); }, false); }
                            s.parentNode.insertBefore(o, s);
                        }
                        async('https://rarebits.io/embed/express.js', () => {
                            const RareBitsExpress = window.RareBitsExpress;
                            RareBitsExpress.init({contractAddress: "0x995020804986274763dF9deb0296B754f2659cA1"});
                            this.setState({loaded: true});
                        });
                    },
                    false
                );
                s.parentNode.insertBefore(web3script, s);
            });
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.scrollHandler);
        window.removeEventListener('resize', this.scrollHandler);
    }

    render() {
        if (!this.props.tulips || !this.state.loaded) {
            return this._renderLoading();
        }
        if (this.props.tulips.length == 0) {
            return this._renderNoTulips();
        }

        let header = this._renderHeader();
        let body = null;
        if (!this.state.didAgreeToTerms) {
            body = (
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                    {this._renderAgreement()}
                </div>
            );
        }
        else if (this.props.isLoading || this.state.blocked) {
            body = <div style={{marginTop: 64}}>{this._renderLoading()}</div>;
        } else {
            body =
                <MarketplaceManageListingsGrid
                    tulips={this.props.tulips}
                    sortBy={this.state.sortBy}
                    onCellSelected={(tulip, appearance, battle) => this.setState({showingModalFor: {tulip, appearance, battle}})} />;
        }

        return (
            <div style={Styles.container} ref={ref => this.containerRef = ref}>
                {
                    this.state.showingModalFor !== null
                    && (
                        <div>
                            <TulipInfoModal
                                onRequestClose={() => this.setState({showingModalFor: null})}
                                tulip={this.state.showingModalFor.tulip}
                                appearance={this.state.showingModalFor.appearance}
                                battle={this.state.showingModalFor.battle}
                            />
                        </div>)
                }
                {header}
                {body}
            </div>
        );
    }

    _renderHeader() {
        return (
            <div style={Styles.headerFlexContainer}>
                <div style={Styles.header}>
                    <span style={Styles.headerTitle}>Manage Listings</span>
                    <div style={Styles.spacer}></div>
                    <div style={Styles.headerRight}>
                        <a href="/marketplace" className="hoverUnderline"><span className="fa fa-angle-left"></span> Back to Marketplace</a>&nbsp;&nbsp;&middot;&nbsp;&nbsp;Sort by{' '}
                        <select style={Styles.headerSortDropdown} onChange={event => { this._dispatch(Actions.fetchOwnedTulips()); this.setState({sortBy: event.target.value})}}>
                            <option value="none">Default</option>
                            <option value="new">New</option>
                            <option value="old">Old</option>
                            <option value="rarity">Rarity</option>
                            <option value="bp">BP</option>
                        </select>
                    </div>
                </div>
            </div>
        );
    }

    _renderLoading() {
        return <SceneLoadingIndicator />;
    }

    _renderNoTulips() {
        return <NoTulipIndicator />;
    }

    _renderAgreement() {
        return (
            <div style={Styles.agreementContainer}>
                <span style={Styles.agreementContainerHeader}>Welcome to the EtherTulips Marketplace!</span>
                <div>Before continuing, please review the following:</div>
                <div>
                    <ul>
                        <li style={{marginBottom: 4}}>EtherTulips provides digital tulips solely for <strong>entertainment</strong> purposes.</li>
                        <li style={{marginBottom: 4}}>The digital tulips are <strong>not</strong> investments and there is no guarantee of resale value of any nature.</li>
                        <li style={{marginBottom: 4}}>We cannot reverse or modify any transactions conducted on the marketplace.</li>
                        <li style={{marginBottom: 4}}>We use a third-party, Rare Bits, to facilitate the marketplace. Please review Rare Bits' Terms of Service and Privacy Policy available at <a href="https://rarebits.io/" target="_blank">https://rarebits.io/</a>.</li>
                        <li style={{marginBottom: 4}}>Availability of the marketplace may be restricted in certain regions, such as the United States, due to local regulations.</li>
                    </ul>
                </div>
                <div>
                    By proceeding, you agree to the <a href="/legal/terms" className="hoverUnderline">Terms of Use</a> and <a href="/legal/privacy" className="hoverUnderline">Privacy Policy</a>.
                </div>
                <div style={{marginTop: 24, marginBottom: 24}}>
                    <a href="#" style={Styles.manageListingsButton} onClick={() => this._onMarketplaceAgree()}>
                        I understand
                    </a>
                </div>
                <div style={Styles.agreementHideFuture}><input type="checkbox" checked={this.state.hideTermsFutureChecked} onChange={ev => this.setState({hideTermsFutureChecked: ev.target.checked})} id="hideNextTime"/> <label htmlFor="hideNextTime">Don't show this message next time</label></div>
            </div>
        );
    }

    _onMarketplaceAgree() {
        this.setState({
            didAgreeToTerms: true,
        });
        if (this.state.hideTermsFutureChecked) {
            localStorage.setItem(StorageKey.MARKETPLACE_AGREEMENT, JSON.stringify(true));
        }
    }

    _onScroll() {
        if (this.props.isLoading || this.props.isFetchingMore) {
            return;
        }
        if (this.props.numTulipsRemaining && this.props.numTulipsRemaining.isGreaterThan(0)) {
            if (this.containerRef) {
                let distance = this.containerRef.getBoundingClientRect().bottom - window.innerHeight;
                if (distance < Constants.myTulipsPaginationFetchMoreDistanceThreshold) {
                    this._dispatch(Actions.fetchMoreOwnedTulips(this.props.tulips.length));
                }
            }
        }
    }
}

const mapStateToProps = state => {
    return {
        isLoading: state.ownedTulips.fetching,
        isFetchingMore: state.ownedTulips.fetchingMore,
        tulips: state.ownedTulips.tulips,
        totalNumOwnedTulips: state.ownedTulips.totalNumOwnedTulips,
        numTulipsRemaining: state.ownedTulips.numTulipsRemaining,
    };
};

let apiRequiredScene = sceneRequiresApi(MarketplaceManageListingsScene);
export default connect(mapStateToProps)(etherTulipsPagify(apiRequiredScene));
