import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import { BigNumber } from '@ethersproject/bignumber';
import BigNumberJs from 'bignumber.js';

BigNumber.prototype.toBigNumberJs = function() {
    return new BigNumberJs(this.toString());
};

ReactDOM.render(
  <App />,
  document.getElementById('root')
);
