import React, { Component } from 'react';

import etherTulipsPagify from 'darwin/components/etherTulipsPagify';

import getAsset from 'darwin/lib/assets/getAsset';
import mergeStyles from 'darwin/lib/styling/mergeStyles';

var Styles = {
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '24px 0',
    },
    menu: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '24px 0',
    },
    panel: {
        display: 'flex',
        flexDirection: 'column',
    },
    header: {
        fontSize: 32,
    },
    subheadQuote: {
        color: '#777',
        marginBottom: 20,
        fontSize: 12,
        fontStyle: 'italic',
        width: 384,
    },
    button: {
        flex: 1,
        padding: '8px 24px',
        borderRadius: 4,
        backgroundColor: '#F92A82',
        color: 'white',
        marginTop: 4,
    },
    warningMessage: {
        color: '#856404',
        backgroundColor: '#fff3cd',
        border: '1px solid #ffeeba',
        padding: '.75rem 1.25rem',
        borderRadius: 5,
        marginBottom: 24,
    },
    link: {
        textDecoration: 'underline',
    },
};

const QUOTES = [
    'Appear weak when you are strong, and strong when you are weak.',
    'The supreme art of war is to subdue the enemy without fighting.',
    'Supreme excellence consists of breaking the enemy\'s resistance without fighting.',
    'In the midst of chaos, there is also opportunity',
    'The greatest victory is that which requires no battle.',
    'Treat your tulips as you would your own beloved sons. And they will follow you into the deepest valley.',
];

class DuelMainMenu extends Component {
    static KEY = 'battles/duel/mainmenu';

    constructor(props) {
        super(props);

        this.quote = QUOTES[Math.floor(Math.random() * QUOTES.length)];

        document.title = 'EtherTulips: Duel';
    }

    render() {
        return (
            <div style={Styles.container}>
                <div style={Styles.menu}>
                    <div style={mergeStyles(Styles.panel, {marginRight: 16})}>
                        <img src={getAsset("battleTulip.png")} />
                    </div>
                    <div style={Styles.panel}>
                        <span style={Styles.header}>Duel</span>
                        <span style={Styles.subheadQuote}>"{this.quote}" - Sun Tzulip, The Art of War</span>
                        <button style={Styles.button} onClick={() => this.props.history.push('/battles/duel/game') }>Find a Game</button>
                        <button style={Styles.button} onClick={() => this.props.history.push('/battles/duel/loadout') }>Change Tulip Loadout</button>
                    </div>
                </div>
            </div>
        );
    }

    _renderWarning() {
        return (
            <div style={Styles.warningMessage}>
                <span className="fa fa-exclamation-triangle"></span> &nbsp; This is an early preview of battles.
                Features and gameplay are subject to change
                . <a href="https://medium.com/@ethertulips/battle-sneak-peek-now-live-a6451f027d84" style={Styles.link} target="_blank">Read more here</a>.
            </div>
        );
    }
}

export default etherTulipsPagify(DuelMainMenu);
