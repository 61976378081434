import React, { Component } from 'react';
import { connect } from 'react-redux';

import Constants from 'darwin/Constants';
import Actions from 'darwin/actions';

import SceneLoadingIndicator from 'darwin/components/SceneLoadingIndicator';
import NoTulipIndicator from 'darwin/components/NoTulipIndicator'
import MyTulipsGrid from 'darwin/components/MyTulipsGrid';
import BridgeModal from 'darwin/components/BridgeModal';
import BridgePrompt from 'darwin/components/BridgePrompt';

import sceneRequiresApi from 'darwin/components/sceneRequiresApi';
import etherTulipsPagify from 'darwin/components/etherTulipsPagify';

const Styles = {
    container: {
        margin: '24px 0',
    },
    noticeFlexContainer: {
        display: 'flex',
        flexDirection: 'row',
        paddingLeft: 32,
        paddingRight: 32,
        paddingTop: 32,
        justifyContent: 'center',
    },
    notice: {
        flex: 1,
        maxWidth: 1024,
        border: '2px solid #FF7F50',
        padding: 8,
        height: 24,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 22,
    },
    headerFlexContainer: {
        display: 'flex',
        flexDirection: 'row',
        paddingLeft: 32,
        paddingRight: 32,
        paddingTop: 8,
        justifyContent: 'center',
        borderBottom: '2px solid #ddd',
    },
    header: {
        maxWidth: 1024,
        flex: 1,
        paddingBottom: 16,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
    },
    headerTitle: {
        fontSize: 48
    },
    headerRight: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    headerSortDropdown: {

    },
    spacer: {
        flex: 1,
    },
    directionSwitcher: {
        display: 'inline',
        fontSize: 20,
    },
    swapButton: {
        background: 'none',
        margin: '0 8px',
        borderRadius: 25,
        border: '2px solid #000',
    },
};

class MyTulipsScene extends Component {
    static KEY = 'bridge';

    get _dispatch() {
        return this.props.dispatch;
    }

    constructor(props) {
        super(props);

        this.containerRef = null;

        this.scrollHandler = () => this._onScroll();
        this.resizeHandler = () => this._onScroll();

        this.state = {
            showingModalFor: null,
            sortBy: null,
        };
    }

    componentWillMount() {
        document.title = 'EtherTulips Bridge';
        window.scene = this;
        if (!this.props.tulips) {
            this._dispatch(Actions.fetchMoreOwnedTulips(0, this.props.v1));
        }
        if (this.props.totalNumOwnedTulips === null) {
            this._dispatch(Actions.fetchNumOwnedTulips(this.props.v1));
        }

        window.addEventListener('scroll', this.scrollHandler);
        window.addEventListener('resize', this.scrollHandler);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.scrollHandler);
        window.removeEventListener('resize', this.scrollHandler);
    }

    render() {
        const notice = (
            <div style={Styles.noticeFlexContainer}>
                <div style={Styles.notice}><span>For more information about bridging, see our <a href="https://medium.com/@ethertulips/introducing-the-ethertulips-bridge-ba88384e503a" target="_blank" style={{textDecoration: 'underline'}}>blog post</a></span></div>
            </div>
        );

        let header = this._renderHeader();
        if (this.props.isLoading || !this.props.tulips) {
            var body = (
                <div style={{marginTop: 64}}>{this._renderLoading()}</div>
            );
        } else if (this.props.tulips.length == 0) {
            var body = this._renderNoTulips();
        } else {
            var body = (
                <MyTulipsGrid
                    tulips={this.props.tulips}
                    onCellSelected={(tulip, appearance, battle) => this.setState({showingModalFor: {tulip, appearance, battle}})}
                    sortBy={this.state.sortBy} v2={!this.props.v1} onlyEligible={true} />
            );
        }

        return (
            <div style={Styles.container} ref={ref => this.containerRef = ref}>
                {
                    this.state.showingModalFor !== null
                    && (
                        <div>
                            <BridgeModal
                                onRequestClose={() => this.setState({showingModalFor: null})}
                                forTulip={this.state.showingModalFor}
                                dispatch={this._dispatch}
                                v1={this.props.v1}
                            />
                        </div>
                    )
                }
                {header}
                {notice}
                {body}
            </div>
        );
    }

    _renderHeader() {
        let numTulipsLabel = this.props.totalNumOwnedTulips === null
            ? <span><span className="fa fa-spinner" style={{color: '#555'}}></span></span>
            : <span>{this.props.totalNumOwnedTulips.toString()} tulips</span>;
        return (
            <div style={Styles.headerFlexContainer}>
                <div style={Styles.header}>
                    <span style={Styles.headerTitle}>Bridge</span>
                    <div style={Styles.spacer}></div>
                    <div style={Styles.headerRight}>
                        {this._renderDirectionSwitcher()}&nbsp;&nbsp;&middot;&nbsp;&nbsp;
                        {numTulipsLabel}&nbsp;&nbsp;&middot;&nbsp;&nbsp;Sort by{' '}
                        <select style={Styles.headerSortDropdown} onChange={event => { this._dispatch(Actions.fetchOwnedTulips()); this.setState({sortBy: event.target.value})}}>
                            <option value="none">Default</option>
                            <option value="new">New</option>
                            <option value="old">Old</option>
                            <option value="rarity">Rarity</option>
                            <option value="bp">BP</option>
                        </select>
                    </div>
                </div>
            </div>
        );
    }

    _renderLoading() {
        return <SceneLoadingIndicator />;
    }

    _renderNoTulips() {
        return (
            <>
                <BridgePrompt />
                <NoTulipIndicator />
            </>
        );
    }

    _renderDirectionSwitcher() {
        const reverse = () => {
            const initV1 = this.props.v1;
            this._dispatch(Actions.swapApi());
            this._dispatch(Actions.fetchMoreOwnedTulips(0, !initV1));
            this._dispatch(Actions.fetchNumOwnedTulips(!initV1));
        };
        const swapButton = (
            <button style={Styles.swapButton} onClick={reverse}>
                &#8644;
            </button>
        );

        if (this.props.v1) {
            return (
                <div style={Styles.directionSwitcher}>
                    Legacy
                    {swapButton}
                    Upgraded
                </div>
            );
        } else {
            return (
                <div style={Styles.directionSwitcher}>
                    Upgraded
                    {swapButton}
                    Legacy
                </div>
            );
        }
    }

    _onScroll() {
        if (this.props.isLoading || this.props.isFetchingMore) {
            return;
        }
        if (this.props.numTulipsRemaining && this.props.numTulipsRemaining.toNumber() > 0) {
            if (this.containerRef) {
                let distance = this.containerRef.getBoundingClientRect().bottom - window.innerHeight;
                if (distance < Constants.myTulipsPaginationFetchMoreDistanceThreshold) {
                    this._dispatch(Actions.fetchMoreOwnedTulips(this.props.tulips.length, this.props.v1));
                }
            }
        }
    }
}

const mapStateToProps = state => {
    return {
        isLoading: state.ownedTulips.fetching,
        isFetchingMore: state.ownedTulips.fetchingMore,
        tulips: state.ownedTulips.tulips,
        totalNumOwnedTulips: state.ownedTulips.totalNumOwnedTulips,
        numTulipsRemaining: state.ownedTulips.numTulipsRemaining,
        v1: state.ownedTulips.v1,
    };
};

let apiRequiredScene = sceneRequiresApi(MyTulipsScene);
export default connect(mapStateToProps)(etherTulipsPagify(apiRequiredScene));
