import React, { Component } from 'react';

var Styles = {
    ribbonContainer: {
        display: 'flex',
        backgroundColor: '#2E3532',
    },
    ribbon: {
        padding: '40px 56px',
        color: '#eee',
    },
    ribbonHead: {
        color: '#fff',
    },
};

export default class LandingRibbon extends Component {
    render() {
        return (
            <div style={Styles.ribbonContainer}>
                <div style={Styles.ribbon}>
                    <h2 style={Styles.ribbonHead}>Tulip mania. Now with fights. On the blockchain.</h2>

                    <p>
                        Tulip mania was a bubble in the 17th century during which prices for tulips rose to ridiculous
                        levels. One bulb cost more than a house! The tulip market crashed in 1637, so you were born too
                        late to participate &mdash; but no worries!

                    </p>
                    <p>
                        We're bringing tulip mania to the 21st century. Each adorable EtherTulip lives on the Ethereum
                        blockchain, where they can be securely bought, traded, and battled. All EtherTulips have unique
                        DNA, which determines their appearance and battle traits. Want a particularly powerful tulip?
                        Buy some seeds, or trade with someone else. But hurry: prices are rising exponentially by
                        the day. You don't want to miss out, do you?
                    </p>
                </div>
            </div>
        );
    }
}
