import * as React from 'react';

import Constants from 'darwin/Constants';

import './Gen2021Banner.css';

function millisecondsToHoursMinutesSeconds(ms) {
    ms += 999; // add 999ms to round remaining milliseconds up to the next   second
    const hours = Math.floor(ms / (1000 * 60 * 60));
    const minutes = Math.floor(ms / (1000 * 60)) % 60;
    const seconds = Math.floor(ms / 1000) % 60;

    return [hours, minutes, seconds];
}

function leadingZero(num) {
    return num >= 0 && num < 10 ? '0' + num : +num;
}

export default function GardenBanner() {
    const [countdownMs, setCountdownMs] = React.useState(Constants.gardenStartSaleTimeMilliseconds - Date.now());

    React.useEffect(() => {
        const interval = setInterval(() => {
            setCountdownMs(Math.max(0, Constants.gardenStartSaleTimeMilliseconds - Date.now()));
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    const renderCountdown = () => {
        const [hours, minutes, seconds] = millisecondsToHoursMinutesSeconds(countdownMs);
        return <span style={{fontFamily: 'Inter, sans-serif', fontWeight: 600, fontSize: 20, color: 'white'}}>Get those tulips ready! Garden releases in {leadingZero(hours)}:{leadingZero(minutes)}:{leadingZero(seconds)} →</span>;
    };

    const renderBuyPromo = () => {
        return <span style={{fontFamily: 'Inter, sans-serif', fontWeight: 600, fontSize: 20, color: 'white'}}>The EtherTulips Garden is out! →</span>;
    };

    return (
        <div style={{display: 'flex', flexDirection: 'row', padding: 20, justifyContent: 'center'}} className="gen2021banner-rainbow-animation">
            <a href="/garden" className="hoverUnderline" style={{color: 'white', textAlign: 'center'}}>
                {countdownMs <= 0 ? renderBuyPromo() : renderCountdown()}
            </a>
        </div>
    );
}