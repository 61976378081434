const typeToColors = {
    'Bull': ['#58c15d', '#366f39'],
    'Bear': ['#e9322d', '#98211e'],
    'Whale': ['#0078e7', '#1d4488'],
    'Normie': ['#9b5545', '#563027'],
    'Dev': ['#9b46c5', '#341742'],
    'Miner': ['#eb5188', '#a03a5e'],
    'Institution': ['#efb102', '#b18302'],
    'Government': ['#5a5a5a', '#212121'],
};

export default typeToColors;