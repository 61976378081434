import React, { Component } from 'react';
import Modal from 'react-modal';

import BattleStatsChart from 'darwin/components/TulipInfoModal/BattleStatsChart';

var Styles = {
    controlArea: {
        height: 24,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    closeButton: {
        cursor: 'pointer',
    }
};

export default class TulipInfoModal extends Component {
    render() {
        return (
            <Modal
                style={{
                    content: {
                        borderRadius: '4px',
                        bottom: 'auto',
                        minHeight: '10rem',
                        left: '50%',
                        paddingLeft: '2rem',
                        paddingRight: '2rem',
                        position: 'fixed',
                        right: 'auto',
                        top: '50%',
                        transform: 'translate(-50%,-50%)',
                        minWidth: '10rem',
                        width: '80%',
                        maxWidth: '40rem'
                    }
                }}
                onRequestClose={this.props.onRequestClose}
                isOpen={true}>

                <div style={Styles.controlArea}><span style={Styles.closeButton} onClick={this.props.onRequestClose}>Close</span></div>
                    <BattleStatsChart battleStats={this.props.battle.slice(1)} />
                <div style={Styles.controlArea}></div>
            </Modal>
        );
    }
}
