import React, { Component } from 'react';

import getAsset from 'darwin/lib/assets/getAsset';

var Styles = {
    container: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        margin: '24px 0',
        justifyContent: 'center',

    }, 

    containerText: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        margin: '24px 0',
        justifyContent: 'center',
    },     
    a :{
        alignItems: 'center',
        margin: '24px 0',
    },
    img: {
        height: 320,
    }
};

export default class Success extends Component {
    render() {
        return (
            <div style={Styles.a}>
            <div style={Styles.container}>
                <img style={Styles.img} src={getAsset("justBoughtTulip.png")} />
            </div> 
            </div>
        );
    }
}
