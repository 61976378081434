import React, { Component } from 'react';

import EnvironmentVariables from 'darwin/EnvironmentVariables';
import BATTLE_STAT_NAMES from 'darwin/lib/constants/battleStatNames';

import TulipCell from './TulipCell';
import EmptyCell from './EmptyCell';

import SceneLoadingIndicator from 'darwin/components/SceneLoadingIndicator';

import mergeStyles from 'darwin/lib/styling/mergeStyles';

var Styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    gridContainer: {
        display: 'flex',
        justifyContent: 'center',
        padding: 32,
    },
    grid: {
        flex: 1,
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
        gridGap: '16px 8px',
        justifyContent: 'center',
        maxWidth: 1024,
        minWidth: 170,
    },
};

export default class MyTulipsGrid extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            appearances: null,
            battleStats: null,
        };

        this.appearancesCache = new Map();
        this.battleStatsCache = new Map();
    }

    componentDidMount() {
        this._componentReceivesProps(this.props);
    }

    componentWillReceiveProps(nextProps) {
        this._componentReceivesProps(nextProps);
    }

    render() {
        let sortedTulips = this.props.tulips.slice();
        switch (this.props.sortBy) {
            case 'new':
                sortedTulips = sortedTulips.sort((a, b) => {
                    return b.id - a.id;
                });
                break;
            case 'old':
                sortedTulips = sortedTulips.sort((a, b) => {
                    return a.id - b.id;
                });
                break;
            case 'rarity':
                sortedTulips = sortedTulips.sort((a, b) => {
                    if (!this.appearancesCache.has(a.id)) {
                        return 1; // move unloaded tulips to the back
                    }
                    if (!this.appearancesCache.has(b.id)) {
                        return -1; // same as above
                    }
                    let tulipAAppearance = this.appearancesCache.get(a.id);
                    let tulipBAppearance = this.appearancesCache.get(b.id);
                    let tulipARarity = tulipAAppearance[3];
                    let tulipBRarity = tulipBAppearance[3];
                    return tulipBRarity - tulipARarity;
                });
                break;
            case 'bp':
                sortedTulips = sortedTulips.sort((a, b) => {
                    if (!this.battleStatsCache.has(a.id)) {
                        return 1; // move unloaded tulips to the back
                    }
                    if (!this.battleStatsCache.has(b.id)) {
                        return -1; // same as above
                    }
                    let tulipABattleStats = this.battleStatsCache.get(a.id);
                    let tulipBBattleStats = this.battleStatsCache.get(b.id);
                    let tulipARarity = tulipABattleStats[0];
                    let tulipBRarity = tulipBBattleStats[0];
                    return tulipBRarity - tulipARarity;
                });
                break;
        }
        let cells = this._renderCells(sortedTulips);
        return (
            <div style={Styles.container}>
                <div style={mergeStyles(Styles.gridContainer, this.props.padding !== undefined && {padding: this.props.padding})}>
                    <div style={Styles.grid}>
                        {cells}
                    </div>
                </div>
                <div>{ !this.state.loaded && <SceneLoadingIndicator /> }</div>
            </div>
        );
    }

    _renderCells(tulips) {
        let cells = [];
        for (let i = 0; i < tulips.length; i++) {
            if (this.appearancesCache.has(tulips[i].id)) {
                const disabled = this.props.onlyEligible && tulips[i].id.toNumber() >= 7251;
                cells.push(
                    <TulipCell
                        onClick={this.props.onCellSelected}
                        onSendClick={this.props.onCellSendClicked}
                        loading={false}
                        key={tulips[i].id}
                        tulip={tulips[i]}
                        appearance={this.appearancesCache.get(tulips[i].id)}
                        battleStats={this.battleStatsCache.get(tulips[i].id)}
                        v2={this.props.v2}
                        disabled={disabled}
                        filter={this.props.filter}
                        claimPrices={this.props.claimPrices}
                    />
                );
            } else {
                cells.push(
                    <TulipCell
                        loading={true}
                        key={tulips[i].id}
                        tulip={tulips[i]}
                        appearance={null}
                        battleStats={null}
                        v2={this.props.v2}
                        filter={this.props.filter}
                        claimPrices={this.props.claimPrices}
                    />
                );
            }
        }
        while (cells.length<6){
            cells.push(
                <EmptyCell />
            );
        }
        return cells;
    }

    _componentReceivesProps(props) {
        let newTulips = [];
        let complete = true;
        for (let tulip of props.tulips) {
            if (!this.appearancesCache.has(tulip.id)) {
                newTulips.push(tulip);
            }
        }

        if (newTulips.length == 0) {
            return;
        } else if (newTulips.length > 60) {
            complete = false;
            newTulips = newTulips.slice(0, 60);
        }

        this.setState({
            loaded: false,
        });

        let that = this;

        newTulips.map((tulip) => {
            fetch(tulip.url)
                .then(res => res.json())
                .then(data => {
                    if (data.attributes) {
                        for (const attribute of data.attributes) {
                            data[attribute.trait_type.toLowerCase()] = attribute.value;
                        }
                        data.rarityName = data.rarity;
                        data.gen = parseInt(data.generation.slice(4))
                        const rarities = ['Very Common', 'Common', 'Uncommon', 'Rare', 'Very Rare', 'Epic', 'Legendary', 'MOOOON'];
                        data.rarity = rarities.indexOf(data.rarityName) + 1;
                    }

                    that.appearancesCache.set(tulip.id, data);
                    that.battleStatsCache.set(tulip.id, BATTLE_STAT_NAMES.map(stat => data[stat.toLowerCase()]));

                    that.setState({
                        loaded: true,
                        appearances: Array.from(that.appearancesCache.values()),
                        battleStats: Array.from(that.battleStatsCache.values()),
                    });

                    if (!complete) {
                        that._componentReceivesProps(props);
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        });
    }
}
