import React, { Component } from 'react'

import mergeStyles from 'darwin/lib/styling/mergeStyles';

var Styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    tulipContainer: {
        backgroundColor: '#eee',
        borderRadius: 8,
    },
    captionContainer: {
        textAlign: 'center',
        fontSize: 'smaller',
    },
    speciesCaption: {
        fontSize: 'larger',
        fontWeight: 'bold',
    },
};

export default class EmptyCell extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div style={Styles.container}>
                <div style={Styles.tulipContainer}>

                </div>
                <div style={Styles.captionContainer}>

                </div>
            </div>
        );
    }
}
