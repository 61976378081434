export default class GameClientSM {
    constructor(gameProps, gameState, myIndex) {
        this.gameProps = gameProps;
        this.gameState = gameState;
        this.myIndex = myIndex;
    }

    updateState(gameState) {
        this.gameState = gameState;
    }
}
