import React, { Component } from 'react';

import getAsset from 'darwin/lib/assets/getAsset';

var Styles = {
    container: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
};

export default class SceneLoadingIndicator extends Component {
    render() {
        return (
            <div style={Styles.container}>
                <img src={getAsset("animation/loading.gif")} style={{width: 100, height: 100, paddingBottom: 60}} />
            </div>
        );
    }
}
