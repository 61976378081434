import React, { Component } from 'react';

import getAsset from 'darwin/lib/assets/getAsset';

var Styles = {
    container: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        margin: '24px 0',
    }, 
    a :{
        alignItems: 'center',
        margin: '24px 0',
    }
};

export default class My404Page extends Component {
    render() {
        return (
            <a style={Styles.a}>
            <div style={Styles.container}>
                <img src={getAsset("ErrorTulip.png")} /> 
            </div> 
            <div style={Styles.container}>    
                <span style={Styles.container}> 404 Page not found.  Please double check your address! </span>
            </div> 
            </a >           
        );
    }
}
