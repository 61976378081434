const RARITY_LEVELS = {
    1: { name: 'Very Common', color: '#555'},
    2: { name: 'Common', color: '#2727a4' },
    3: { name: 'Uncommon', color: '#1a681a' },
    4: { name: 'Rare', color: '#850000' },
    5: { name: 'Very Rare', color: 'orange' },
    6: { name: 'Epic', color: 'purple' },
    7: { name: 'Legendary', color: '#ff2d52' },
    8: { name: 'MOOOON', color: '#333' },
};

export default RARITY_LEVELS;
