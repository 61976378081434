import * as React from 'react';

import etherTulipsPagify from 'darwin/components/etherTulipsPagify';

import Garden from 'darwin/components/Garden';
import GardenModal from 'darwin/components/Garden/GardenModal';

import Constants from 'darwin/Constants';

function millisecondsToHoursMinutesSeconds(ms) {
    ms += 999; // add 999ms to round remaining milliseconds up to the next   second
    const hours = Math.floor(ms / (1000 * 60 * 60));
    const minutes = Math.floor(ms / (1000 * 60)) % 60;
    const seconds = Math.floor(ms / 1000) % 60;

    return [hours, minutes, seconds];
}

function leadingZero(num) {
    return num >= 0 && num < 10 ? '0' + num : +num;
}

function GardenScene() {
    const [countdownMs, setCountdownMs] = React.useState(Constants.gardenStartSaleTimeMilliseconds - Date.now());
    const [showingModalFor, setShowingModalFor] = React.useState(null);

    const [hours, minutes, seconds] = millisecondsToHoursMinutesSeconds(countdownMs);

    React.useEffect(() => {
        const interval = setInterval(() => {
            setCountdownMs(Math.max(0, Constants.gardenStartSaleTimeMilliseconds - Date.now()));
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    const modal = showingModalFor !== null && (
        <GardenModal
            plotId={showingModalFor}
            onRequestClose={() => setShowingModalFor(null)}
        />
    );

    return (
        <div>
            <div className="gen2021banner-rainbow-animation" style={{display: 'flex', flexDirection: 'row', padding: 20, justifyContent: 'center', fontFamily: 'Inter, sans-serif', color: 'white', fontWeight: 600}}>
                {countdownMs > 0 ? (
                    <span>Plots will be available to claim in {leadingZero(hours)}:{leadingZero(minutes)}:{leadingZero(seconds)}</span>
                ) : (
                    <span>Plots are now available to claim!</span>
                )}
            </div>
            { modal }
            <Garden plotOnClick={id => setShowingModalFor(id)} preview={countdownMs > 0} />
        </div>
    );
}

GardenScene.KEY = 'garden';

export default etherTulipsPagify(GardenScene);