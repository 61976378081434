import React, { Component } from 'react';
import { connect } from 'react-redux';

import Constants from 'darwin/Constants';
import Actions from 'darwin/actions';

import SceneLoadingIndicator from 'darwin/components/SceneLoadingIndicator';
import NoTulipIndicator from 'darwin/components/NoTulipIndicator'
import DuelLoadoutGrid from 'darwin/components/DuelLoadoutGrid';
import TulipInfoModal from 'darwin/components/TulipInfoModal';
import SendTulipModal from 'darwin/components/SendTulipModal';
import BridgePrompt from 'darwin/components/BridgePrompt';

import sceneRequiresApi from 'darwin/components/sceneRequiresApi';
import etherTulipsPagify from 'darwin/components/etherTulipsPagify';

import StorageKey from 'darwin/lib/storage/StorageKey';
import validateLoadout from 'darwin/lib/duel/validateLoadout';

const MAX_TULIPS = 6;

const Styles = {
    container: {
        margin: '24px 0',
    },
    noticeFlexContainer: {
        display: 'flex',
        flexDirection: 'row',
        paddingLeft: 32,
        paddingRight: 32,
        paddingTop: 32,
        justifyContent: 'center',
    },
    notice: {
        flex: 1,
        maxWidth: 1024,
        border: '2px solid #FF7F50',
        padding: 8,
        height: 24,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 22,
    },
    headerFlexContainer: {
        display: 'flex',
        flexDirection: 'row',
        paddingLeft: 32,
        paddingRight: 32,
        paddingTop: 8,
        justifyContent: 'center',
        borderBottom: '2px solid #ddd',
    },
    header: {
        maxWidth: 1024,
        flex: 1,
        paddingBottom: 16,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
    },
    headerTitle: {
        fontSize: 48
    },
    headerRight: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    headerSortDropdown: {

    },
    spacer: {
        flex: 1,
    },
};

class DuelLoadoutScene extends Component {
    static KEY = 'battles/duel/loadout';

    get _dispatch() {
        return this.props.dispatch;
    }

    constructor(props) {
        super(props);

        this.containerRef = null;

        this.scrollHandler = () => this._onScroll();
        this.resizeHandler = () => this._onScroll();

        this.state = {
            showingModalFor: null,
            showingSendModalFor: null,
            sortBy: null,
            validated: false,
            selectedTulips: JSON.parse(localStorage.getItem(StorageKey.TULIP_LOADOUT)) || {},
            starterTulip: localStorage.getItem(StorageKey.TULIP_LOADOUT_PRIMARY) || null,
        };

        validateLoadout(this.state.selectedTulips, this.state.starterTulip)
            .then(valid => {
                if (!valid) {
                    this.setState({
                        validated: true,
                        selectedTulips: {},
                        startTulip: null,
                    });
                    localStorage.setItem(StorageKey.TULIP_LOADOUT, JSON.stringify({}));
                    localStorage.setItem(StorageKey.TULIP_LOADOUT_PRIMARY, null);
                } else {
                    this.setState({ validated: true });
                }
            });
    }

    componentWillMount() {
        document.title = 'EtherTulips: Duel Loadout';
        if (!this.props.tulips) {
            this._dispatch(Actions.fetchMoreOwnedTulips(0));
        }
        if (this.props.totalNumOwnedTulips === null) {
            this._dispatch(Actions.fetchNumOwnedTulips());
        }

        window.addEventListener('scroll', this.scrollHandler);
        window.addEventListener('resize', this.scrollHandler);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.scrollHandler);
        window.removeEventListener('resize', this.scrollHandler);
    }

    render() {
        if (!this.props.tulips || !this.state.validated) {
            return this._renderLoading();
        }
        if (this.props.tulips.length == 0) {
            return this._renderNoTulips();
        }

        let header = this._renderHeader();
        let body = this.props.isLoading
            ? (
            <div style={{marginTop: 64}}>{this._renderLoading()}</div>
        )
            : (
            <DuelLoadoutGrid
                tulips={this.props.tulips}
                selectedTulips={this.state.selectedTulips}
                starterTulip={this.state.starterTulip}
                onTulipSelected={tulip => this._onTulipSelected(tulip)}
                onTulipSetStarterClicked={id => {}}
                sortBy={this.state.sortBy} />
        );

        return (
            <div style={Styles.container} ref={ref => this.containerRef = ref}>
                {
                    this.state.showingModalFor !== null
                    && (
                        <div>
                            <TulipInfoModal
                                onRequestClose={() => this.setState({showingModalFor: null})}
                                tulip={this.state.showingModalFor.tulip}
                                appearance={this.state.showingModalFor.appearance}
                                battle={this.state.showingModalFor.battle}
                            />
                        </div>)
                }
                {
                    this.state.showingSendModalFor !== null
                    && (
                        <div>
                            <SendTulipModal
                                onRequestClose={() => this.setState({showingSendModalFor: null})}
                                forTulip={this.state.showingSendModalFor}
                                dispatch={this._dispatch}
                            />
                        </div>
                    )
                }
                {header}
                <BridgePrompt />
                {body}
            </div>
        );
    }

    _renderHeader() {
        let numTulipsLabel = this.props.totalNumOwnedTulips === null
            ? <span><span className="fa fa-spinner" style={{color: '#555'}}></span></span>
            : <span>Pick up to 6 tulips</span>;
        return (
            <div style={Styles.headerFlexContainer}>
                <div style={Styles.header}>
                    <span style={Styles.headerTitle}>Select Loadout</span>
                    <div style={Styles.spacer}></div>
                    <div style={Styles.headerRight}>
                        <a href="/battles/duel" className="hoverUnderline"><span className="fa fa-angle-left"></span> Back to Main Menu</a>&nbsp;&nbsp;&middot;&nbsp;&nbsp;{numTulipsLabel}&nbsp;&nbsp;&middot;&nbsp;&nbsp;Sort by{' '}
                        <select style={Styles.headerSortDropdown} onChange={event => { this._dispatch(Actions.fetchOwnedTulips()); this.setState({sortBy: event.target.value})}}>
                            <option value="none">Default</option>
                            <option value="new">New</option>
                            <option value="old">Old</option>
                            <option value="rarity">Rarity</option>
                            <option value="bp">BP</option>
                        </select>
                    </div>
                </div>
            </div>
        );
    }

    _renderLoading() {
        return <SceneLoadingIndicator />;
    }

    _renderNoTulips() {
        return <NoTulipIndicator />;
    }

    _onScroll() {
        if (this.props.isLoading || this.props.isFetchingMore) {
            return;
        }
        if (this.props.numTulipsRemaining && this.props.numTulipsRemaining.isGreaterThan(0)) {
            if (this.containerRef) {
                let distance = this.containerRef.getBoundingClientRect().bottom - window.innerHeight;
                if (distance < Constants.myTulipsPaginationFetchMoreDistanceThreshold) {
                    this._dispatch(Actions.fetchMoreOwnedTulips(this.props.tulips.length));
                }
            }
        }
    }

    _onTulipSelected(tulip) {
        let selectedTulips = {...this.state.selectedTulips};
        let starterTulip = this.state.starterTulip;
        if (tulip.id.toString() in selectedTulips) {
            delete selectedTulips[tulip.id.toString()];
            if (starterTulip == tulip.id.toString()) {
                if (Object.keys(selectedTulips).length == 0) {
                    starterTulip = null;
                } else {
                    starterTulip = Object.keys(selectedTulips)[0];
                }
            }
        } else {
            if (Object.keys(selectedTulips).length >= MAX_TULIPS) {
                return;
            }
            if (Object.keys(selectedTulips).length == 0) {
                starterTulip = tulip.id.toString();
            }
            selectedTulips[tulip.id.toString()] = true;
        }
        localStorage.setItem(StorageKey.TULIP_LOADOUT, JSON.stringify(selectedTulips));
        if (starterTulip) {
            localStorage.setItem(StorageKey.TULIP_LOADOUT_PRIMARY, starterTulip);
        } else {
            localStorage.removeItem(StorageKey.TULIP_LOADOUT_PRIMARY);
        }
        this.setState({selectedTulips, starterTulip});
    }
}

const mapStateToProps = state => {
    return {
        isLoading: state.ownedTulips.fetching,
        isFetchingMore: state.ownedTulips.fetchingMore,
        tulips: state.ownedTulips.tulips,
        totalNumOwnedTulips: state.ownedTulips.totalNumOwnedTulips,
        numTulipsRemaining: state.ownedTulips.numTulipsRemaining,
    };
};

let apiRequiredScene = sceneRequiresApi(DuelLoadoutScene);
export default connect(mapStateToProps)(etherTulipsPagify(apiRequiredScene));
