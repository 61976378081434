import React, { Component } from 'react';
import battleStatNames from 'darwin/lib/constants/battleStatNames';
import Chart from 'chart.js/auto';

var Styles = {
    chart: {

    },
};

export default class BattleStatsChart extends Component {
    render() {
        return (
            <canvas id="radarChart" style={Styles.chart}></canvas>
        );
    }

    componentDidMount() {
        let ctx = document.getElementById('radarChart');

        let chart = new Chart(ctx, {
            type: 'radar',
            data: {
                labels: battleStatNames,
                datasets: [{
                    label: '',
                    data: this.props.battleStats,
                    backgroundColor: 'rgba(150, 213, 242, 0.2)',
                    borderColor: 'rgba(150, 213, 242, 1)',
                    pointBackgroundColor: 'rgba(150, 213, 242, 1)',
                }],
            },
            options: {
                layout: {
                    padding: {
                        bottom: 8,
                    },
                },
                scales: {
                    r: {
                        min: 0,
                        max: 100,
                        ticks: {
                            maxTicksLimit: 6,
                        }
                    }
                },
                plugins: {
                    title: {
                        display: true,
                        text: 'Battle Stats',
                    },
                    legend: {
                        display: false,
                    },
                    tooltips: {
                        displayColors: false,
                    },
                }
            }
        });
    }
}
