import React, { Component } from 'react';

import getAsset from 'darwin/lib/assets/getAsset';

var Styles = {
    container: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    }, 
    a: {
        alignItems: 'center'
    }
};

export default class NoTulipIndicator extends Component {
    render() {
        return (
            <a style={Styles.a}>
            <div style={Styles.container}>
                <img src={getAsset("noTulip.png")} /> 
            </div> 
            <div style={Styles.container}>    
                <span style={Styles.container}> You don't have any tulips yet! Head over to the&#160;<a href="/shop" >shop</a>&#160;to get your first.  </span>
            </div> 
            </a>           
        );
    }
}
