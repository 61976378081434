import * as React from 'react';
import Pica from 'pica';

import Apis from 'darwin/lib/api';
import mergeStyles from 'darwin/lib/styling/mergeStyles';
import getAsset from 'darwin/lib/assets/getAsset';

import GardenModal from './GardenModal';
import PlantedTulip from './PlantedTulip';
import { tileMap, plotCoords, plotMap, gardenWidth, gardenHeight, tileNames, numPlots } from './gardenMap';

const { gardenApi, v2Api } = Apis;
const pica = Pica();

const Styles = {
    container: {
        width: '100vw',
        height: '100vh',
        overflow: 'hidden',
        backgroundColor: '#ddd',
        position: 'relative',
    },
};

function ControlBar(props) {
    return (
        <div>
            <span style={{color: '#fff'}}>Highlight:</span> &nbsp;
            <button onClick={() => props.setHighlighted(null)}>None</button>
            <button onClick={() => props.setHighlighted('all')}>All Plots</button>
            <button onClick={() => props.setHighlighted('unclaimed')}>Unclaimed Plots</button>
            <button onClick={() => props.setHighlighted('mine')}>My Plots</button>
        </div>
    );
}

export default function Garden(props) {
    const [canvasState, setCanvasState] = React.useState({
        s: 0.25,
        x: -gardenWidth * 200 * .25 / 2 + window.innerWidth / 2,
        y: -gardenHeight * 200 * .25 / 2 + window.innerHeight / 2,
        w: window.innerWidth,
        h: window.innerHeight,
        ready: false,
    });
    const [images, setImages] = React.useState({});
    const [fullImages, setFullImages] = React.useState({});
    const [clicking, setClicking] = React.useState(null);
    const [dragging, setDragging] = React.useState(false);
    const [plotVisible, setPlotVisible] = React.useState(Array(numPlots).fill(false));
    const [lastUpdate, setLastUpdate] = React.useState(Array(numPlots).fill(0));
    const [highlighted, setHighlighted] = React.useState(null);
    const [plotMine, setPlotMine] = React.useState(Array(numPlots).fill(false));

    const getCanvas = () => document.getElementById('garden-canvas');
    const autoRefreshMs = 30 * 1000;
    const tulipScale = 1.4;

    function render() {
        const state = canvasState;
        if (!state.ready) {
            return;
        }

        const canvas = getCanvas();
        canvas.setAttribute('width', state.w)
        canvas.setAttribute('height', state.h)

        const tileSize = 200 * state.s;
        const tulipSize = tileSize * tulipScale;
        const ctx = canvas.getContext('2d');

        for (let i = 0; i < gardenHeight; i++) {
            for (let j = 0; j < gardenWidth; j++) {
                const x = tileSize * j + state.x;
                const y = tileSize * i + state.y;
                if (!(x < state.w && x + tileSize > 0 && y + tileSize > 0 && y < state.h)) {
                    continue;
                }

                ctx.drawImage(images[tileMap[i][j]], x, y, tileSize, tileSize);
            }
        }

        for (let i = 0; i < gardenHeight; i++) {
            for (let j = 0; j < gardenWidth; j++) {
                const x = tileSize * j + state.x;
                const y = tileSize * i + state.y;
                if (!(x < state.w && x + tileSize > 0 && y + tileSize > 0 && y < state.h)) {
                    continue;
                }

                if (plotMap[i][j] != -1) {
                    const plotImage = images[plotMap[i][j]];
                    if (props.preview) {
                        if (state.s > 0.1 && tileMap[i][j] != 'lily_pad') {
                            ctx.drawImage(images['rows'], x, y, tileSize, tileSize);
                        }
                    } else if (plotImage === undefined) {
                        ctx.drawImage(images['spinner'], x + tileSize * .25, y + tileSize * .25, tileSize * .5, tileSize * .5);
                    } else if (plotImage == 'unclaimed') {
                        if (state.s > 0.1 && tileMap[i][j] != 'lily_pad') {
                            ctx.drawImage(images['rows'], x, y, tileSize, tileSize);
                        }
                    } else if (plotImage == 'unused') {
                        if (state.s > 0.1 && tileMap[i][j] != 'lily_pad') {
                            ctx.drawImage(images['rows'], x, y, tileSize, tileSize);
                        }
                    } else {
                        ctx.drawImage(plotImage, x - (tulipSize - tileSize) / 2, y - (tulipSize - tileSize) / 2, tulipSize, tulipSize);
                    }

                    if (
                        highlighted == 'all'
                        || highlighted == 'unclaimed' && plotImage == 'unclaimed'
                        || highlighted == 'mine' && plotMine[plotMap[i][j]]
                    ) {
                        ctx.fillStyle = 'rgba(0, 0, 255, 0.2)';
                        ctx.fillRect(x, y, tileSize, tileSize);
                    }
                }
            }
        }
    }

    function refreshVisible() {
        const state = canvasState;
        const tileSize = 200 * state.s;

        for (const [idx, [i, j]] of plotCoords.entries()) {
            const x = tileSize * j + state.x;
            const y = tileSize * i + state.y;
            plotVisible[idx] = (x < state.w && x + tileSize > 0 && y + tileSize > 0 && y < state.h);
        }

        setPlotVisible(plotVisible);
        loadContractData();
    }

    function loadContractData() {
        const promises = [];
        for (let i = 0; i < numPlots; i++) {
            if (!plotVisible[i] || Date.now() - lastUpdate[i] < autoRefreshMs) {
                continue;
            }
            lastUpdate[i] = Date.now();

            let promise = gardenApi.plotClaimed(i)
                .then(claimed => {
                    if (!claimed) {
                        fullImages[i] = 'unclaimed';
                        images[i] = 'unclaimed';
                        return;
                    }

                    return gardenApi.plotInfo(i)
                        .then(info => {
                            plotMine[i] = info.owner == v2Api.maybeGetAccount();
                            if (info.nftAddress == 0) {
                                fullImages[i] = 'unused';
                                images[i] = 'unused';
                                return;
                            }

                            return v2Api.tokenUri(info.nftId)
                                .then(uri => fetch(uri))
                                .then(res => res.json())
                                .then(data => downloadImage(i, data.image));
                        });
                }).catch(err => console.log(i, err));
            promises.push(promise);
        }

        Promise.all(promises)
            .then(() => {
                setImages(images);
                setFullImages(fullImages);
                setPlotMine(plotMine);
                render();
            });
    }

    function downloadImage(name, url) {
        return new Promise((resolve, reject) => {
            fullImages[name] = new Image();
            fullImages[name].crossOrigin = 'Anonymous';
            fullImages[name].onload = () => resolve();
            fullImages[name].src = url;
            images[name] = fullImages[name];
        });
    }

    function resizeImages() {
        const scale = canvasState.s;
        const promises = [];
        const resized = {};
        for (const [imageName, fullImage] of Object.entries(fullImages)) {
            if (typeof(fullImage) == 'string') continue;
            const canvas = document.createElement('canvas')
            if (isNaN(parseInt(imageName))) {
                canvas.width = fullImage.width * scale;
                canvas.height = fullImage.height * scale;
            } else {
                canvas.width = fullImage.width * scale * tulipScale;
                canvas.height = fullImage.height * scale * tulipScale;
            }
            resized[imageName] = canvas;

            const promise = pica.resize(fullImage, canvas, {
                quality: 3,
                alpha: true,
                unsharpAmount: 160,
                unsharpRadius: 0.6,
                unsharpThreshold: 1,
            });
            promises.push(promise);
        }

        Promise.all(promises)
            .then(() => {
                if (canvasState.s != scale) return;
                for (const [name, image] of Object.entries(resized)) {
                    images[name] = image;
                }
                setImages(images);
                render();
            });
    }

    React.useEffect(() => {
        // download images
        refreshVisible();
        const promises = tileNames.map(tileName => downloadImage(tileName, getAsset(`tiles/${tileName}.png`)));
        promises.push(downloadImage('spinner', getAsset('spinner.png')));
        promises.push(downloadImage('rows', getAsset('tiles/rows.png')));

        Promise.all(promises).then(() => {
            canvasState.ready = true;
            setFullImages(fullImages);
            setImages(images);
            resizeImages();
            setCanvasState(canvasState);
            render();
        });
    }, []);

    React.useEffect(() => {
        // mouse events
        const canvas = getCanvas();
        const mouseDownHandler = (event) => {
            setClicking({x: event.offsetX, y: event.offsetY});
        };
        canvas.onmousedown = mouseDownHandler;
        canvas.ontouchstart = (event) => {
            event.preventDefault();
            mouseDownHandler({ offsetX: event.touches[0].clientX, offsetY: event.touches[0].clientY });
        };

        const mouseUpHandler = (event) => {
            if (!dragging) {
                const i = Math.floor((event.offsetY - canvasState.y) / (200 * canvasState.s));
                const j = Math.floor((event.offsetX - canvasState.x) / (200 * canvasState.s));
                if (i >= 0 && j >= 0 && i < gardenHeight && j < gardenWidth && plotMap[i][j] != -1) {
                    document.body.style.cursor = 'default';
                    !props.preview && props.plotOnClick(plotMap[i][j]);
                }
            }
        };
        canvas.onmouseup = mouseUpHandler;
        canvas.ontouchend = (event) => {
            event.preventDefault();
            mouseUpHandler({ offsetX: event.changedTouches[0].clientX, offsetY: event.changedTouches[0].clientY });
        }

        const windowMouseUpHandler = (event) => {
            setClicking(null);
            setDragging(false);
        }
        window.onmouseup = windowMouseUpHandler;
        window.ontouchend = windowMouseUpHandler;
        window.ontouchcancel = windowMouseUpHandler;

        const mouseMoveHandler = (event) => {
            const i = Math.floor((event.offsetY - canvasState.y) / (200 * canvasState.s));
            const j = Math.floor((event.offsetX - canvasState.x) / (200 * canvasState.s));
            if (!props.preview && i >= 0 && j >= 0 && i < gardenHeight && j < gardenWidth && plotMap[i][j] != -1) {
                document.body.style.cursor = 'pointer';
            } else {
                document.body.style.cursor = 'default';
            }

            if (!clicking) {
                return;
            }

            const { x, y } = clicking;
            if (Math.abs(event.offsetX - x) < 2 && Math.abs(event.offsetY - y) < 2) {
                return;
            }
            canvasState.x += event.offsetX - x;
            canvasState.y += event.offsetY - y;

            setClicking({x: event.offsetX, y: event.offsetY});
            setDragging(true);
            refreshVisible();
            render();
        };
        canvas.onmousemove = mouseMoveHandler;
        canvas.ontouchmove = (event) => {
            event.preventDefault();
            mouseMoveHandler({ offsetX: event.touches[0].clientX, offsetY: event.touches[0].clientY });
        };

        canvas.onwheel = (event) => {
            if (!canvasState.ready) {
                return;
            }
            const amt = Math.min(Math.max(event.deltaY, -1000), 1000);
            let newScale = canvasState.s - amt * 2e-4;
            newScale = Math.min(Math.max(newScale, 0.05), 1);

            canvasState.x = event.clientX - (event.clientX - canvasState.x) * newScale / canvasState.s;
            canvasState.y = event.clientY - (event.clientY - canvasState.y) * newScale / canvasState.s;
            canvasState.s = newScale;
            setCanvasState(canvasState);

            refreshVisible();
            resizeImages();
            render();
            return false;
        };
    });

    React.useEffect(() => {
        // contract auto refresh
        const interval = setInterval(loadContractData, autoRefreshMs);
        return () => clearInterval(interval);
    }, []);

    React.useLayoutEffect(() => {
        // window resize
        const onResize = () => {
            canvasState.w = window.innerWidth;
            canvasState.h = window.innerHeight;
            setCanvasState(canvasState);
            refreshVisible();
        };

        window.addEventListener('resize', onResize);
        return () => window.removeEventListener('resize', onResize);
    });

    React.useEffect(render, [highlighted]);
    const highlightOnClick = (val) => {
        setHighlighted(val);
        render();
    };

    return (
        <div style={Styles.container}>
            <div style={{position: 'absolute', right: 4, top: 4, backgroundColor: 'rgba(0, 0, 0, 0.54)', padding: 8, borderRadius: 4}}>
                <ControlBar highlighted={highlighted} setHighlighted={highlightOnClick} />
            </div>
            <canvas id="garden-canvas" width={window.innerWidth} height={window.innerHeight}></canvas>
        </div>
    );
}
