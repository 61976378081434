import React, { Component } from 'react';

import Constants from 'darwin/Constants';

function zeroLeading(num) {
    return (num < 10 ? '0' : '') + num;
}

export default class ShopTimeRemaining extends Component {
    constructor(props) {
        super(props);

        this.intervalHandle = null;
        this.state = {
            secondsLeft: this.props.blocksRemaining.times(Constants.blockTimeSeconds),
        };
    }

    componentDidMount() {
        this.intervalHandle = setInterval(() => this.setState({secondsLeft: this.state.secondsLeft.minus(1)}), 1000);
    }

    componentWillUnmount() {
        this.intervalHandle && clearInterval(this.intervalHandle);
    }

    render() {
        let hours = Math.floor(this.state.secondsLeft / (60 * 60));
        let minutes = Math.floor((this.state.secondsLeft - hours * 60 * 60) / 60);
        let seconds = this.state.secondsLeft - hours * 60 * 60 - minutes * 60;

        return (
            <span>~{zeroLeading(hours)}:{zeroLeading(minutes)}:{zeroLeading(seconds)}</span>
        );
    }
}