import React, { Component } from 'react';

import V1 from './v1.js';
import V2 from './v2.js';

export default class MyTulipsGrid extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (this.props.v2) {
            return (
                <V2 {...this.props} />
            );
        } else {
            return (
                <V1 {...this.props} />
            );
        }
    }
}
