import React, { Component } from 'react';

import getAsset from 'darwin/lib/assets/getAsset';
import mergeStyles from 'darwin/lib/styling/mergeStyles';
import typeToColors  from 'darwin/lib/duel/typeToColors';
import DuelSoundboard from 'darwin/lib/audio/DuelSoundboard';

import DuelTulip from './DuelTulip';
import DuelMoveButton from './DuelMoveButton';
import DuelLog from './DuelLog';

var Styles = {
    wrapper: {
        padding: '0 32px',
        margin: '24px auto',
    },
    container: {
        display: 'flex',
        justifyContent: 'center',
        width: 1024,
    },
    interfaceContainer: {
        flex: 2,
    },
    simContainer: {
        margin: '0 12px 12px 0',
        padding: 12,
        border: '1px solid #9c9c9c',
        height: 360,
        display: 'flex',
        justifyContent: 'space-around',
        flexDirection: 'column',
        backgroundImage: `url("${getAsset('landscapes/1.jpg')}")`,
        backgroundSize: '130%',
        backgroundPosition: 'right bottom',
        overflow: 'hidden',
    },
    controlsContainer: {
        minHeight: 90,
    },
    actionMessage: {
        backgroundColor: '#333',
        color: '#fff',
        padding: 10,
        borderRadius: 4,
        fontWeight: 'bold',
        marginRight: 12,
    },
    buttonRow: {
        margin: '12px 12px 0 0',
        display: 'flex',
        flexDirection: 'row',
    },
    tulipContainer: {
        display: 'flex',
    },
    theirTulipContainer: {
        alignSelf: 'flex-end',
    },
    warningMessage: {
        color: '#856404',
        backgroundColor: '#fff3cd',
        border: '1px solid #ffeeba',
        padding: '.75rem 1.25rem',
        borderRadius: 5,
        marginBottom: 24,
    },
    link: {
        textDecoration: 'underline',
    },
    tulipSwitchList: {
        display: 'flex',
        flexDirection: 'column',
        marginRight: 12,
    },
    tulipSwitchEntry: {
        display: 'flex',
        flexDirection: 'row',
        padding: 8,
        border: '1px solid #333',
        borderRadius: 4,
        marginTop: 8,
        alignItems: 'center',
        height: 40,
    },
    tulipSwitchEntryLabel: {

    },
    tulipSwitchEntryButton: {
        borderRadius: 4,
        backgroundColor: '#ff3366',
        color: 'white',
        padding: 8,
        border: '1px solid #fff',
    },
    tulipSwitchBackButton: {
        borderRadius: 4,
        backgroundColor: '#000',
        color: 'white',
        padding: 8,
        marginTop: 8,
        border: '1px solid #333',
    },
    typeIndicator: {
        fontSize: 10,
        borderRadius: 4,
        padding: 2,
        color: '#fff',
        marginLeft: 4,
    },
};

const ACTION_MENU_MAIN = 'main';
const ACTION_MENU_MOVE = 'move';
const ACTION_MENU_SWITCH = 'switch';

export default class Duel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            actionNeeded: true,
            actionMenu: ACTION_MENU_MAIN,
        };
    }

    render() {
        let sim = this._renderSim();
        let controls = this._renderControls();
        let log = this._renderLog();
        let warning = this._renderWarning();

        return (
            <div style={Styles.wrapper}>
                {/*{warning}*/}
                <div style={Styles.container}>
                    <div style={Styles.interfaceContainer}>
                        {sim}
                        {controls}
                    </div>
                    {log}
                </div>
            </div>
        );
    }

    componentDidMount() {
        this.soundboard = new DuelSoundboard();
        this.soundboard.playMusic();
    }

    _renderSim() {
        let me = this.props.myIndex;
        let them = 1 - me;

        let myTulipProps = this.props.gameProps.tulips[me];
        let myTulipStates = this.props.gameState.tulips[me];
        let myActive = this.props.gameState.activeTulips[me];

        let theirTulipProps = this.props.gameProps.tulips[them];
        let theirTulipStates = this.props.gameState.tulips[them];
        let theirActive = this.props.gameState.activeTulips[them];

        let sim =
            <div style={Styles.simWrapper}>
                <div style={Styles.simContainer}>
                    <div style={Styles.theirTulipContainer}>
                        <DuelTulip
                            appearance={theirTulipProps[theirActive].appearance}
                            battleStats={theirTulipProps[theirActive].battleStats}
                            image={theirTulipProps[theirActive].image}
                            type={theirTulipProps[theirActive].type}
                            tulipState={theirTulipStates[theirActive]}
                            alignRight={true}
                        />
                    </div>

                    <div style={Styles.myTulipContainer}>
                        <DuelTulip
                            appearance={myTulipProps[myActive].appearance}
                            battleStats={myTulipProps[myActive].battleStats}
                            image={myTulipProps[myActive].image}
                            type={myTulipProps[myActive].type}
                            tulipState={myTulipStates[myActive]}
                            alignRight={false}
                        />
                    </div>
                </div>
            </div>;

        return sim;
    }

    _renderControls() {
        const STATUS = { // TODO: move this somewhere reasonable
            PLAYING: 0,
            FINISHED: 1,
            INTERRUPTED: 2,
        };
        let gameStatus = this.props.gameState.gameStatus;

        if (gameStatus == STATUS.PLAYING) {
            if (this.state.actionNeeded) {
                switch (this.state.actionMenu) {
                    case ACTION_MENU_MAIN:
                        return this._renderActionMenuMain();
                    case ACTION_MENU_MOVE:
                        return this._renderActionMenuMoves();
                    case ACTION_MENU_SWITCH:
                        return this._renderActionMenuSwitcher();
                }
            } else {
                return (
                    <div style={Styles.controlsContainer}>
                        <div style={Styles.actionMessage}>
                            Waiting for your opponent...
                        </div>
                    </div>
                );
            }
        } else if (gameStatus == STATUS.FINISHED) {
            let playAgain = this._renderPlayAgainButton();

            return (
                <div style={Styles.controlsContainer}>
                    <div style={Styles.actionMessage}>
                        Game Over!
                    </div>
                    <div style={Styles.buttonRow}>
                        {playAgain}
                    </div>
                </div>
            );
        } else if (gameStatus == STATUS.INTERRUPTED) {
            let playAgain = this._renderPlayAgainButton();

            return (
                <div style={Styles.controlsContainer}>
                    <div style={Styles.actionMessage}>
                        Your opponent disconnected!
                    </div>
                    <div style={Styles.buttonRow}>
                        {playAgain}
                    </div>
                </div>
            );
        }
    }

    _renderActionMenuMain() {
        return (
            <div style={Styles.controlsContainer}>
                <div style={Styles.actionMessage}>
                    Select an action.
                </div>
                <div style={Styles.buttonRow}>
                    <DuelMoveButton
                        name="Make a Move"
                        background="#58c15d"
                        border="#366f39"
                        onClick={() => { this.setState({actionMenu: ACTION_MENU_MOVE}) }}
                    />
                    <DuelMoveButton
                        name="Switch Tulip"
                        background="#e9322d"
                        border="#98211e"
                        onClick={() => { this.setState({actionMenu: ACTION_MENU_SWITCH}) }}
                    />
                </div>
            </div>
        );
    }

    _renderActionMenuMoves() {
        let buttons = this._renderMoveButtons();

        return (
            <div style={Styles.controlsContainer}>
                <div style={Styles.actionMessage}>
                    What will you do?
                </div>
                <div style={Styles.buttonRow}>
                    {buttons}
                </div>
            </div>
        );
    }

    _renderActionMenuSwitcher() {
        let me = this.props.myIndex;
        let myActive = this.props.gameState.activeTulips[me];

        let tulips = [];
        for (let i = 0; i < this.props.gameProps.tulips[me].length; i++) {
            if (i == myActive) {
                continue;
            }
            let type = this.props.gameProps.tulips[me][i].type;
            let actionView = this.props.gameState.tulips[me][i].hp > 0
                ? (
                    <button style={Styles.tulipSwitchEntryButton} onClick={() => this.switchTulip(i)}>Switch</button>
                )
                : (
                    <span style={{color: 'red', textAlign: 'center', width: 64}}>Fainted</span>
                );
            tulips.push(
                <div style={Styles.tulipSwitchEntry}>
                    <span style={Styles.tulipSwitchEntryLabel}>{this.props.gameProps.tulips[me][i].appearance[4]} (BP {this.props.gameProps.tulips[me][i].battleStats[0]})</span>
                    <span style={mergeStyles(Styles.typeIndicator, {backgroundColor: typeToColors[type][0], border: '1px solid #'+typeToColors[type][1]})}>{type}</span>
                    <div style={{flex: 1}} />
                    {actionView}
                </div>
            );
        }

        return (
            <div style={Styles.controlsContainer}>
                <div style={Styles.actionMessage}>
                    Which tulip do you want to switch in?
                </div>
                <div style={Styles.tulipSwitchList}>
                    {tulips}
                    <button style={Styles.tulipSwitchBackButton} onClick={() => { this.setState({actionMenu: ACTION_MENU_MAIN}) }}><span className="fa fa-arrow-circle-left"></span></button>
                </div>
            </div>
        );
    }

    _renderMoveButtons() {
        let buttons = [];

        let me = this.props.myIndex;
        let myActive = this.props.gameState.activeTulips[me];
        let myMoves = this.props.gameProps.tulips[me][myActive].moves;

        for (let i = 0; i < myMoves.length; i++) {
            let props = myMoves[i];
            let button =
                <DuelMoveButton
                    name={props.name}
                    icon={props.icon}
                    background={props.background}
                    border={props.border}
                    flexAmount={0}
                    key={i}
                    onClick={() => { this.moveChosen(i) }}
                />;
            buttons.push(button);
        }

        buttons.push(
            <DuelMoveButton
                icon="arrow-circle-left"
                background="#000"
                border="#333"
                flexAmount={0}
                minWidthAmount={24}
                key={myMoves.length}
                onClick={() => { this.setState({actionMenu: ACTION_MENU_MAIN}) }}
            />
        );

        return buttons;
    }

    _renderPlayAgainButton() {
        return (
            <DuelMoveButton
                name="Play Again?"
                icon="refresh"
                background="#58c15d"
                border="#366f39"
                onClick={() => { window.location.reload() }}
            />
        );
    }

    _renderLog() {
        let log =
            <DuelLog
                messages={this.props.gameState.log}
                myIndex={this.props.myIndex}
                onSendMessage={message => {this.props.handler.handleSendChat(message)}}
            />;
        return log;
    }

    _renderWarning() {
        return (
            <div style={Styles.warningMessage}>
                <span className="fa fa-exclamation-triangle"></span> &nbsp; This is an early preview of battles.
                Features and gameplay are subject to change
                . <a href="https://medium.com/@ethertulips/battle-sneak-peek-now-live-a6451f027d84" style={Styles.link} target="_blank">Read more here</a>.
            </div>
        );
    }

    moveChosen(index) {
        this.props.handler.handleMove(index);
        this.setState({
            actionNeeded: false,
            actionMenu: ACTION_MENU_MAIN,
        });
    }

    switchTulip(index) {
        this.props.handler.handleSwitch(index);
        this.setState({
            actionNeeded: false,
            actionMenu: ACTION_MENU_MAIN,
        });
    }

    handleUpdate() {
        let mostRecentMessage = this.props.gameState.log[this.props.gameState.log.length - 1]
        let requiresSound = false;
        if (this.props.gameState.gameStatus != 2 && mostRecentMessage.type == 'TURN') {
            for (let message of mostRecentMessage.messages) {
                if (message.includes('used')) {
                    requiresSound = true;
                    break;
                }
            }
        }

        if (requiresSound) {
            this.soundboard.playMove();
        }

        if (!this.state.actionNeeded && mostRecentMessage.type == 'TURN') {
            this.setState({
                actionNeeded: true,
            })
        }
    }
}
