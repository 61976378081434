import React, { Component } from 'react';

import etherTulipsPagify from 'darwin/components/etherTulipsPagify';

import Success from 'darwin/components/Success';


const Styles = {
    container: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        flexDirection: 'column',
        marginTop: 32,
    },
    text: {
        flex: 1,
        maxWidth: 1024,
    },
};

class TermsOfUseScene extends Component {
    static KEY = 'legal/terms';

    componentWillMount() {
        document.title = 'EtherTulips: Terms of Use';
    }
    render() {
        return (
            <div style={Styles.container}>
                <div style={Styles.text}>
                    <h1>Ether Tulips Terms of Service</h1>
                            Last Updated: 8/30/2021
                            <br />
                            Welcome to the Ether Tulips (“Ether Tulips”) website located at https://ethertulips.com/ (the “Site”). Ether Tulips is an application that runs on the Ethereum network, using specially-developed “Smart Contracts” and the Site (collectively, the “App”), that enables users to hold, trade, and battle digital tulips for fun. 
                            <br />
                            Please read these Terms of Service (the “Terms”) and our Privacy Policy (https://ethertulips.com/legal/privacy) (“Privacy Policy”) carefully because they govern your use of our Site and our App. These Terms and the documents that are referred to herein constitute a binding obligation between you and the owner of Ether Tulips. To make these Terms easier to read, the Site, our services and App are collectively called the “Services.” A “Virtual Currency” is any block-chain based currency such as Ethereum.
                            <br />
                            1. Agreement to Terms. By using our Services, you agree to be bound by these Terms. If you don’t agree to be bound by these Terms, do not use the Services. If you are accessing and using the Services on behalf of a company (such as your employer) or other legal entity, you represent and warrant that you have the authority to bind that company or other legal entity to these Terms. In that case, “you” and “your” will refer to that company or other legal entity.
                            <br />
                            2. Privacy Policy. Please refer to our Privacy Policy for information on how we collect, use and disclose information from our users. You acknowledge and agree that your use of the Services is subject to our Privacy Policy.
                            <br />
                            IMPORTANT NOTICE REGARDING ARBITRATION: WHEN YOU AGREE TO THESE TERMS YOU ARE AGREEING (WITH LIMITED EXCEPTION) TO RESOLVE ANY DISPUTE BETWEEN YOU AND ETHER TULIPS THROUGH BINDING, INDIVIDUAL ARBITRATION RATHER THAN IN COURT. PLEASE REVIEW CAREFULLY SECTION 19 “DISPUTE RESOLUTION FOR CONSUMERS” BELOW FOR DETAILS REGARDING ARBITRATION (INCLUDING THE PROCEDURE TO OPT OUT OF ARBITRATION).
                            <br />
                            3. Changes to Terms or Services. We may update the Terms at any time, in our sole discretion. If we do so, we’ll let you know either by posting the updated Terms on the Site or through other communications. It’s important that you review the Terms whenever we update them or you use the Services. If you continue to use the Services after we have posted updated Terms, you are agreeing to be bound by the updated Terms. If you don’t agree to be bound by the updated Terms, then, except as otherwise provided in Section 19(f) “Effect of Changes on Arbitration,” you may not use the Services anymore. Because our Services are evolving over time we may change or discontinue all or any part of the Services, at any time and without notice, at our sole discretion.
                            <br />
                            4. Who May Use the Services?
                            <br />
                            (a) Eligibility. You may use the Services only if you are 13years or older, capable of forming a binding contract with Ether Tulips and are not barred from using the Services under applicable law.
                            <br />
                            (b) Ethereum Wallet Required.  In order to use our Services, you will need a Virtual Currency known as Ethereum.  For more information about Ethereum, please visit https://www.ethereum.org/. Ethereum can be purchased, stored, and managed through various third-part electronic wallet applications (“Wallet”), such as My Ether Wallet or Metamask. You will need to use a third-part Wallet to in order to access our Services. Ether Tulip does not provide its own Wallet. When using our Services, you are uniquely identified by your Wallet—your purchases, trades, battles, and other interactions with our Services are all done through your Wallet. You hereby accept and acknowledge that your Wallet is operated by a third-party, not Ether Tulips, and that Ether Tulips is not responsible for the content, products or services provided to you by that third-party. 
                            <br />
                            5. Wallet Registration and Management.
                            <br />
                            (a) When you create a Wallet, you are strongly advised to take the following precautions, as failure to do so may result in loss of access to, and/or control over, your Wallet: (i) Create a strong password that you do not use for any other website or online service; (ii) Provide accurate and truthful information; (iii) Maintain and promptly update your Wallet information; (iv) maintain the security of your Wallet by protecting your Wallet password and access to your computer and your Wallet; (v) Promptly notify your Wallet provider if you discover or otherwise suspect any security breaches related to your Wallet (vi) use any backup functionalities offered by your Wallet provider and safeguard your backup files as you would safeguard your most important personal information.
                            <br />
                            (b) You hereby accept and acknowledge that you take responsibility for all activities that occur under your Wallet and accept all risks of any authorized or unauthorized access to your Wallet, to the maximum extent permitted by law. You are solely responsible for maintaining the security of your Wallet. Failure to do so may result in the loss of control of Virtual Currency associated with the Wallet. Ether Tulips does not receive or store your Wallet password, nor the unencrypted keys and addresses. Therefore, we cannot assist you with Wallet password retrieval. You are solely responsible for remembering your Wallet password.
                            <br />
                            (c) We may, in our sole discretion and without cost to you, with or without prior notice and at any time, modify or discontinue, temporarily or permanently, any portion of our Services. You are solely responsible for storing, outside of the Services, a backup of any Wallet Address and Private Key pair that you maintain in your Wallet.
                            <br />
                            (d) If you do not maintain a backup of your Wallet data outside of the Services, you will be may not be able to access Virtual Currency associated with any Wallet Address maintained in your Wallet in the event that we discontinue or deprecate the Services.
                            <br />
                            6. The Ether Tulips Services.
                            <br />
                            (a) Sole Permissible Purpose.  We provide the Services to you for a single purpose:  to provide a fun way to collect, battle, and trade digital tulips. Ether Tulips grants you a limited license to use the Services only for this purpose. You may not use the Services for any other purpose. 
                            <br />
                            (b) Digital Items Provided Through the Service.  Our Services allow you to purchase digital tulips and digital seeds that mature into digital tulips using Virtual Currency. You agree to pay the applicable fees for purchasing such digital seeds. The digital tulips come in hundreds of different varieties, some of which are rarer than others. THERE IS NO GUARANTEE THAT THE DIGITAL TULIPS WILL HAVE ANY RESALE VALUE OF ANY NATURE. THE SEEDS AND THE DIGITAL TULIPS ARE PROVIDED FOR ENTERTAINMENT AND NOT FOR INVESTMENT OR RESALE. There are eight different rarity levels. For more information on the probability of obtaining each rarity level, please visit: https://medium.com/@ethertulips/origins-generation-0-tulips-249a99b02203. 
                            <br />
                            We reserve the right to update or otherwise modify these rarity levels as our Services continue to evolve.
                            <br />
                            (c) Digital Items Are Not Convertible. You hereby accept and acknowledge that any digital items provided to you through Services, including but not limited to digital tulips and seeds, are collectable items only and cannot be sold for, or otherwise converted into, cash value.  You hereby accept and acknowledge that any digital items provided to you through the Services cannot be purchased for speculative purposes, and, unless otherwise required by law or permitted by this Agreement, are not redeemable or refundable for any sum of money or monetary value from Ether Tulips at any time.
                            <br />
                            (d) Description of Services. As described in more detail below, the Services, among other things, provide in-browser (or otherwise local) software that (i) generates and stores Virtual Currency Wallet Addresses and encrypted Private Keys (defined below), and (ii) Facilitates the submission of Virtual Currency transaction data to the relevant Virtual Currency blockchain network without requiring you to download or install the associated Virtual Currency network software to your local device.
                            <br />
                            (e) Transactions. 
                            <br />
                            (i) In order to be completed, all proposed Virtual Currency transactions must be confirmed and recorded in the Virtual Currency public ledger associated with the relevant Virtual Currency network. Such networks are decentralized, peer-to-peer networks supported by independent third-parties, which are not owned, controlled or operated by Ether Tulips. Virtual Currency networks are operated by decentralized networks of independent third parties. Ether Tulips has no control over any Virtual Currency network and therefore cannot and does not ensure that any transaction details you submit via the Services will be confirmed via the relevant Virtual Currency network. You acknowledge and agree that the transaction details you submit via the Services may not be completed, or may be substantially delayed, by the Virtual Currency network used to process the transaction.
                            <br />
                            (ii) Through the Virtual Currency network, you are able to transfer certain digital items provided through the Services, such as tulips and seeds, to other users.  You hereby accept and acknowledge that Ether Tulips is not a party to those transactions, and that you are solely responsible for initiating and completing those transactions. 
                            <br />
                            (f) No Storage or Transmission of Virtual Currency. A Virtual Currency is an intangible, digital asset. They exist only by virtue of the ownership record maintained in the underlying Virtual Currency network. The Services do not store, send or receive Virtual Currency. Any transfer of title that might occur in any Virtual Currency occurs on the decentralized ledger within the Virtual Currency network and not within the Services. We do not guarantee that the Service can affect the transfer of title or right in any Virtual Currency.
                            <br />
                            (g) Relationship. Nothing in these Terms is intended to nor shall create any partnership, joint venture, agency, consultancy or trusteeship, you and Ether Tulips being with respect to one another independent contractors.
                            <br />
                            (h) Accuracy of Information. You represent and warrant that any information you provide via the Services is accurate and complete. You accept and acknowledge that Ether Tulips is not responsible for any errors or omissions that you make in connection with any Virtual Currency transaction initiated via the Services, for instance, if you mistype a Wallet Address or otherwise provide incorrect information. We strongly encourage you to review your transaction details carefully before completing them via the Services.
                            <br />
                            (i) No Cancellations or Modifications. Once transaction details have been submitted to the Virtual Currency network via the Services, the Services cannot assist you to cancel or otherwise modify your transaction details. Ether Tulips has no control over any Virtual Currency network and does not have the ability to facilitate any cancellation or modification requests.
                            <br />
                            (j)  Taxes. It is your responsibility to determine what, if any, taxes apply to the transactions you for which you have submitted transaction details via the Services, and it is your responsibility to report and remit the correct tax to the appropriate tax authority. You agree that Ether Tulips is not responsible for determining whether taxes apply to your Virtual Currency transactions or for collecting, reporting, withholding or remitting any taxes arising from any Virtual Currency transactions.
                            <br />
                            7. Fees For Using The Blockchain Services
                            <br />
                            (a) Fees for Using Ether Tulips. Except as provided above in Section 6, Ether Tulips does not currently charge fees to use the Services. However, we reserve the right to do so in future, and in such case any applicable fees will be displayed prior to you using any Service to which a fee applies.
                            <br />
                            (b) Miners Fee. You are responsible for paying any mining or transfer fees imposed by your wallet provider or the applicable Virtual Currency network.
                            <br />
                            8. Feedback. We welcome feedback, comments and suggestions for improvements to the Services (“Feedback”). You can submit Feedback by emailing us at contact@ethertulips.com. You grant to us a non-exclusive, transferable, worldwide, perpetual, irrevocable, fully-paid, royalty-free license, with the right to sublicense, under any and all intellectual property rights that you own or control to use, copy, modify, create derivative works based upon and otherwise exploit the Feedback for any purpose.
                            <br />
                            9. Content Ownership, Responsibility and Removal. 
                            <br />
                            (a) Definitions. For purposes of these Terms: (i) “Content” means text, graphics, images, music, software, audio, video, computer code, works of authorship of any kind, and information or other materials that are posted, generated, provided or otherwise made available through the Services; and (ii) “User Content” means any Content that you  provide to be made available through the Services. Content includes without limitation User Content.
                            <br />
                            (b) Our Content Ownership. Ether Tulips does not claim any ownership rights in any User Content and nothing in these Terms will be deemed to restrict any rights that you may have to use and exploit your User Content. Subject to the foregoing, Ether Tulips and its licensors exclusively own all right, title and interest in and to the Services and Content, including all associated intellectual property rights. You acknowledge that the Services and Content are protected by copyright, trademark, and other laws of the United States and foreign countries. You agree not to remove, alter or obscure any copyright, trademark, service mark or other proprietary rights notices incorporated in or accompanying the Services or Content.
                            <br />
                            (c) Rights in User Content Granted by You. By making any User Content available through the Services you hereby grant to Ether Tulips a non-exclusive, transferable, worldwide, royalty-free license, with the right to sublicense, to use, copy, modify, distribute, publicly display, publicly perform, distribute, and if applicable, create derivative works based upon your User Content in connection with operating and providing the Services and Content to you and to other Ether Tulip users.
                            <br />
                            (d) Your Responsibility for User Content. You are solely responsible for all your User Content. You represent and warrant that you own all your User Content or you have all rights that are necessary to grant us the license rights in your User Content under these Terms. You also represent and warrant that neither your User Content, nor your use and provision of your User Content to be made available through the Services, nor any use of your User Content by Ether Tulips on or through the Services will infringe, misappropriate or violate a third party’s intellectual property rights, or rights of publicity or privacy, or result in the violation of any applicable law or regulation.
                            <br />
                            (e) Removal of User Content. You can remove your User Content by specifically deleting it. However, in certain instances, some of your User Content (such as posts or comments you make) may not be completely removed and copies of your User Content may continue to exist on the Services. We are not responsible or liable for the removal or deletion of (or the failure to remove or delete) any of your User Content.
                            <br />
                            (f) Rights in Content Granted by Ether Tulips. Subject to your compliance with these Terms, Ether Tulips grants to you a limited, non-exclusive, non-transferable license, with no right to sublicense, to download, view, copy, display and print the Content solely in connection with your permitted use of the Services and solely for your personal and non-commercial purposes.
                            <br />
                            10. Rights and Terms for Apps.
                            <br />
                            (a) Rights in App Granted by Ether Tulips. Subject to your compliance with these Terms, Ether Tulips grants to you a limited non-exclusive, non-transferable license, with no right to sublicense, to download and install a copy of the App on a mobile device or computer that you own or control and to run such copy of the App solely for your own personal non-commercial purposes. You may not copy the App, except for making a reasonable number of copies for backup or archival purposes. Except as expressly permitted in these Terms, you may not: (i) copy, modify or create derivative works based on the App; (ii) distribute, transfer, sublicense, lease, lend or rent the App to any third party; (iii) reverse engineer, decompile or disassemble the App; or (iv) make the functionality of the App available to multiple users through any means. Ether Tulips reserves all rights in and to the App not expressly granted to you under these Terms.
                            <br />
                            (b) Accessing App from App Store. The following terms apply to any App accessed through or downloaded from any app store or distribution platform (like the Apple App Store or Google Play) where the App may now or in the future be made available (each an “App Provider”). You acknowledge and agree that: 
                            <br />
                            These Terms are concluded between you and Ether Tulips, and not with the App Provider, and Ether Tulips (not the App Provider), is solely responsible for the App.
                            <br />
                            The App Provider has no obligation to furnish any maintenance and support services with respect to the App.
                            <br />
                            In the event of any failure of the App to conform to any applicable warranty, you may notify the App Provider, and the App Provider will refund the purchase price for the App to you (if applicable) and, to the maximum extent permitted by applicable law, the App Provider will have no other warranty obligation whatsoever with respect to the App. Any other claims, losses, liabilities, damages, costs or expenses attributable to any failure to conform to any warranty will be the sole responsibility of Ether Tulips.
                            <br />
                            The App Provider is not responsible for addressing any claims you have or any claims of any third party relating to the App or your possession and use of the App, including, but not limited to: (i) product liability claims; (ii) any claim that the App fails to conform to any applicable legal or regulatory requirement; and (iii) claims arising under consumer protection or similar legislation.
                            <br />
                            In the event of any third party claim that the App or your possession and use of that App infringes that third party’s intellectual property rights, Ether Tulips will be solely responsible for the investigation, defense, settlement and discharge of any such intellectual property infringement claim to the extent required by these Terms.
                            <br />
                            The App Provider, and its subsidiaries, are third-party beneficiaries of these Terms as related to your license to the App, and that, upon your acceptance of the Terms, the App Provider will have the right (and will be deemed to have accepted the right) to enforce these Terms as related to your license of the App against you as a third-party beneficiary thereof.
                            <br />
                            You represent and warrant that (i) you are not located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a terrorist-supporting country; and (ii) you are not listed on any U.S. Government list of prohibited or restricted parties.
                            <br />
                            You must also comply with all applicable third party terms of service when using the App.
                            <br />
                            11. General Prohibitions and Ether Tulips’s Enforcement Rights. You agree not to do any of the following:
                            <br />
                            (a) Post, upload, publish, submit or transmit any Content that: (i) infringes, misappropriates or violates a third party’s patent, copyright, trademark, trade secret, moral rights or other intellectual property rights, or rights of publicity or privacy; (ii) violates, or encourages any conduct that would violate, any applicable law or regulation or would give rise to civil liability; (iii) is fraudulent, false, misleading or deceptive; (iv) is defamatory, obscene, pornographic, vulgar or offensive; (v) promotes discrimination, bigotry, racism, hatred, harassment or harm against any individual or group; (vi) is violent or threatening or promotes violence or actions that are threatening to any person or entity; or (vii) promotes illegal or harmful activities or substances;
                            <br />
                            (b) Use, display, mirror or frame the Services or any individual element within the Services, Ether Tulips’s name, any Ether Tulips trademark, logo or other proprietary information, or the layout and design of any page or form contained on a page, without Ether Tulips’s express written consent; 
                            <br />
                            (c) Access, tamper with, or use non-public areas of the Services, Ether Tulips’s computer systems, or the technical delivery systems of Ether Tulips’s providers; 
                            <br />
                            (d) Attempt to probe, scan or test the vulnerability of any Ether Tulips system or network or breach any security or authentication measures; 
                            <br />
                            (e) Avoid, bypass, remove, deactivate, impair, descramble or otherwise circumvent any technological measure implemented by Ether Tulips or any of Ether Tulips’s providers or any other third party (including another user) to protect the Services or Content; 
                            <br />
                            (f) Attempt to access or search the Services or Content or download Content from the Services through the use of any engine, software, tool, agent, device or mechanism (including spiders, robots, crawlers, data mining tools or the like) other than the software and/or search agents provided by Ether Tulips or other generally available third-party web browsers; 
                            <br />
                            (g) Send any unsolicited or unauthorized advertising, promotional materials, email, junk mail, spam, chain letters or other form of solicitation; 
                            <br />
                            (h) Use any meta tags or other hidden text or metadata utilizing a Ether Tulips trademark, logo URL or product name without Ether Tulips’s express written consent; 
                            <br />
                            (i) Use the Services or Content, or any portion thereof, for any commercial purpose or for the benefit of any third party or in any manner not permitted by these Terms; 
                            <br />
                            (j) Forge any TCP/IP packet header or any part of the header information in any email or newsgroup posting, or in any way use the Services or Content to send altered, deceptive or false source-identifying information; 
                            <br />
                            (k) Attempt to decipher, decompile, disassemble or reverse engineer any of the software used to provide the Services or Content; 
                            <br />
                            (l) Interfere with, or attempt to interfere with, the access of any user, host or network, including, without limitation, sending a virus, overloading, flooding, spamming, or mail-bombing the Services; 
                            <br />
                            (m) Collect or store any personally identifiable information from the Services from other users of the Services without their express permission; 
                            <br />
                            (n) Impersonate or misrepresent your affiliation with any person or entity; 
                            <br />
                            (o) Violate any applicable law or regulation; or
                            <br />
                            (p) Encourage or enable any other individual to do any of the foregoing.
                            <br />
                            Although we’re not obligated to monitor access to or use of the Services or Content or to review or edit any Content, we have the right to do so for the purpose of operating the Services, to ensure compliance with these Terms and to comply with applicable law or other legal requirements. We reserve the right, but are not obligated, to remove or disable access to any Content, at any time and without notice, including, but not limited to, if we, at our sole discretion, consider any Content to be objectionable or in violation of these Terms. We have the right to investigate violations of these Terms or conduct that affects the Services. We may also consult and cooperate with law enforcement authorities to prosecute users who violate the law.
                            <br />
                            12. DMCA/Copyright Policy. Ether Tulips respects copyright law and expects its users to do the same. It is It is Ether Tulips’s policy to terminate, in appropriate circumstances, subscribers or account  holders who are repeat copyright infringers. We may require proof that a competent authority has rendered judgments of copyright infringement because we cannot know all the facts and circumstances to be certain about accusations of infringement. We reserve the right to determine what circumstances are appropriate; it is difficult to identify them in the abstract. Please see Ether Tulips’ Copyright and IP Policy at 1309 Coffeen Avenue STE 1200, Sheridan, WY 82801 for further information.
                            <br />
                            13. Links to Third Party Websites or Resources. The Services (including the App) may contain links to third-party websites or resources. We provide these links only as a convenience and are not responsible for the content, products or services on or available from those websites or resources or links displayed on such websites. In addition, use of our Services may require you to use third-party websites or resources, such as the Ethereum network.  You accept an acknowledge that we do not own or control these third-party websites or resources, that we are not liable for the acts or omissions of any such third parties, and that we are not liable for any damage you may suffer as a result of your interactions with such third parties. 
                            <br />
                            14. Termination. We may terminate your access to and use of the Services, at our sole discretion, at any time and without notice to you. Upon any termination, discontinuation or cancellation of the Services, the following Sections will survive: 5(d), 5(e), 6(a), 6(b), 6 (c), 6(f)(ii), 9(a), 9(b), 9(c), 15, 16, 17, 18, and 19.
                            <br />
                            15. Warranty Disclaimers. THE SERVICES AND CONTENT ARE PROVIDED “AS IS,” WITHOUT WARRANTY OF ANY KIND. WITHOUT LIMITING THE FOREGOING, WE EXPLICITLY DISCLAIM ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUIET ENJOYMENT AND NON-INFRINGEMENT, AND ANY WARRANTIES ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE. We make no warranty that the Services will meet your requirements or be available on an uninterrupted, secure, or error-free basis. We make no warranty regarding the quality, accuracy, timeliness, truthfulness, completeness or reliability of any Content.
                            <br />
                            16. Indemnity. You will indemnify and hold harmless Ether Tulips and its officers, directors, employees and agents, from and against any claims, disputes, demands, liabilities, damages, losses, and costs and expenses, including, without limitation, reasonable legal and accounting fees arising out of or in any way connected with (i) your access to or use of the Services or Content, (ii) your User Content, or (iii) your violation of these Terms.
                            <br />
                            17. Limitation of Liability.
                            <br />
                            (a) Assumption of Risk.  
                            <br />
                            (i) You hereby accept and acknowledge that the prices associated with Virtual Currency are extremely volatile and subject to change, which could materially and adversely affect the your ability to use our Services, and that you are solely liable for the risk associated with the use of Virtual Currency. 
                            <br />
                            (ii) You hereby accept and acknowledge that the regulatory regime governing Virtual Currency is evolving, that new regulations or policies may materially and adversely affect your ability to use our Services and our ability to provide those Services to you, and that we are not liable to you for any harm that may result from changes to the governing regulatory regime(s).
                            <br />
                            (iii) You hereby accept and acknowledge that the networks that support Virtual Currency, including but not limited to the Ethereum network, are subject to technological and structural changes that may materially and adversely affect your ability to use our Services and our ability to provide those Services to you.  You hereby acknowledge and agree not to hold us liable for any harm that may result to you if it no longer becomes feasible for us to provide our Services to you. 
                            <br />
                            (b) NEITHER ETHER TULIPS NOR ANY OTHER PARTY INVOLVED IN CREATING, PRODUCING, OR DELIVERING THE SERVICES OR CONTENT WILL BE LIABLE FOR ANY INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES, OR DAMAGES FOR LOST PROFITS, LOST REVENUES, LOST SAVINGS, LOST BUSINESS OPPORTUNITY, LOSS OF DATA OR GOODWILL, SERVICE INTERRUPTION, COMPUTER DAMAGE OR SYSTEM FAILURE OR THE COST OF SUBSTITUTE SERVICES OF ANY KIND ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR FROM THE USE OF OR INABILITY TO USE THE SERVICES OR CONTENT, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT ETHER TULIPS OR ANY OTHER PARTY HAS BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, EVEN IF A LIMITED REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU.
                            <br />
                            (c) IN NO EVENT WILL ETHER TULIPS’S TOTAL LIABILITY ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR FROM THE USE OF OR INABILITY TO USE THE SERVICES OR CONTENT EXCEED THE AMOUNTS YOU HAVE PAID TO ETHER TULIPS FOR USE OF THE SERVICES OR CONTENT OR ONE HUNDRED DOLLARS ($100), IF YOU HAVE NOT HAD ANY PAYMENT OBLIGATIONS TO ETHER TULIPS, AS APPLICABLE.
                            <br />
                            (d) THE EXCLUSIONS AND LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN ETHER TULIPS AND YOU.
                            <br />
                            18. Governing Law and Forum Choice. These Terms and any action related thereto will be governed by the Federal Arbitration Act, federal arbitration law, and the laws of the State of California, without regard to its conflict of laws provisions. Except as otherwise expressly set forth in Section 19 “Dispute Resolution for Consumers,” the exclusive jurisdiction for all Disputes (defined below) that you and Ether Tulips are not required to arbitrate will be the state and federal courts located in the Northern District of California, and you and Ether Tulips each waive any objection to jurisdiction and venue in such courts.
                            <br />
                            19. Dispute Resolution. 
                            <br />
                            (a) Mandatory Arbitration of Disputes. We each agree that any dispute, claim or controversy arising out of or relating to these Terms or the breach, termination, enforcement, interpretation or validity thereof or the use of the Services or Content (collectively, “Disputes”) will be resolved solely by binding, individual arbitration and not in a class, representative or consolidated action or proceeding. You and Ether Tulips agree that the U.S. Federal Arbitration Act governs the interpretation and enforcement of these Terms, and that You and Ether Tulips are each waiving the right to a trial by jury or to participate in a class action.  This arbitration provision shall survive termination of these Terms.
                            <br />
                            (b) Exceptions and Opt-out. As limited exceptions to Section 19(a) above: (i) you may seek to resolve a Dispute in small claims court If it qualifies; and (ii) we each retain the right to seek injunctive or other equitable relief from a court to prevent (or enjoin) the infringement or misappropriation of our intellectual property rights. In addition, you will retain the right to opt out of arbitration entirely and litigate any Dispute if you provide us with written notice of your desire to do so by email at contact@ethertulips.com  or by regular mail at 1309 Coffeen Avenue STE 1200, Sheridan, WY 82801 within thirty (30) days following the date you first agree to these Terms.
                            <br />
                            (c) Conducting Arbitration and Arbitration Rules. The arbitration will be conducted by the American Arbitration Association (“AAA”) under its Consumer Arbitration Rules (the “AAA Rules”) then in effect, except as modified by these Terms. The AAA Rules are available at www.adr.org or by calling 1-800-778-7879. A party who wishes to start arbitration must submit a written Demand for Arbitration to AAA and give notice to the other party as specified in the AAA Rules. The AAA provides a form Demand for Arbitration at www.adr.org. 
                            <br />
                            If Your claim is for U.S. $10,000 or less, you may choose whether the arbitration will be conducted solely on the basis of documents submitted to the arbitrator, through a telephonic or video-conference hearing, or by an in-person hearing as established by the AAA Rules.  If Your claim exceeds U.S. $10,000, the right to a hearing will be determined by the AAA Rules. Any arbitration hearings will take place in the county (or parish) where you live, unless we both agree to a different location. The parties agree that the arbitrator shall have exclusive authority to decide all issues relating to the interpretation, applicability, enforceability and scope of this arbitration agreement.
                            <br />
                            (d) Arbitration Costs. Payment of all filing, administration and arbitrator fees will be governed by the AAA Rules. We’ll pay for all filing, administration and arbitrator fees and expenses if your Dispute is for less than $10,000, unless the arbitrator finds your Dispute frivolous. If we prevail in arbitration we’ll pay all of our attorneys’ fees and costs and won’t seek to recover them from you. If you prevail in arbitration you will be entitled to an award of attorneys’ fees and expenses to the extent provided under applicable law.
                            <br />
                            (e) Class Action Waiver.  YOU AND ETHER TULIPS AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING.  Further, if the parties’ dispute is resolved through arbitration, the arbitrator may not consolidate another person's claims with your claims, and may not otherwise preside over any form of a representative or class proceeding.  If this specific provision is found to be unenforceable, then the entirety of this Dispute Resolution section shall be null and void.
                            <br />
                            (f) Effect of Changes on Arbitration. Notwithstanding the provisions of Section 3 “Changes to Terms or Services” above, if Ether Tulips changes any of the terms of this Section 19 “Dispute Resolution” after the date you first accepted these Terms (or accepted any subsequent changes to these Terms), you may reject any such change by sending us written notice (including by email to contact@ethertulips.com) within 30 days of the date such change became effective, as indicated in the “Last Updated” date above or in the date of Ether Tulips s email to you notifying you of such change. By rejecting any change, you are agreeing that you will arbitrate any Dispute between you and Ether Tulips in accordance with the terms of this Section 19 “Dispute Resolution” as of the date you first accepted these Terms (or accepted any subsequent changes to these Terms).
                            <br />
                            (g) Severability.  With the exception of any of the provisions in Section 16(e) of these Terms ("Class Action Waiver"), if an arbitrator or court of competent jurisdiction decides that any part of this Agreement is invalid or unenforceable, the other parts of these Terms will still apply.
                            <br />
                            20. General Terms.
                            <br />
                            (a) Entire Agreement. These Terms constitute the entire and exclusive understanding and agreement between Ether Tulips and you regarding the Services and Content, and these Terms supersede and replace any and all prior oral or written understandings or agreements between Ether Tulips and you regarding the Services and Content. If any provision of these Terms is held invalid or unenforceable by an arbitrator or a court of competent jurisdiction, that provision will be enforced to the maximum extent permissible and the other provisions of these Terms will remain in full force and effect. You may not assign or transfer these Terms, by operation of law or otherwise, without Ether Tulips’s prior written consent. Any attempt by you to assign or transfer these Terms, without such consent, will be null. Ether Tulips may freely assign or transfer these Terms without restriction. Subject to the foregoing, these Terms will bind and inure to the benefit of the parties, their successors and permitted assigns.
                            <br />
                            (b) Notices. Any notices or other communications provided by Ether Tulips under these Terms, including those regarding modifications to these Terms, will be given: (i) via email; or (ii) by posting to the Services. For notices made by e-mail, the date of receipt will be deemed the date on which such notice is transmitted.
                            <br />
                            (c) Waiver of Rights. Ether Tulips’ failure to enforce any right or provision of these Terms will not be considered a waiver of such right or provision. The waiver of any such right or provision will be effective only if in writing and signed by a duly authorized representative of Ether Tulips. Except as expressly set forth in these Terms, the exercise by either party of any of its remedies under these Terms will be without prejudice to its other remedies under these Terms or otherwise.
                            <br />
                            21. Contact Information. If you have any questions about these Terms or the Services, please contact Ether Tulips at contact@ethertulips.com or 1309 Coffeen Avenue STE 1200, Sheridan, WY 82801.
                            <br />
                </div>
            </div>
        );
    }
}

export default etherTulipsPagify(TermsOfUseScene);
