import React, { Component } from 'react';

import mergeStyles from 'darwin/lib/styling/mergeStyles';

var Styles = {
    logContainer: {
        flex: 1,
        margin: '0 0 0 8px',
    },
    log: {
        padding: 12, 
        border: '1px solid #9c9c9c',
        height: 360,
        overflow: 'auto',
    },
    logMove: {
        fontWeight: 'bold',
        borderBottom: '1px solid #9c9c9c',
    },
    messageComposer: {
        marginTop: 12,
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
    },
    messageComposerInput: {
        flex: 1,
        height: 34,
        border: '1px solid #9c9c9c',
        borderRadius: 4,
        paddingLeft: 8,
        paddingRight: 8,
    },
    messageComposerSend: {
        marginLeft: 8,
        cursor: 'pointer',
        color: '#ff3366',
    },
    chatMessageBubbleOutgoing: {
        borderRadius: 8,
        backgroundColor: '#ff3366',
        color: 'white',
        padding: 8,
    },
    chatMessageBubbleIncoming: {
        borderRadius: 8,
        backgroundColor: '#eee',
        color: 'black',
        padding: 8,
    },
};

export default class DuelLog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            chatMessage: '',
        };
    }

    render() {
        let me = this.props.myIndex;
        let them = 1 - me;

        let log = [];
        let turnCounter = 1;
        for (let i = 0; i < this.props.messages.length; i++) {
            let msg = this.props.messages[i];
            if (!msg.type) {
                console.log('WARNING: Invalid message received.');
                console.log(msg);
                continue;
            }
            let messageViews = null;
            switch (msg.type) {
                case 'SYSTEM':
                    messageViews = this._renderSystemMessage(msg.messages);
                    break;
                case 'TURN':
                    messageViews = this._renderTurnMessages(msg.messages, turnCounter, me, them);
                    turnCounter += 1;
                    break;
                case 'CHAT':
                    messageViews = this._renderChatMessages(msg.messages, me);
                    break;
                default:
                    console.log('WARNING: Invalid message received.');
                    console.log(msg);
                    continue;
                    break;
            }
            log.push(
                <div key={i}>{messageViews}</div>
            );
        }

        return (
            <div style={Styles.logContainer}>
                <div id="log" style={Styles.log}>
                    {log}
                </div>
                <div style={Styles.messageComposer}>
                    <input type="text" placeholder="Send a message..." value={this.state.chatMessage} onKeyDown={e => {e.keyCode == 13 && this.state.chatMessage && this.props.onSendMessage && (this.props.onSendMessage(this.state.chatMessage) || this.setState({chatMessage: ''}))}} onChange={event => this.setState({chatMessage: event.target.value})} style={Styles.messageComposerInput} />
                    { this.state.chatMessage && <a style={Styles.messageComposerSend} onClick={() => { this.state.chatMessage && this.props.onSendMessage && this.props.onSendMessage(this.state.chatMessage); this.setState({chatMessage: ''}); }}>Send</a> }
                </div>
            </div>
        );
    }

    _renderSystemMessage(messages) {
        let messageViews = [];
        let keyCounter = 0;
        for (let msg of messages) {
            messageViews.push(
                <p style={Styles.logAction} key={keyCounter}>
                    <strong>{msg}</strong>
                </p>
            );
            keyCounter += 1;
        }
        return messageViews;
    }

    _renderTurnMessages(messages, turnNum, me, them) {
        let messageViews = [];
        messageViews.push(
            <p style={Styles.logMove} key={0}>
                Move #{turnNum}
            </p>
        );
        let keyCounter = 1;
        for (let msg of messages) {
            let message = msg
                .replace(`[${me}]`, "Your")
                .replace(`[${them}]`, "Their")
                .replace(`[p${me}]`, "You")
                .replace(`[p${them}]`, "They");
            messageViews.push(
                <p style={Styles.logAction} key={keyCounter}>
                    {message}
                </p>
            );
            keyCounter += 1;
        }
        return messageViews;
    }

    _renderChatMessages(messages, me) {
        let messageViews = [];
        let keyCounter = 0;
        for (let msg of messages) {
            console.log(msg.sender);
            let messageSender = (msg.sender == me ? 'You:' : 'Opponent:');
            messageViews.push(
                <p style={msg.sender == me ? Styles.chatMessageBubbleOutgoing : Styles.chatMessageBubbleIncoming} key={keyCounter}>
                    <strong>{messageSender}</strong> {msg.message}
                </p>
            );
            keyCounter += 1;
        }
        return messageViews;
    }

    scroll() {
        let log = document.getElementById("log");
        log.scrollTop = log.scrollHeight;
    }

    componentDidMount() {
        this.scroll();
    }

    componentDidUpdate() {
        this.scroll();
    }
}
