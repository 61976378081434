import ActionType from './ActionType';

import Apis from 'darwin/lib/api';
import Constants from 'darwin/Constants';

const {v2Api: Api, v1Api: LegacyApi} = Apis;

function getApi(v1) {
    return (v1 ? LegacyApi : Api);
}

function _bindPromise(promise, onSuccess, onFailure) {
    promise.then(onSuccess).catch(onFailure);
    return promise;
}

function _requestNumOwnedTulips() {
    return {
        type: ActionType.REQUEST_NUM_OWNED_TULIPS,
    };
}

function _receiveNumOwnedTulips(numTulips) {
    return {
        type: ActionType.RECEIVE_NUM_OWNED_TULIPS,
        numTulips,
    };
}

function _requestNumOwnedTulipsFailed(error) {
    return {
        type: ActionType.REQUEST_NUM_OWNED_TULIPS_FAILED,
        error,
    };
}

function _requestOwnedTulips() {
    return {
        type: ActionType.REQUEST_OWNED_TULIPS,
    };
}

function _receiveOwnedTulips(tulips) {
    return {
        type: ActionType.RECEIVE_OWNED_TULIPS,
        tulips,
    };
}

function _requestOwnedTulipsFailed(error) {
    return {
        type: ActionType.REQUEST_OWNED_TULIPS_FAILED,
        error,
    };
}

function _requestMoreOwnedTulips(offset) {
    return {
        type: ActionType.REQUEST_MORE_OWNED_TULIPS,
        offset
    };
}

function _receiveMoreOwnedTulips(offset, tulips, numRemaining) {
    return {
        type: ActionType.RECEIVE_MORE_OWNED_TULIPS,
        tulips,
        numRemaining,
        offset,
    };
}

function _requestMoreOwnedTulipsFailed(error) {
    return {
        type: ActionType.REQUEST_MORE_OWNED_TULIPS_FAILED,
        error,
    };
}

function _requestBuyTulips() {
    return {
        type: ActionType.REQUEST_BUY_TULIPS,
    };
}

function _boughtTulips() {
    return {
        type: ActionType.BOUGHT_TULIPS,
    };
}

function _requestBuyTulipsFailed(error) {
    return {
        type: ActionType.REQUEST_BUY_TULIPS_FAILED,
        error,
    };
}

function _requestShopInfoForGen(gen) {
    return {
        type: ActionType.REQUEST_SHOP_GEN_INFO,
        gen,
    }
}

function _receiveShopInfoForGen(gen, info) {
    return {
        type: ActionType.RECEIVE_SHOP_GEN_INFO,
        gen,
        info,
    }
}

function _requestShopInfoForGenFailed(gen, err) {
    return {
        type: ActionType.REQUEST_SHOP_GEN_INFO_FAILED,
        gen,
        err,
    }
}

function _transferTulipRequest(id, to) {
    return {
        type: ActionType.TRANSFER_TULIP_REQUEST,
        id,
        to,
    }
}

function _transferTulipSuccess(id, to, resp) {
    return {
        type: ActionType.TRANSFER_TULIP_SUCCESS,
        id,
        to,
        resp,
    }
}

function _transferTulipFailed(id, to, err) {
    return {
        type: ActionType.TRANSFER_TULIP_FAILED,
        id,
        to,
        err,
    }
}

function _swapApi() {
    return {
        type: ActionType.SWAP_API,
    }
}

async function _fetchAllMyTulips() {
    let done = false;
    let tulips = [];
    while(!done) {
        let nextBatch = await Api.fetchOwnedTulipsBatch(tulips.length, Constants.maxOwnedTulipsBatchSize);
        let batch = nextBatch[0];
        tulips = tulips.concat(batch);
        let numRemaining = nextBatch[1];
        if (numRemaining <= 0) {
            done = true;
        }
    }
    return tulips;
}

class Actions {
    fetchNumOwnedTulips(v1 = false) {
        return dispatch => {
            dispatch(_requestNumOwnedTulips());
            return _bindPromise(
                getApi(v1).getNumOwnedTulips(),
                resp => {
                    dispatch(_receiveNumOwnedTulips(resp));
                },
                err => {
                    dispatch(_requestNumOwnedTulipsFailed(err));
                },
            );
        };
    }

    fetchOwnedTulips() {
        return dispatch => {
            dispatch(_requestOwnedTulips());
            return _bindPromise(
                _fetchAllMyTulips(),
                resp => {
                    dispatch(_receiveOwnedTulips(resp));
                },
                err => {
                    dispatch(_requestOwnedTulipsFailed(err));
                },
            );
        };
    }

    fetchMoreOwnedTulips(offset, v1 = false) {
        return dispatch => {
            dispatch(_requestMoreOwnedTulips(offset));
            return _bindPromise(
                getApi(v1).fetchOwnedTulipsBatch(offset, Constants.maxOwnedTulipsBatchSize),
                resp => {
                    dispatch(_receiveMoreOwnedTulips(offset, resp[0], resp[1]));
                },
                err => {
                    dispatch(_requestMoreOwnedTulipsFailed(err));
                },
            );
        };
    }

    buyTulips(amount, gen, ethAmount) {
        return dispatch => {
            dispatch(_requestBuyTulips());
            return _bindPromise(
                Api.buyTulips(amount, gen, ethAmount),
                resp => {
                    dispatch(_boughtTulips(resp));
                    dispatch(this.fetchOwnedTulips());
                },
                err => {
                    dispatch(_requestBuyTulipsFailed(err));
                },
            );
        };
    }

    transferTulip(id, to) {
        return dispatch => {
            dispatch(_transferTulipRequest(id, to));
            return _bindPromise(
                Api.transferTulip(id, to),
                resp => {
                    dispatch(_transferTulipSuccess(id, to, resp));
                },
                err => {
                    dispatch(_transferTulipFailed(id, to, err));
                },
            );
        };
    }

    fetchShopInfoForGen(gen) {
        return dispatch => {
            dispatch(_requestShopInfoForGen(gen));
            return _bindPromise(
                Promise.all([Api.getPriceForGen(gen), Api.getPriceIncreaseDetailsForGen(gen)]),
                ([price, priceIncreaseDetails]) => {
                    dispatch(_receiveShopInfoForGen(gen, {price, priceIncreaseDetails}));
                },
                err => {
                    dispatch(_requestShopInfoForGenFailed(gen, err));
                }
            );
        }
    }

    swapApi() {
        return dispatch => {
            dispatch(_swapApi());
        };
    }
}

export default new Actions();
