import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import Slider from 'react-rangeslider';

import BigNumber from 'bignumber.js';
import {ethers} from 'ethers';

import Actions from 'darwin/actions';

import Api from 'darwin/lib/api';

import ShopTimeRemaining from 'darwin/components/ShopList/ShopTimeRemaining';
import ShopItemRow from 'darwin/components/ShopList/ShopItemRow';
import LegacyShopItemRow from 'darwin/components/ShopList/LegacyShopItemRow';
import SceneLoadingIndicator from 'darwin/components/SceneLoadingIndicator';

import Constants from 'darwin/Constants';
import Strings from 'darwin/Strings';
import genInfo from 'darwin/lib/tulips/genInfo';
import sceneRequiresApi from 'darwin/components/sceneRequiresApi';
import getAsset from 'darwin/lib/assets/getAsset';
import etherTulipsPagify from 'darwin/components/etherTulipsPagify';

import MediaQuery from 'react-responsive';

import mergeStyles from 'darwin/lib/styling/mergeStyles';

import 'react-rangeslider/lib/index.css';

import './ShopScene.css';

const ApiV2 = Api.v2Api;

var Styles = {
    itemEthSymbol: {
        fontSize: 20,
    },
    container: {
        marginTop: 24,
        marginBottom: 8,
    },
    noticeFlexContainer: {
        display: 'flex',
        flexDirection: 'row',
        paddingLeft: 32,
        paddingRight: 32,
        paddingTop: 32,
        justifyContent: 'center',
    },
    notice: {
        flex: 1,
        maxWidth: 1024,
        border: '2px solid #FF7F50',
        padding: 8,
        height: 24,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 22,
    },
    headerFlexContainer: {
        display: 'flex',
        flexDirection: 'row',
        paddingLeft: 32,
        paddingRight: 32,
        paddingTop: 8,
        justifyContent: 'center',
        borderBottom: '2px solid #ddd',
    },
    header: {
        maxWidth: 1024,
        flex: 1,
        paddingBottom: 16,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
    },
    headerTitle: {
        fontSize: 48
    },
    priceCountdownTimer: {
        color: 'red',
    },
    spacer: {
        flex: 1,
    },
    shopFlexContainer: {
        marginTop: 32,
        maxWidth: 1024,
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingLeft: 32,
        paddingRight: 32,
        display: 'flex',
        flexDirection: 'column',
        alignItem: 'center',
    },
    genContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    gen2021Container: {
        backgroundColor: '#222',
        borderRadius: 8,
        marginBottom: 24,
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        padding: 20,
        fontFamily: 'Inter, sans-serif',
    },
    gen2021Header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        fontSize: 32,
        fontWeight: 'bold',
        marginBottom: 20,
    },
    gen2021SeedName: {
        fontSize: 20,
        fontWeight: 600,
    },
    gen2021SeedImage: {
        width: 152,
        height: 152,
        borderRadius: 8,
        backgroundColor: '#292929',
        marginRight: 20,
    },
    genHeader: {
        paddingBottom: 8,
        marginBottom: 24,
        borderBottom: '2px solid #888',
    },
    genHeaderTitle: {
        fontSize: 24,
    },
    genComingSoonContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: 40,
    },
    genComingSoonHeader: {
        fontSize: 48,
    },
    genComingSoonSubhead: {
        fontSize: 16,
    },
    genComingSoonDescription: {
        maxWidth: 512,
        marginBottom: 16,
        textAlign: 'center',
        fontStyle: 'italic',
        color: '#777',
    },
    genComingSoonSeedImage: {
        width: 256,
        height: 256,
    },
    genItems: {
        display: 'flex',
        flexDirection: 'column',
    },
    seedModalContainer: {
        display: 'flex',
        flexDirection: 'column',
        margin: '24px 0',
    },
    seedModalClose: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
    },
    seedModalContent: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
    },
    seedModalSeedImage: {
        width: 304,
        height: 304,
    },
    seedModalDetails: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        margin: '24px 0',
    },
    seedModalCloseButton: {
        cursor: 'pointer'
    },
    splashButton: {
        backgroundColor: '#F92A82',
        color: '#fff',
        textDecoration: 'none',
        padding: '.5em 1em',
        borderRadius: 4,
    },
    itemPriceLine: {
        marginTop: 8,
    },
    itemName: {
        fontSize: 20,
    },
    itemDescription: {
        marginTop: 8,
        fontStyle: 'italic',
        color: '#777',
        marginBottom: 8,
    },
    itemLegalAgreement: {
        textAlign: 'center',
    },
    itemPurchasing: {
        textAlign: 'center',
    },
    itemPurchaseError: {
        textAlign: 'center',
        color: 'red',
    },
    itemNote: {
        textAlign: 'center',
        fontSize: 10,
    },
    loadingSpinner: {
        height: 16,
    },
    disclaimerMessage: {
        fontSize: 14,
        borderRadius: 5,
        marginBottom: 24,
        padding: '.75rem 1.25rem',
        color: '#004085',
        backgroundColor: '#cce5ff',
        border: '2px solid #b8daff',
        flex: 1,
    },
};

function millisecondsToHoursMinutesSeconds(ms) {
    ms += 999; // add 999ms to round remaining milliseconds up to the next   second
    const hours = Math.floor(ms / (1000 * 60 * 60));
    const minutes = Math.floor(ms / (1000 * 60)) % 60;
    const seconds = Math.floor(ms / 1000) % 60;

    return [hours, minutes, seconds];
}

function leadingZero(num) {
    return num >= 0 && num < 10 ? '0' + num : +num;
}

const SALE_DOUBLE_BLOCK = 13149334;

class ShopScene extends Component {
    static KEY = 'shop';

    get _dispatch() {
        return this.props.dispatch;
    }

    constructor(props) {
        super(props);

        this.state = {
            seedModalOpenFor: null,
            buySeedModalAmount: 1,
            intervalHandle: null,
            purchasing: false,
            gen2021MintError: null,
            gen2021MintInputQuantity: '',
            gen2021MintTxStatus: null,
            gen2021MintTxHash: null,
            gen2021CountdownMs: Constants.gen2021StartSaleTimeMilliseconds - Date.now(),
            gen2021SaleStarted: null,
            gen2021TotalSupply: null,
            gen2021Price: null,
            currentBlock: null,
            gen2021PriceDoubleCountdownMs: null,
        };

        this._countdownTimeout = null;
        this._pollSaleStartedTimeout = null;
        this._pollTotalSupplyTimeout = null;
        this._pollPriceTimeout = null;
        this._pollBlockTimeout = null;
        this._countdownPriceDoubleTimeout = null;
    }

    componentWillMount() {
        document.title = 'EtherTulips: Shop';

        this._tickGen2021Countdown();
        this._pollSaleStarted();
        this._pollTotalSupply();
        this._pollPrice();
        this._pollBlock();
        this._tickGen2021PriceDoubleCountdownMs();
    }

    componentWillUnmount() {
        if (this._countdownTimeout) {
            clearTimeout(this._countdownTimeout);
        }
        if (this._pollSaleStartedTimeout) {
            clearTimeout(this._pollSaleStartedTimeout);
        }
        if (this._pollTotalSupplyTimeout) {
            clearTimeout(this._pollTotalSupplyTimeout);
        }
        if (this._pollPriceTimeout) {
            clearTimeout(this._pollPriceTimeout);
        }
        if (this._pollBlockTimeout) {
            clearTimeout(this._pollBlockTimeout);
        }
        if (this._countdownPriceDoubleTimeout) {
            clearTimeout(this._countdownPriceDoubleTimeout);
        }
    }

    render() {
        if (this.props.isLoading) { // TODO: this is a hack to ensure price info is loaded
            return this._renderLoading();
        } else {
            return this._renderShop();
        }
    }

    _renderShop() {
        let modal = this.state.seedModalOpenFor !== null && this._renderModal();

        return (
            <div style={Styles.container}>
                { this._renderHeader() }
                <div style={Styles.shopFlexContainer}>
                    <span style={Styles.disclaimerMessage}>
                        As a reminder, Ether Tulips are <strong>not</strong> investments and are provided for entertainment purposes only. Please review the <a href="/legal/terms" target="_blank" style={{textDecoration: 'underline'}}>Terms of Service</a> for more information.
                    </span>
                    {this._renderGen2021()}
                    {this._renderGen(1)}
                    {this._renderGen(0)}
                </div>
                {modal}
            </div>
        );
    }

    _renderHeader() {
        return (
            <div style={Styles.headerFlexContainer}>
                <div style={Styles.header}>
                    <span style={Styles.headerTitle}>Shop</span>
                    <div style={Styles.spacer}></div>
                </div>
            </div>
        );
    }

    _renderGen1ComingSoon() {
        return (
            <div style={Styles.genComingSoonContainer}>
                <div style={Styles.genComingSoonHeader}><span>Generation 1: ???</span></div>
                <div style={Styles.genComingSoonSubhead}>Coming soon.</div>
                <div>
                    <img src={getAsset("seeds/1.png")} style={Styles.genComingSoonSeedImage} />
                </div>
                <div style={Styles.genComingSoonDescription}>
                    <span>{Strings.shopGen1SeedDescription}</span>
                </div>
                <div>
                    <a href="https://medium.com/@ethertulips/introducing-gen-1-a-sneak-peek-c4ad2531075d" target="_blank" className="hoverUnderline">Get a sneak peak</a>
                </div>
            </div>
        );
    }

    async _pollSaleStarted() {
        const resp = await ApiV2.salesStarted();
        this.setState({
            gen2021SaleStarted: resp,
        });
        if (!resp) {
            this._pollSaleStartedTimeout = setTimeout(() => this._pollSaleStarted(), 2000);
        }
    }

    async _pollTotalSupply() {
        const resp = await ApiV2.totalSupply();
        this.setState({
            gen2021TotalSupply: resp,
        });
        this._pollTotalSupplyTimeout = setTimeout(() => this._pollTotalSupply(), 3000);
    }

    async _pollPrice() {
        const resp = await ApiV2.getPrice();
        this.setState({
            gen2021Price: resp,
        });
        this._pollPriceTimeout = setTimeout(() => this._pollPrice(), 1000);
    }

    async _pollBlock() {
        const provider = await ApiV2.provider();
        const block = await provider.getBlockNumber();
        const msRemaining = Math.max(0, (SALE_DOUBLE_BLOCK - block) * 13100); // ~13.1 seconds per eth block
        if (block !== this.state.currentBlock) {
            this.setState({
                currentBlock: block,
                gen2021PriceDoubleCountdownMs: msRemaining,
            });
        }
        this._pollBlockTimeout = setTimeout(() => this._pollBlock(), 1000);
    }

    _tickGen2021Countdown() {
        const msRemaining = Math.max(0, Constants.gen2021StartSaleTimeMilliseconds - Date.now());
        this.setState({gen2021CountdownMs: msRemaining});

        if (msRemaining > 0) {
            this._countdownTimeout = setTimeout(() => this._tickGen2021Countdown(), 1000);
        }
    }

    _tickGen2021PriceDoubleCountdownMs() {
        if (!this.state.currentBlock) {
            this._countdownPriceDoubleTimeout = setTimeout(() => this._tickGen2021PriceDoubleCountdownMs(), 1000);
            return;
        }

        const msRemaining = Math.max(0, this.state.gen2021PriceDoubleCountdownMs - 1000);
        this.setState({gen2021PriceDoubleCountdownMs: msRemaining});

        if (msRemaining > 0) {
            this._countdownPriceDoubleTimeout = setTimeout(() => this._tickGen2021PriceDoubleCountdownMs(), 1000);
        }
    }

    _onGen2021MintInputQuantityChange(event) {
        const value = event.target.value;
        if (value === '') {
            this.setState({
                gen2021MintTxHash: null,
                gen2021MintError: '',
                gen2021MintInputQuantity: '',
            });
            return;
        }
        const valueNumeric = +value;
        if (isNaN(valueNumeric) || valueNumeric < 1) return;
        if (valueNumeric > 30) {
            this.setState({
                gen2021MintTxHash: null,
                gen2021MintError: 'Max 30 per transaction',
            });
            return;
        }
        this.setState({
            gen2021MintTxHash: null,
            gen2021MintInputQuantity: valueNumeric,
            gen2021MintError: '',
        });
    }

    async _onMintPressed() {
        const total = this.state.gen2021MintInputQuantity && ethers.utils.parseEther('0.15').mul(+this.state.gen2021MintInputQuantity);
        this.setState({
            gen2021MintError: null,
            gen2021MintTxStatus: 'waiting_mint',
            gen2021MintTxHash: null,
        });
        try {
            const tx = await ApiV2.mintTulips(+this.state.gen2021MintInputQuantity, total);
            this.setState({
                gen2021MintInputQuantity: '',
                gen2021MintTxStatus: null,
                gen2021MintTxHash: tx.hash,
            });
        } catch (err) {
            if (err.message.includes('insufficient funds')) {
                this.setState({
                    gen2021MintError: 'Insufficient wallet balance',
                    gen2021MintTxStatus: null,
                });
            } else {
                this.setState({
                    gen2021MintError: err.message,
                    gen2021MintTxStatus: null,
                });
            }
        }
    }

    _renderGen2021() {
        const scheduledSaleStarted = this.state.gen2021CountdownMs === 0;

        const mintButtonEnabled = this.state.gen2021MintInputQuantity && !isNaN(this.state.gen2021MintInputQuantity);
        const total = this.state.gen2021MintInputQuantity && ethers.utils.parseEther('0.15').mul(+this.state.gen2021MintInputQuantity);

        const mintButton = this.state.gen2021MintTxStatus === null ? (
            this.state.gen2021MintTxHash ? (
                <>
                    <span style={{paddingLeft: 8, paddingRight: 16, fontSize: 12, marginTop: 8, color: '#23cc01', textAlign: 'center', fontWeight: 'bold'}}>Transaction sent!</span>
                    <button className="shopscene-track-button" style={{marginTop: 8, border: 'none', padding: 8, borderRadius: 8}} onClick={() => window.open(`https://etherscan.io/tx/${this.state.gen2021MintTxHash}`,'_blank')}>
                        <span style={{fontWeight: 600, fontSize: 14}}>Track on Etherscan <span className="fa fa-external-link"></span></span>
                    </button>
                </>
            ) : (
                <button className="shopscene-mint-button" style={mergeStyles({marginTop: 8, border: 'none', padding: 8, borderRadius: 8}, !mintButtonEnabled && {backgroundColor: '#555', color: '#999'})} onClick={() => this._onMintPressed()} disabled={!mintButtonEnabled}>
                    <span style={{fontWeight: 600, fontSize: 14}}>Mint</span>
                </button>
            )
        ) : (
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: 8, height: 36}}>
                <img style={Styles.loadingSpinner} src={getAsset("animation/loading.gif")} />
                <span style={{marginLeft: 4, fontWeight: 600, fontSize: 14}}>Waiting for confirmation</span>
            </div>
        );

        const renderSale = () => {
            const renderCountdown = () => {
                if (!this.state.gen2021PriceDoubleCountdownMs) {
                    return null;
                }
                const [hours, minutes, seconds] = millisecondsToHoursMinutesSeconds(this.state.gen2021PriceDoubleCountdownMs);
                return (
                    <span style={{fontSize: 14, opacity: 0.87, fontWeight: 600}}>⏳ Price increases to <span style={{color: 'red'}}>⟠0.15</span> in <span style={{color: 'red'}}>~{leadingZero(hours)}:{leadingZero(minutes)}:{leadingZero(seconds)}.</span></span>
                );
            };

            return (
                <>
                    <MediaQuery minWidth={780}>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <div style={Styles.gen2021SeedImage}>
                                <img src={getAsset('seeds/2021.png')} style={Styles.gen2021SeedImage} />
                            </div>
                            <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                                <span style={Styles.gen2021SeedName}>A Super Seed <span style={{marginLeft: 4, fontSize: 12}}><a href="https://medium.com/@ethertulips/the-gen-2021-release-489d26f2a061" target="_blank" className="hoverUnderline">Learn more</a></span></span>
                                <span style={{marginTop: 4, opacity: 0.87, fontSize: 14, fontWeight: 600}}>
                                    <span style={{color: '#33e312'}}>🏷 {this.state.gen2021Price ? `⟠${ethers.utils.formatEther(this.state.gen2021Price)} each` : <span style={{color: 'white', opacity: 0.54}}>--</span>}</span>
                                    {this.state.gen2021TotalSupply !== null && (
                                        <span>
                                            {' '}&middot; <span style={{color: '#888'}}>{12345 - this.state.gen2021TotalSupply.toNumber()} remaining</span>
                                        </span>
                                    )}
                                </span>
                                {renderCountdown()}
                                <span style={{marginTop: 8, opacity: 0.8, fontSize: 14, lineHeight: 1.5}}>
                            The search for the perfect tulip is neverending. And so, three years and five months ago we made the pilgrimage to the Temple of Tulips. No wifi. No data. No connection to the outside world. Only tulips. For years we researched and tested in solitude in the pursuit of perfection.<br />
                            <br />
                            Our first attempt involved modifying a critical nucleotide sequence in the DNA of the Seedy Seeds. The tulip howled as it sprouted into life. Something was clearly wrong, as it started shrieking in pain. "Why do we exist? Why are we? WHY ARE WE?" it bawled. The Templekeeper immediately set it on fire to end its misery.<br />
                            <br />
                            Thomas Edison once said, "I have not failed. I've just found 10,000 ways that won't work." And so we persevered. Introducing the Super Seed. Now <span style={{fontWeight: 'bold', fontStyle: 'italic'}} className="shopscene-rainbow-text-animated">10x</span> as bubbly. We promise it was 100% ethically made.
                            </span>
                            </div>
                            <div style={{display: 'flex', flexDirection: 'column', borderLeft: '1px solid #333', marginLeft: 20, paddingLeft: 20, width: 232}}>
                                <div style={{backgroundColor: '#555', borderRadius: 8, paddingLeft: 8, paddingRight: 8, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                    <span style={{fontWeight: 600, fontSize: 12}}>Quantity</span>
                                    <input type="text" placeholder="100" style={{background: 'none', border: 'none', padding: 8, textAlign: 'right', outline: 'none', flex: 1, width: 0}} value={this.state.gen2021MintInputQuantity} onChange={(ev) => this._onGen2021MintInputQuantityChange(ev)} />
                                </div>
                                {/*<div style={{paddingLeft: 8, paddingRight: 8, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>*/}
                                {/*    <span style={{fontWeight: 600, fontSize: 12}}>Quantity</span>*/}
                                {/*    <div style={{flex: 1}} />*/}
                                {/*    <span style={{fontSize: 12}}>{this.state.gen2021MintInputQuantity}</span>*/}
                                {/*</div>*/}
                                {/*<Slider*/}
                                {/*    value={this.state.gen2021MintInputQuantity}*/}
                                {/*    orientation="horizontal"*/}
                                {/*    onChange={val => this.setState({gen2021MintInputQuantity: val})}*/}
                                {/*    min={1}*/}
                                {/*    max={30}*/}
                                {/*/>*/}
                                { this.state.gen2021MintError && <span style={{paddingLeft: 8, paddingRight: 16, fontSize: 12, marginTop: 4, color: 'red'}}>{this.state.gen2021MintError}</span>}
                                <div style={{display: 'flex', flexDirection: 'row', marginTop: 8, alignItems: 'center', fontSize: 12, paddingLeft: 8, paddingRight: 16}}>
                                    <span style={{fontWeight: 600, opacity: 0.8}}>Total</span>
                                    <div style={{flex: 1}} />
                                    <span style={{height: 16}}>{total ? '⟠ ' + ethers.utils.formatEther(total) : '--'}</span>
                                </div>
                                <span style={{fontSize: 10, paddingLeft: 8, paddingRight: 16, marginTop: 8}}>By minting, you agree to the <a href="/legal/terms" className="hoverUnderline" target="_blank">Terms of Use</a>.</span>
                                {mintButton}
                            </div>
                        </div>
                    </MediaQuery>
                    <MediaQuery maxWidth={779}>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <div style={mergeStyles(Styles.gen2021SeedImage, {marginRight: 0})}>
                                <img src={getAsset('seeds/2021.png')} style={mergeStyles(Styles.gen2021SeedImage, {marginRight: 0})} />
                            </div>
                            <div style={{display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'center', marginTop: 8}}>
                                <span style={Styles.gen2021SeedName}>A Super Seed <span style={{marginLeft: 4, fontSize: 12}}><a href="https://medium.com/@ethertulips/the-gen-2021-release-489d26f2a061" target="_blank" className="hoverUnderline">Learn more</a></span></span>
                                <span style={{marginTop: 4, opacity: 0.87, fontSize: 14, fontWeight: 600}}>
                                    <span style={{color: '#33e312'}}>🏷 {this.state.gen2021Price ? `⟠${ethers.utils.formatEther(this.state.gen2021Price)} each` : <span style={{color: 'white', opacity: 0.54}}>--</span>}</span>
                                    {this.state.gen2021TotalSupply !== null && (
                                        <span>
                                            {' '}&middot; <span style={{color: '#888'}}>{12345 - this.state.gen2021TotalSupply.toNumber()} remaining</span>
                                        </span>
                                    )}
                                </span>
                                {renderCountdown()}
                            </div>
                            <div style={{display: 'flex', flexDirection: 'column', borderLeft: '1px solid #333', width: 232, marginTop: 16}}>
                                <div style={{backgroundColor: '#555', borderRadius: 8, paddingLeft: 8, paddingRight: 8, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                    <span style={{fontWeight: 600, fontSize: 12}}>Quantity</span>
                                    <input type="text" placeholder="100" style={{background: 'none', border: 'none', padding: 8, textAlign: 'right', outline: 'none', flex: 1, width: 0}} value={this.state.gen2021MintInputQuantity} onChange={(ev) => this._onGen2021MintInputQuantityChange(ev)} />
                                </div>
                                {/*<div style={{paddingLeft: 8, paddingRight: 8, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>*/}
                                {/*    <span style={{fontWeight: 600, fontSize: 12}}>Quantity</span>*/}
                                {/*    <div style={{flex: 1}} />*/}
                                {/*    <span style={{fontSize: 12}}>{this.state.gen2021MintInputQuantity}</span>*/}
                                {/*</div>*/}
                                {/*<Slider*/}
                                {/*    value={this.state.gen2021MintInputQuantity}*/}
                                {/*    orientation="horizontal"*/}
                                {/*    onChange={val => this.setState({gen2021MintInputQuantity: val})}*/}
                                {/*    min={1}*/}
                                {/*    max={30}*/}
                                {/*/>*/}
                                { this.state.gen2021MintError && <span style={{paddingLeft: 8, paddingRight: 16, fontSize: 12, marginTop: 4, color: 'red'}}>{this.state.gen2021MintError}</span>}
                                <div style={{display: 'flex', flexDirection: 'row', marginTop: 8, alignItems: 'center', fontSize: 12, paddingLeft: 8, paddingRight: 16}}>
                                    <span style={{fontWeight: 600, opacity: 0.8}}>Total</span>
                                    <div style={{flex: 1}} />
                                    <span style={{height: 16}}>{total ? '⟠ ' + ethers.utils.formatEther(total) : '--'}</span>
                                </div>
                                <span style={{fontSize: 10, paddingLeft: 8, paddingRight: 16, marginTop: 8}}>By minting, you agree to the <a href="/legal/terms" className="hoverUnderline" target="_blank">Terms of Use</a>.</span>
                                {mintButton}
                            </div>
                            <div style={{display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'center', marginTop: 16, paddingLeft: 24, paddingRight: 24}}>
                                <span style={{marginTop: 8, opacity: 0.8, fontSize: 14, lineHeight: 1.5}}>
                                The search for the perfect tulip is neverending. And so, three years and five months ago we made the pilgrimage to the Temple of Tulips. No wifi. No data. No connection to the outside world. Only tulips. For years we researched and tested in solitude in the pursuit of perfection.<br />
                                <br />
                                Our first attempt involved modifying a critical nucleotide sequence in the DNA of the Seedy Seeds. The tulip howled as it sprouted into life. Something was clearly wrong, as it started shrieking in pain. "Why do we exist? Why are we? WHY ARE WE?" it bawled. The Templekeeper immediately set it on fire to end its misery.<br />
                                <br />
                                Thomas Edison once said, "I have not failed. I've just found 10,000 ways that won't work." And so we persevered. Introducing the Super Seed. Now <span style={{fontWeight: 'bold', fontStyle: 'italic'}} className="shopscene-rainbow-text-animated">10x</span> as bubbly. We promise it was 100% ethically made.
                                </span>
                            </div>
                        </div>
                    </MediaQuery>
                </>
            );
        };

        const renderCountdown = () => {
            if (this.state.gen2021CountdownMs <= 0 && !this.state.gen2021SaleStarted) {
                return (
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <span style={{fontWeight: 600, opacity: 0.87, fontSize: 12}}>Form will auto-refresh</span>
                        <span style={{fontWeight: 'bold', fontSize: 32, marginTop: 4, textAlign: 'center'}} className="shopscene-rainbow-text-animated">Minting begins momentarily</span>
                        <span style={{fontWeight: 600, fontSize: 12, opacity: 0.87, marginTop: 16}}>Total Supply: 5,094 &middot; ⟠0.075 each</span>
                        <span style={{fontSize: 14, marginTop: 4}}><a href="https://medium.com/@ethertulips/the-2021-update-bf0b653a623a" target="_blank" style={{color: '#498dff'}} className="hoverUnderline">Read about Gen 2021</a></span>
                    </div>
                );
            }

            const [hours, minutes, seconds] = millisecondsToHoursMinutesSeconds(this.state.gen2021CountdownMs);

            return (
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <span style={{fontWeight: 600, opacity: 0.87}}>Mint starts in</span>
                    <span style={{fontWeight: 'bold', fontSize: 48, marginTop: 4, width: 224}} className="shopscene-rainbow-text-animated">{leadingZero(hours)}:{leadingZero(minutes)}:{leadingZero(seconds)}</span>
                    <span style={{fontWeight: 600, fontSize: 12, opacity: 0.87, marginTop: 16}}>Total Supply: 5,094 &middot; ⟠0.075 each</span>
                    <span style={{fontSize: 14, marginTop: 4}}><a href="https://medium.com/@ethertulips/the-gen-2021-release-489d26f2a061" target="_blank" style={{color: '#498dff'}} className="hoverUnderline">Read about Gen 2021</a></span>
                </div>
            );
        };

        return (
            <div style={Styles.gen2021Container}>
                <div style={Styles.gen2021Header}><span className="shopscene-rainbow-text-animated" style={{textAlign: 'center'}}>Generation 2021</span></div>
                {scheduledSaleStarted && this.state.gen2021SaleStarted !== false ? renderSale() : renderCountdown()}
            </div>
        );
    }

    _renderGen(gen) {
        let {name, seedName, seedDescription, learnMoreUrl} = genInfo[gen];

        return (
            <div style={Styles.genContainer}>
                <div style={Styles.genHeader}><span style={Styles.genHeaderTitle}>Generation {gen}: {name}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href={learnMoreUrl} target="_blank" className="hoverUnderline">Learn more</a></div>
                <div style={Styles.genItems}>
                    <LegacyShopItemRow
                        name={seedName}
                        description={seedDescription}
                        price={0}
                        futurePrice={0}
                        blocksRemaining={0}
                        changeBlock={0}
                        onSelect={() => this.setState({seedModalOpenFor: gen})}
                        imageUrl={getAsset("seeds/"+gen+".png")}
                        imageBackgroundColor="#eee" />
                </div>
            </div>
        );
    }

    _renderModal() {
        if (this.state.seedModalOpenFor === null) {
            return null;
        }

        let gen = this.state.seedModalOpenFor;
        let {seedName, seedDescription} = genInfo[gen];
        let {price} = this._getPricingForGen(gen);
        let ethPrice = this._getDisplayPrice(price);

        let purchaseButton;
        let purchaseError = null;
        if (!this.state.purchasing) {
            purchaseButton = (
                <button style={Styles.splashButton} onClick={() => {
                    let totalCharge = price.times(this.state.buySeedModalAmount);
                    this.setState({
                        purchasing: true,
                        purchaseError: null,
                    });
                    this._dispatch(Actions.buyTulips(this.state.buySeedModalAmount, gen, totalCharge))
                        .then(resp => {
                            if (resp) {
                                this.setState({ seedModalOpenFor: null, purchasing: false });
                                this.props.history.push(
                                    '/shop/purchased?purchased=' +
                                    this.state.buySeedModalAmount +
                                    '&transactionHash='+
                                    resp.hash
                                );
                            }
                        })
                        .catch(error => {
                            console.log(error);
                            this.setState({
                                purchasing: false,
                                purchaseError: error,
                            });
                        });
                }}>
                    { !this.state.purchasing ? "Buy" : "Loading..." }
                </button>
            );
        } else {
            purchaseButton = (
                <span style={Styles.itemPurchasing}><img style={Styles.loadingSpinner} src={getAsset("animation/loading.gif")} />&nbsp;Waiting for network...(may take a few minutes)</span>
            );
        }

        if (this.state.purchaseError) {
            purchaseError = (
                <span style={Styles.itemPurchaseError}>Transaction error.</span>
            );
        }

        return (
            <Modal
                onRequestClose={() => this.setState({seedModalOpenFor: null})}
                style={{
                    content: {
                        borderRadius: '4px',
                        bottom: 'auto',
                        minHeight: '10rem',
                        left: '50%',
                        padding: '2rem',
                        position: 'fixed',
                        right: 'auto',
                        top: '50%',
                        transform: 'translate(-50%,-50%)',
                        minWidth: '20rem',
                        width: '80%',
                        maxWidth: '60rem'
                    }
                }}
                isOpen={this.state.seedModalOpenFor !== null}>
                <div style={Styles.seedModalContainer}>
                    <div style={Styles.seedModalClose}>
                        <div style={{flex: 1}}></div>
                        <div style={Styles.seedModalCloseButton} onClick={() => this.setState({seedModalOpenFor: null})}>Close</div>
                    </div>
                    <div style={Styles.seedModalContent}>
                        <div>
                            <img style={Styles.seedModalSeedImage} src={getAsset("seeds/"+gen+".png")} />
                        </div>
                        <div style={Styles.seedModalDetails}>
                            <div style={Styles.itemName}>{seedName}</div>
                            <div style={Styles.itemPriceLine}>
                                <span className="fa fa-tag" style={{color: '#FF7F50'}}></span>&nbsp;&nbsp; <strong><span style={Styles.itemEthSymbol}>⟠</span> {ethPrice.toString()}</strong>
                                &nbsp;&nbsp;&nbsp; Quantity: {this.state.buySeedModalAmount} &nbsp;&nbsp; Total Cost: <span style={Styles.itemEthSymbol}>⟠</span>&nbsp;{ethPrice.times(this.state.buySeedModalAmount).toString()}
                            </div>
                            <div style={Styles.itemDescription}>{seedDescription}</div>
                            <Slider
                                value={this.state.buySeedModalAmount}
                                orientation="horizontal"
                                onChange={val => this.setState({buySeedModalAmount: val})}
                                min={1}
                                max={25}
                            />
                            <span style={Styles.itemLegalAgreement}>By purchasing, you agree to the <a href="/legal/terms" className="hoverUnderline">Terms of Use</a> and <a href="/legal/privacy" className="hoverUnderline">Privacy Policy</a>.</span>
                            <br />
                            {purchaseError}
                            {purchaseButton}
                            <br />
                            <span style={Styles.itemNote}>Note: Total cost does not include transaction fees. For gas, we recommend using the MetaMask gas limit recommendation and <a href="https://ethgasstation.info/" className="hoverUnderline" target="_blank">a suitable gas price</a>.</span>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }

    _getDisplayPrice(amt) {
        const displayPrice = amt.dividedBy(ethers.constants.WeiPerEther.toString());
        return displayPrice.decimalPlaces(3, BigNumber.ROUND_UP);
    }

    _renderLoading() {
        return <SceneLoadingIndicator />;
    }

    _renderError() {

    }

    _hasGen(gen) {
        return !!this.props.gens[String(gen)];
    }

    _getPricingForGen(gen) {
        if (!this.props.gens[String(gen)]) {
            return null;
        }
        return this.props.gens[String(gen)];
    }
}

const mapStateToProps = state => {
    return {
        isLoading: state.shop.fetching,
        gens: state.shop.gens,
    };
};

let apiRequiredScene = sceneRequiresApi(ShopScene)
export default connect(mapStateToProps)(etherTulipsPagify(apiRequiredScene));
